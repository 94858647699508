

function getBgColor(index: number, highlight: boolean, isExpired: boolean) {
    if (highlight) {
        return "#efae39";
    }
    if (isExpired) {
        return "#ff8383";
    }
    return !(index % 2) ? "#fff0bb" : '#f1f1f1';
}

export { getBgColor }
