import * as React from 'react';
import {
    PageSection,
    Alert,
    Tooltip,
    Button,
    Modal,
    ModalVariant,
    FormSelect,
    FormSelectOption,
    Popover,
} from '@patternfly/react-core';

import {
    TableComposable, Tbody, Th, Thead, Tr, Td, ThProps
} from '@patternfly/react-table';
import RedoIcon from '@patternfly/react-icons/dist/esm/icons/redo-icon';
import ListIcon from '@patternfly/react-icons/dist/esm/icons/list-icon';
import PencilAltIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon';
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';
import CopyIcon from '@patternfly/react-icons/dist/esm/icons/copy-icon';
import ShareSquareIcon from '@patternfly/react-icons/dist/esm/icons/share-square-icon';
import DataSinkIcon from '@patternfly/react-icons/dist/esm/icons/data-sink-icon';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { apiDelete, fetchCustomers, getCurrentClaims, isReadOnlyContract } from './fetcher';
import * as styles from './lab.module.css';
import { getBgColor } from './table-helper';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatTimeDuration } from './formatter';
import { ContractContacts, ContractKindResolver, ContractOptionsView, isContractExpired, ToolTipLink } from './contract-components';
import { apiPut, ManagerContract, ManagerCustomer } from 'linbit-api-fetcher';
import { CredentialNameResolver, LdapNameResolver } from './components';

interface CustomerChooserProps {
    contractId: number,
    closePopover: () => void,
}


const CustomerChooser: React.FunctionComponent<CustomerChooserProps> = (props: CustomerChooserProps) => {
    const queryClient = useQueryClient();

    const [customerId, setCustomerId] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState("");

    const onMoveClick = () => {
        apiPut<ManagerContract>('/manager/contracts/' + props.contractId + '/move-to-customer/' + customerId,
            {})
            .then((_resp) => {
                queryClient.invalidateQueries(['contracts']);
                props.closePopover();
            })
            .catch((reason) => setErrorMessage(reason.toString()));
    }

    const { isLoading, isError, data, error } =
        useQuery<ManagerCustomer[], string>(['customers', true, [], false], () =>
            fetchCustomers(true))

    if (isError) {
        return <Alert variant='danger' title={error}></Alert>
    }

    const customers = data ? data : [];

    return (
        <React.Fragment>
            Company:
            <FormSelect
                value={customerId}
                aria-label="customer select"
                onChange={(val: string) => setCustomerId(+val)}
            >
                {customers.map(mc => <FormSelectOption key={mc.id} value={mc.id} label={`${mc.name} - ${mc.id}`} />)}
            </FormSelect><br /><br />
            <Button
                onClick={onMoveClick}>Move contract</Button>
            {errorMessage && <Alert title={"Error moving: " + errorMessage} variant='danger' />}
        </React.Fragment>
    )
}

enum View {
    All = 0,
    ActiveOnly = 1,
    ExpiredOnly = 2,
}

interface ContractTableProps {
    customerId: number,
    viewMode?: View,
    contracts: ManagerContract[],
}


const ContractTable: React.FunctionComponent<ContractTableProps> = (props: ContractTableProps) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [activeContractId, setActiveContractId] = React.useState(0);
    const [responseError, setResponseError] = React.useState('');

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();
    const claims = getCurrentClaims();

    const [activeSortIndex, setActiveSortIndex] = React.useState<number>(9);
    // Sort direction of the currently sorted column
    const [activeSortDirection, setActiveSortDirection] = React.useState<'asc' | 'desc'>('asc');

    const getSortParams = (columnIndex: number): ThProps['sort'] => ({
        sortBy: {
            index: activeSortIndex,
            direction: activeSortDirection
        },
        onSort: (_event, index, direction) => {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
        },
        columnIndex
    });

    React.useEffect(() => {
        // get anchor links working, DOM not yet populated HACKY
        const anchor = window.location.hash.slice(1);
        if (anchor) {
            setTimeout(() => {
                const anchorEl = document.getElementById(anchor);
                if (anchorEl) {
                    anchorEl.scrollIntoView();
                }
            }, 500)
        }
    }, [location]);

    const handleDeleteModalToggle = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    }

    const onDeleteContract = (contract_id: number) => {
        setActiveContractId(contract_id);
        setIsDeleteModalOpen(true);
    }

    const onDeleteContractAffirmed = () => {
        apiDelete<string>("/manager/customers/" + props.customerId + "/contracts/" + activeContractId)
            .then((_resp) => {
                setResponseError('');
                queryClient.invalidateQueries(['contracts', props.customerId]);
                setIsDeleteModalOpen(false);
            })
            .catch((reason) => {
                let pageElem = document.getElementById("contracts-section");
                setTimeout(() => {
                    pageElem?.scrollIntoView();
                }, 100);
                setResponseError(reason.toString());
                setIsDeleteModalOpen(false);
            });
    }

    function renderContractData(contracts: ManagerContract[] | undefined) {
        if (contracts !== undefined) {
            return contracts
                .filter((cs) => {
                    switch (props.viewMode) {
                        case View.ActiveOnly: return !isContractExpired(cs);
                        case View.ExpiredOnly: return isContractExpired(cs);
                        default: return true;
                    }
                })
                .sort((a, b) => {
                    switch (activeSortIndex) {
                        case 0: {
                            return activeSortDirection === 'asc'
                                ? a.id - b.id : b.id - a.id;
                        }
                        case 4: {
                            let aD = Date.parse(a.support_until);
                            let bD = Date.parse(b.support_until);
                            return activeSortDirection === 'asc' ?
                                aD - bD : bD - aD;
                        }
                        case 5: {
                            return activeSortDirection === 'asc' ?
                                a.registered_nodes - b.registered_nodes : b.registered_nodes - a.registered_nodes;
                        }
                        case 6: {
                            return activeSortDirection === 'asc' ?
                                a.accounted_time - b.accounted_time : b.accounted_time - a.accounted_time;
                        }
                        default: {
                            return a.id - b.id +
                                (isContractExpired(a) ? 10000000 : 0) - (isContractExpired(b) ? 10000000 : 0)
                        }
                    }
                })
                .map((cs, index) => {
                    const ctId = "ct-" + cs.id;
                    const isExpired = isContractExpired(cs);
                    return (
                        <Tr key={cs.id} style={{ backgroundColor: getBgColor(index, location.hash === "#" + ctId, isExpired) }} id={ctId}>
                            <Td style={{ whiteSpace: "nowrap" }}>{cs.id}<br />
                                <ToolTipLink tooltipContent={"Followup contract id"} prefix={"f: "} contractId={cs.followup_contract_id} />
                                {cs.followup_contract_id && <br />}
                                <ToolTipLink tooltipContent={"Origin/Root contract id"} prefix={"o: "} contractId={cs.origin_contract_id} />
                            </Td>
                            <Td><LdapNameResolver ldap_uid={cs.account_manager_ldap} /></Td>
                            <Td><ContractKindResolver kind={cs.kind_id} /></Td>
                            <Td textCenter style={{ fontFamily: "RedHatMono", fontSize: "0.7em", whiteSpace: "nowrap" }}>{cs.payment_date}</Td>
                            <Td textCenter style={{ fontFamily: "RedHatMono", fontSize: "0.7em", whiteSpace: "nowrap" }}>
                                <p>{cs.support_from}</p>
                                <p>{cs.download_until}</p>
                                <p>{cs.support_until}</p>
                            </Td>
                            <Td textCenter>
                                <Tooltip content="Registered / Max">
                                    <p>
                                        <Link to={`/customers/${props.customerId}/?tab=1&filter=` + encodeURIComponent(`contract=${cs.id}`)}>{cs.registered_nodes}</Link> / {cs.infinite_nodes ? "∞" : cs.supported_nodes}
                                    </p>
                                </Tooltip>
                                <Tooltip content="Tickets / Max"><p>{cs.tickets} / {cs.max_tickets === 0 ? "∞" : cs.max_tickets}</p></Tooltip>
                                {cs.tickets > 0 ? <Tooltip content="Ticket list"><Link to={`/contracts/${cs.id}/tickets`}><ListIcon /></Link></Tooltip> : <ListIcon />}
                            </Td>
                            <Td textCenter>{formatTimeDuration(cs.accounted_time)}</Td>
                            <Td>
                                <p style={{ backgroundColor: '#e7dbc6' }}>Send reminder: {cs.send_reminders ? 'yes' : 'no'}</p>
                                <p style={{ backgroundColor: '#b7bcdf' }}>Issuer: {cs.issuer_name}</p>
                                <p style={{ backgroundColor: '#b7dfc1' }}>Credential: <CredentialNameResolver credential_id={cs.credential_id} customer_id={props.customerId} /></p>
                                <p>Comment:<br />{cs.comment}</p>
                            </Td>
                            <Td style={{ whiteSpace: "nowrap" }}>
                                <div style={{ float: 'right' }}><Tooltip content="Copy contacts"><Link to={`/customers/${props.customerId}/contracts-contact-copy/${cs.id}`}><CopyIcon /></Link></Tooltip></div>
                                <ContractContacts contractId={cs.id} />
                            </Td>
                            <Td><ContractOptionsView options={cs.options} /></Td>
                            <Td textCenter>
                                <Button
                                    variant='plain'
                                    id={"edit-contract-" + cs.id}
                                    onClick={() => navigate("/customers/" + props.customerId + "/contracts/" + cs.id)}
                                    isDisabled={isReadOnlyContract(claims, cs.issuer_id)}>
                                    <Tooltip content="Edit contract"><PencilAltIcon /></Tooltip>
                                </Button><br />
                                <Button
                                    variant='plain'
                                    id={"renew-contract-" + cs.id}
                                    onClick={() => navigate("/customers/" + props.customerId + "/contracts/" + cs.id + "?renew=true")}>
                                    <Tooltip content="Renew contract"><RedoIcon /></Tooltip>
                                </Button><br />
                                <Button
                                    variant='plain'
                                    id={"mail-contract-" + cs.id}
                                    onClick={() => navigate("/contracts/" + cs.id + "/mail")}>
                                    <Tooltip content="Mail contract details"><ShareSquareIcon /></Tooltip>
                                </Button>
                                <br />
                                <Popover
                                    aria-label='customer chooser popover'
                                    id={"popup-chooser-" + cs.id}
                                    headerContent={<div>Choose new company for contract</div>}
                                    bodyContent={hide => (<CustomerChooser contractId={cs.id} closePopover={hide} />)}
                                >
                                    <Button
                                        variant='plain'
                                        id={"move-contract-" + cs.id}
                                        isDisabled={isReadOnlyContract(claims, cs.issuer_id)}>
                                        <Tooltip content="Move contract to another customer"><DataSinkIcon /></Tooltip>
                                    </Button>
                                </Popover><br />
                                <Button
                                    variant='plain'
                                    id={"delete-contract-" + cs.id}
                                    onClick={() => onDeleteContract(cs.id)}
                                    isDisabled={isReadOnlyContract(claims, cs.issuer_id)}>
                                    <Tooltip content="Delete contract"><TrashIcon /></Tooltip>
                                </Button>
                            </Td>
                        </Tr>
                    )
                })
        }
        return (<Tr />)
    }

    return (
        <PageSection id="contracts-section">
            {responseError && (<Alert variant='danger' title={responseError} isInline />)}
            <Button isSmall onClick={() => navigate("/customers/" + props.customerId + "/contracts")}>Create contract</Button>
            <Modal
                variant={ModalVariant.small}
                title="Really delete contract?"
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteModalToggle}
                actions={[
                    <Button key="confirm" variant="primary" onClick={onDeleteContractAffirmed}>
                        Sure
                    </Button>,
                    <Button key="cancel" variant="link" onClick={handleDeleteModalToggle}>
                        Abort
                    </Button>
                ]}
            >
                Are you sure you want to delete this contract?
            </Modal>
            <TableComposable
                aria-label='contracts table'
                variant='compact'
                className={styles.tinytable}
            >
                <Thead>
                    <Tr>
                        <Th sort={getSortParams(0)}>ID</Th>
                        <Th>Account Mgr</Th>
                        <Th>Support level</Th>
                        <Th>Payment date</Th>
                        <Th sort={getSortParams(4)}>From<br />Download<br />Expires</Th>
                        <Th sort={getSortParams(5)}>Nodes<br />Tickets</Th>
                        <Th sort={getSortParams(6)}>Time</Th>
                        <Th width={20}>Info</Th>
                        <Th width={20}>Contacts</Th>
                        <Th width={15}>Options</Th>
                        <Th textCenter>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {renderContractData(props.contracts)}
                </Tbody>
            </TableComposable>
        </PageSection>
    )
}

export { ContractTable, View }
