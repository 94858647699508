import * as React from 'react';
import '@patternfly/react-core/dist/styles/base.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { App } from './app_layout/app_layout';
import './app.css';
// login stuff
import { AppLogin } from './app_login/app_login';
import { Customers } from './page-customers';
import { CustomerPage } from './page-customer';
import { Dashboard } from './page-dashboard';
import { ContractOptions } from './options/page-contract-options';
import { NotFound } from './not_found/not_found';
import { ContractEditPage } from './page-contract-edit';
import { apiPost, parseJwt } from './fetcher';
import { ContractExpirationPage } from './page-contract-expiration';
import { TicketPage } from './page-tickets';
import { ContractByContactPage } from './page-search-contract-by-contact';
import { LicenseExpirationPage } from './page-license-expiration';
import { IssuersPage } from './page-issuers';
import { ContractContactCopyPage } from './page-contract-contact-copy';
import { SendMailPage } from './page-send-mail';
import { ContractPage } from './page-contract';
import { ChangelogPage } from './page-changelog';
import { ContractsPage } from './page-contracts';
import { ContractNodesRegisteredPage } from './reports/page-contract-nodes-registered';
import { AdminSessionsPage } from './page-admin-sessions';
import { CustomerContactsPage } from './reports/page-customer-contacts';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 1000 * 60
    }
  }
});

const Main: React.FunctionComponent = () => {
  const [isLoged, setIsLoged] = React.useState(false);

  const onHandleLogin = (value: boolean) => {
    setIsLoged(value);
    queryClient.invalidateQueries();
  }

  const onHandleLogout = () => {
    apiPost("/logout", {});
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    setIsLoged(false);
    window.location.href = '/'; // cannot use navigate here
  }

  React.useEffect(() => {
    let value = localStorage.getItem('access-token');
    if (value) {
      let jwt = parseJwt(value);
      let curExp = jwt["exp"] * 1000;
      if (curExp < Date.now() && localStorage.getItem('refresh-token')) {
        console.log("refresh now");
      }
      setIsLoged(true);
    } else {
      setIsLoged(false);
    }
  }, []);

  return (
    !isLoged ?
      <AppLogin handleLogin={onHandleLogin} />
      :
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" element={<App handleLogout={onHandleLogout} />}>
              <Route index element={<Dashboard />} />
              <Route path="customers">
                <Route index element={<Customers />} />
                <Route path=":customerId">
                  <Route index element={<CustomerPage />} />
                  <Route path="contracts-contact-copy">
                    <Route path=":contractId" element={<ContractContactCopyPage />} />
                  </Route>
                  <Route path="contracts">
                    <Route index element={<ContractEditPage />} />
                    <Route path=":contractId" element={<ContractEditPage />} />
                  </Route>
                </Route>
              </Route>
              <Route path="contracts">
                <Route index element={<ContractsPage />} />
                <Route path=":contractId">
                  <Route index element={<ContractPage />} />
                  <Route path="tickets" element={<TicketPage />} />
                  <Route path="mail" element={<SendMailPage />} />
                </Route>
              </Route>
              <Route path="reports">
                <Route path="contract-nodes-registered" element={<ContractNodesRegisteredPage />} />
                <Route path="customer-contacts" element={<CustomerContactsPage />} />
              </Route>
              <Route path="admin">
                <Route path="sessions" element={<AdminSessionsPage />} />
              </Route>
              <Route path="contracts-by-contact" element={<ContractByContactPage />} />
              <Route path="contract-expiration" element={<ContractExpirationPage />} />
              <Route path="license-expiration" element={<LicenseExpirationPage />} />
              <Route path="issuers" element={<IssuersPage />} />
              <Route path="contract-options" element={<ContractOptions />} />
              <Route path="changelog" element={<ChangelogPage />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </QueryClientProvider>
      </BrowserRouter>
  )
};

export default Main;
