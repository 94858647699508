import * as React from 'react';
import {
    Alert, PageSection, Spinner, BackToTop, Toolbar, ToolbarContent,
    ToolbarItem, DatePicker, isValidDate, Button, Tooltip
} from "@patternfly/react-core"
import { TableComposable, Thead, Tr, Th, Tbody, Td, ThProps } from '@patternfly/react-table';
import { useQuery } from "@tanstack/react-query"
import { Link } from 'react-router-dom';

import { ContractNodesRegisteredStats, NodesRegisteredStats } from 'linbit-api-fetcher'
import { apiGet } from '../fetcher';
import { IssuerSelect } from '../form-components';
import * as styles from '../lab.module.css';
import { setUTC } from '../formatter';

interface ContractNodesRegisteredTableProps {
    entries: ContractNodesRegisteredStats[]
}

const ContractNodesRegisteredTable: React.FunctionComponent<ContractNodesRegisteredTableProps> = (props: ContractNodesRegisteredTableProps) => {
    const [activeSortIndex, setActiveSortIndex] = React.useState<number>(1);

    // Sort direction of the currently sorted column
    const [activeSortDirection, setActiveSortDirection] = React.useState<'asc' | 'desc'>('asc');

    const getSortParams = (columnIndex: number): ThProps['sort'] => ({
        sortBy: {
            index: activeSortIndex,
            direction: activeSortDirection
        },
        onSort: (_event, index, direction) => {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
        },
        columnIndex
    });

    function renderRows(rows: ContractNodesRegisteredStats[]) {
        return rows.sort((a, b) => {
            if (activeSortIndex !== null) {
                if (activeSortIndex === 1) {
                    return activeSortDirection === 'asc' ?
                        a.customer_name.localeCompare(b.customer_name) : b.customer_name.localeCompare(a.customer_name);
                }
                if (activeSortIndex === 2) {
                    return activeSortDirection === 'asc' ? a.node_count - b.node_count : b.node_count - a.node_count;
                }
            }
            return activeSortDirection === 'asc' ? a.contract_id - b.contract_id : b.contract_id - a.contract_id;
        }).map((t) => {
            const contractFilter = encodeURIComponent(`contract=${t.contract_id}`);
            return (
                <Tr key={t.contract_id}>
                    <Td><Link to={"/contracts/" + t.contract_id}>{t.contract_id}</Link></Td>
                    <Td><Link to={"/customers/" + t.customer_id}>{t.customer_name}</Link></Td>
                    <Td><Link to={`/customers/${t.customer_id}/?tab=1&filter=` + contractFilter}>{t.node_count}</Link></Td>
                    <Td>{t.contract_comment}</Td>
                </Tr>)
        })
    }

    return (
        <TableComposable
            variant='compact'
            className={styles.smalltable}
        >
            <Thead>
                <Tr>
                    <Th width={10} sort={getSortParams(0)}>ContractId</Th>
                    <Th width={30} sort={getSortParams(1)}>Customer Name</Th>
                    <Th width={10} sort={getSortParams(2)}>#Nodes</Th>
                    <Th >Comment</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderRows(props.entries)}
                <Tr key="0">
                    <Td></Td>
                    <Td><strong>Sum</strong></Td>
                    <Td><strong>{props.entries.map((e) => e.node_count).reduce((pv, cv) => pv + cv, 0)}</strong></Td>
                    <Td></Td>
                </Tr>
            </Tbody>
        </TableComposable>
    )
}

const TODAY = new Date();

const ContractNodesRegisteredPage: React.FunctionComponent = () => {
    const quarterStart = (d: Date) => {
        const q = Math.floor(d.getUTCMonth() / 3);
        const qm = q * 3;
        const rd = new Date();
        setUTC(rd, d.getFullYear(), qm, 1);
        return rd;
    }

    const quarterEnd = (d: Date) => {
        const q = Math.floor(d.getUTCMonth() / 3 + 1);
        const qm = q * 3 - 1;
        const rd = new Date();
        setUTC(rd, d.getFullYear(), qm, qm === 5 || qm === 8 ? 30 : 31);
        return rd;
    }

    const [issuedFromDate, setIssuedFromDate] = React.useState(quarterStart(TODAY));
    const [issuedUntilDate, setIssuedUntilDate] = React.useState(quarterEnd(TODAY));
    const [filterIssuer, setFilterIssuer] = React.useState(0);

    const onChangeIssuedFromDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (date && isValidDate(date)) {
            const newD = new Date();
            setUTC(newD, date.getFullYear(), date.getMonth(), date.getDate());
            setIssuedFromDate(newD);
        }
    }

    const onChangeIssuedUntilDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (date && isValidDate(date)) {
            const newD = new Date();
            setUTC(newD, date.getFullYear(), date.getMonth(), date.getDate());
            setIssuedUntilDate(newD);
        }
    }

    const changeQuarter = (d: Date, change: number) => {
        const rd = new Date();
        setUTC(rd, d.getFullYear(), d.getUTCMonth() + (3 * change), 15);
        setIssuedFromDate(quarterStart(rd));
        setIssuedUntilDate(quarterEnd(rd));
    }

    async function fetchStatsContractNodesRegistered(from: Date, to?: Date, issuer_id?: number): Promise<NodesRegisteredStats> {
        let base_uri = '/manager/stats/nodes-registered';
        let qs = [];
        qs.push("from=" + from.toISOString().slice(0, 19));
        if (to) {
            qs.push("to=" + to.toISOString().slice(0, 19));
        }
        if (issuer_id) {
            qs.push("issuer_id=" + issuer_id);
        }
        const uri = base_uri + (qs.length > 0 ? '?' + qs.join('&') : "");
        return apiGet<NodesRegisteredStats>(uri);
    }

    const QUERY_KEY = ['/manager/stats/nodes-registered', issuedFromDate, issuedUntilDate, filterIssuer];
    const { isLoading, isError, data, error } =
        useQuery<NodesRegisteredStats, string>(
            QUERY_KEY,
            () => fetchStatsContractNodesRegistered(issuedFromDate, issuedUntilDate, filterIssuer));

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>);
    }

    const contract_nodes_registered = data ? data.contract_nodes_registered : [];
    return (
        <React.Fragment>
            <PageSection hasOverflowScroll name="scrolling-section">
                <Toolbar>
                    <ToolbarContent>
                        <ToolbarItem>
                            Contracts nodes registered between
                            <DatePicker
                                id='issued-from-date'
                                appendTo={document.body}
                                onChange={onChangeIssuedFromDate}
                                value={issuedFromDate.toISOString().slice(0, 10)} />&nbsp;
                            and
                        </ToolbarItem>
                        <ToolbarItem>
                            <DatePicker
                                id='issued-from-date'
                                appendTo={document.body}
                                onChange={onChangeIssuedUntilDate}
                                value={issuedUntilDate.toISOString().slice(0, 10)} />&nbsp;
                            for
                        </ToolbarItem>
                        <ToolbarItem>
                            <IssuerSelect
                                noneLabel="<All-Issuers>"
                                issuerId={filterIssuer}
                                onChange={(issuerId: number) => setFilterIssuer(issuerId)} />
                        </ToolbarItem>
                        <ToolbarItem>
                            <Tooltip content="Go 1 quarter past"><Button onClick={() => { changeQuarter(issuedFromDate, -1) }} variant='secondary'>-1q</Button></Tooltip>
                            <Tooltip content="Go 1 quarter forward"><Button onClick={() => { changeQuarter(issuedFromDate, 1) }} variant='secondary'>+1q</Button></Tooltip>
                        </ToolbarItem>
                    </ToolbarContent>
                </Toolbar>
                <br />
                {isLoading ? <Spinner isSVG /> : <ContractNodesRegisteredTable entries={contract_nodes_registered} />}
            </PageSection>
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>)
}

export {
    ContractNodesRegisteredPage
}
