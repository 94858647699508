import * as React from 'react';
import {
    Alert, PageSection, Spinner, BackToTop, Toolbar, ToolbarContent,
    ToolbarItem, DatePicker, isValidDate, Button, Tooltip
} from "@patternfly/react-core"
import { TableComposable, Thead, Tr, Th, Tbody, Td, ThProps } from '@patternfly/react-table';
import { useQuery } from "@tanstack/react-query"
import { Link } from 'react-router-dom';
import DownloadIcon from '@patternfly/react-icons/dist/esm/icons/download-icon';
import saveAs = require('file-saver');

import { CustomerContacts, CustomerContactsResponse } from 'linbit-api-fetcher'
import { apiGet } from '../fetcher';
import * as styles from '../lab.module.css';


interface CustomerContactsTableProps {
    entries: CustomerContacts[]
}

class CustomerContactEntry {
    customer_id: number
    customer_name: string
    email: string

    constructor(customer_id: number, name: string, email: string) {
        this.customer_id = customer_id;
        this.customer_name = name;
        this.email = email;
    }
}

const CustomerContactsTable: React.FunctionComponent<CustomerContactsTableProps> = (props: CustomerContactsTableProps) => {
    const [activeSortIndex, setActiveSortIndex] = React.useState<number>(0);

    // Sort direction of the currently sorted column
    const [activeSortDirection, setActiveSortDirection] = React.useState<'asc' | 'desc'>('asc');

    const getSortParams = (columnIndex: number): ThProps['sort'] => ({
        sortBy: {
            index: activeSortIndex,
            direction: activeSortDirection
        },
        onSort: (_event, index, direction) => {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
        },
        columnIndex
    });

    const filterAndSortCustomers = (): CustomerContactEntry[] => {
        return props.entries.flatMap(customer => {
            return customer.contacts.map(c =>
                new CustomerContactEntry(customer.customer_id, customer.customer_name, c.email))
        }).sort((a, b) => {
            if (activeSortIndex !== null) {
                if (activeSortIndex === 0) {
                    return activeSortDirection === 'asc' ?
                        a.customer_name.localeCompare(b.customer_name) : b.customer_name.localeCompare(a.customer_name);
                }
                if (activeSortIndex === 1) {
                    return activeSortDirection === 'asc' ?
                        a.email.localeCompare(b.email) : b.email.localeCompare(a.email);
                }
            }
            return activeSortDirection === 'asc' ?
                a.customer_name.localeCompare(b.customer_name) : b.customer_name.localeCompare(a.customer_name);
        });
    }

    function csvField(str?: string | null) {
        if (str)
            return '"' + str.replaceAll('"', '""') + '"';
        return "";
    }

    const onExportCSV = () => {
        const filtered = filterAndSortCustomers();

        const csv: string[] = ["Name,Email"];
        for (const c of filtered) {
            const line = [
                csvField(c.customer_name),
                csvField(c.email),
            ];
            csv.push(line.join(","))
        }
        saveAs(new Blob([csv.join("\n")], { type: "text/csv" }), "customers.csv");
    }

    function renderRows(rows: CustomerContacts[]) {
        let i = 0;
        return filterAndSortCustomers().map((t) => {
            return (
                <Tr key={t.customer_name + '/' + t.email}>
                    <Td><Link to={"/customers/" + t.customer_id}>{t.customer_name}</Link></Td>
                    <Td>{t.email}</Td>
                </Tr>)
        })
    }

    return (
        <React.Fragment>
            <Toolbar>
                <ToolbarContent>
                    <ToolbarItem>
                        <Button variant='control' onClick={onExportCSV}><DownloadIcon /> .csv</Button>
                    </ToolbarItem>
                </ToolbarContent>
            </Toolbar>
            <TableComposable
                variant='compact'
                className={styles.smalltable}
            >
                <Thead>
                    <Tr>
                        <Th sort={getSortParams(0)}>Customer Name</Th>
                        <Th sort={getSortParams(1)}>Contact</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {renderRows(props.entries)}
                </Tbody>
            </TableComposable>
        </React.Fragment>
    )
}

const TODAY = new Date();

const CustomerContactsPage: React.FunctionComponent = () => {
    async function fetchCustomerContacts(): Promise<CustomerContactsResponse> {
        let uri = '/manager/reports/customer-with-contacts';
        return apiGet<CustomerContactsResponse>(uri);
    }

    const QUERY_KEY = ['/manager/reports/customer-with-contacts'];
    const { isLoading, isError, data, error } =
        useQuery<CustomerContactsResponse, string>(
            QUERY_KEY,
            () => fetchCustomerContacts());

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>);
    }

    const company_entries = data ? data.list : [];
    return (
        <React.Fragment>
            <PageSection hasOverflowScroll name="scrolling-section">
                {isLoading ? <Spinner isSVG /> : <CustomerContactsTable entries={company_entries} />}
            </PageSection>
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>)
}

export {
    CustomerContactsPage
}
