import * as React from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import {
  Nav,
  NavList,
  NavItem,
  Page,
  PageSidebar,
  SkipToContent,
  Masthead,
  MastheadToggle,
  PageToggleButton,
  MastheadMain,
  MastheadContent,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  ToolbarGroup,
  MastheadBrand,
  Dropdown,
  DropdownGroup,
  DropdownItem,
  DropdownToggle,
  Avatar,
  Button,
  Modal,
  ModalVariant,
  Select,
  SelectOption,
  SelectOptionObject,
  SelectVariant,
  Alert,
  Banner,
  SearchInput,
  NavGroup,
  NavExpandable,
} from '@patternfly/react-core';
import BarsIcon from '@patternfly/react-icons/dist/esm/icons/bars-icon';
import imgAvatar from '../../../assets/images/avatarImg.svg';
import logo from '../../../assets/linbit-logo-white.png';
import { apiGet, apiPost, parseJwt } from '../fetcher';
import { AuthIssuer, AuthIssuerResponse, LoginResponse } from 'linbit-api-fetcher';
import { LdapNameResolver } from '../components';

interface IAppProps {
  handleLogout: (event: React.MouseEvent<any> | React.KeyboardEvent | MouseEvent) => void;
}

const App: React.FunctionComponent<IAppProps> = (props: IAppProps) => {
  const [userDropdownOpen, setUserDropdownOpen] = React.useState(false);
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const [isMobileView, setIsMobileView] = React.useState(false);
  const [isNavOpenMobile, setIsNavOpenMobile] = React.useState(false);
  const [contractIdSearch, setContractIdSearch] = React.useState('');

  const [orgModalError, setOrgModalError] = React.useState("");
  const [isOrgModalOpen, setIsOrgModalOpen] = React.useState(false);
  const [isOrgModalSelectOpen, setIsOrgModalSelectOpen] = React.useState(false);
  const [orgModalSelection, setOrgModalSelection] = React.useState<string | SelectOptionObject>("");

  const [user, setUser] = React.useState('unknown');
  const [roleLabel, setRoleLabel] = React.useState('?');
  const [role, setRole] = React.useState('Manager');
  const [possibleIssuers, setPossibleIssuers] = React.useState<AuthIssuer[]>([])

  const STORAGE_SIDEBAR = "sidebar-state"

  const navigate = useNavigate();

  const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
    setIsMobileView(props.mobileView);
  };

  function LogoImg() {
    const navigate = useNavigate();
    function handleClick() {
      navigate('/', { replace: true });
    }
    return (
      <img src={logo} onClick={handleClick} style={{ height: 30, width: 140 }} alt="Linbit Logo" />
    );
  }

  React.useEffect(() => {
    let token = localStorage.getItem('access-token');
    if (token) {
      let jwt_data = parseJwt(token);
      if (jwt_data) {
        setUser(jwt_data['sub']);
        // setRole(jwt_data['role']);
        apiGet<AuthIssuerResponse>("/auth/issuers")
          .then((resp) => {
            setPossibleIssuers(resp.list);
            let issuer = resp.list.find((issuer) => issuer.id == jwt_data['issuer_id']);
            if (issuer) {
              setRoleLabel(issuer.organization + (jwt_data['role'] == "Admin" ? "*" : ""));
              setRole(jwt_data['role']);
            }
          })
      }
    }

    let sidebarState = localStorage.getItem(STORAGE_SIDEBAR);
    if (sidebarState) {
      if (sidebarState === "open") {
        setIsNavOpen(true);
        setIsNavOpenMobile(true);
      } else {
        setIsNavOpen(false);
        setIsNavOpenMobile(false);
      }
    }
  }, []);

  function isContractId(str: string) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  const onSearchContractId = (_event: React.SyntheticEvent<HTMLButtonElement, Event>, value: string) => {
    if (isContractId(value)) {
      navigate("/contracts/" + value);
      setContractIdSearch('');
    }
  }

  const handleSwitchOrg = () => {
    setIsOrgModalOpen(true);
  }

  const handleSwitchBackOrg = () => {
    let token = localStorage.getItem("original-access-token");
    if (token) {
      localStorage.setItem("access-token", localStorage.getItem("original-access-token") + "");
      localStorage.setItem("refresh-token", localStorage.getItem("original-refresh-token") + "");
      localStorage.removeItem("original-access-token");
      localStorage.removeItem("original-refresh-token");
      navigate(0);
    }
  }

  const handleOrganizationModalToggle = () => {
    setOrgModalError("");
    setIsOrgModalOpen(!isOrgModalOpen);
  }

  const onSelectOrganization = (event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
    value: string | SelectOptionObject,
    isPlaceholder: boolean | undefined) => {
    setOrgModalSelection(value);
    setIsOrgModalSelectOpen(false);
  }

  const onSwitchOrganization = () => {
    let issuer = possibleIssuers.find((issuer) => issuer.name == orgModalSelection);
    if (issuer) {
      console.log("use ", issuer.id);
      apiPost<LoginResponse>("/auth/switch-issuer", { issuer_id: issuer.id })
        .then((resp) => {
          localStorage.setItem("original-access-token", localStorage.getItem("access-token") + "");
          localStorage.setItem("original-refresh-token", localStorage.getItem("refresh-token") + "");
          localStorage.setItem("access-token", resp.access_token);
          localStorage.setItem("refresh-token", resp.refresh_token);
          let jwt_data = parseJwt(resp.access_token);
          if (jwt_data) {
            setUser(jwt_data['sub']);
            setRoleLabel(issuer?.organization + (jwt_data['role'] == "Admin" ? "*" : ""));
          }
          setIsOrgModalOpen(false);
          navigate(0);
        })
        .catch((reason) => {
          setOrgModalError(reason.toString());
        })
    } else {
      setOrgModalError("Couldn't find issuer name: " + orgModalSelection);
    }
  }

  const onNavToggleMobile = () => {
    setIsNavOpenMobile(!isNavOpenMobile);
    localStorage.setItem(STORAGE_SIDEBAR, !isNavOpenMobile ? "open" : "closed");
  }

  const onNavToggleDesktop = () => {
    setIsNavOpen(!isNavOpen);
    localStorage.setItem(STORAGE_SIDEBAR, !isNavOpen ? "open" : "closed");
  }

  const orgSelectItems =
    possibleIssuers.map((issuer) => {
      return (<SelectOption key={issuer.id} value={issuer.name} description={issuer.organization}></SelectOption>)
    });

  const userDropDownItems = [
    <DropdownGroup key="user-drop">
      {possibleIssuers.length > 1 && localStorage.getItem("original-access-token") == null && <DropdownItem key="user-drop-switchorg" onClick={handleSwitchOrg}>Switch Organization</DropdownItem>}
      {localStorage.getItem("original-access-token") && <DropdownItem key="user-drop-end-switchorg" onClick={handleSwitchBackOrg}>Switch back organization</DropdownItem>}
      <DropdownItem key="user-drop-logout" onClick={props.handleLogout}>Logout</DropdownItem>
    </DropdownGroup>
  ];

  const headerToolbar = (
    <Toolbar id="toolbar" isFullHeight isStatic>
      <ToolbarContent>
        <ToolbarGroup
          variant="icon-button-group"
          alignment={{ default: 'alignRight' }}
          spacer={{ default: 'spacerNone', md: 'spacerMd' }}
        >
          <ToolbarItem>
            <SearchInput
              placeholder="contract id"
              value={contractIdSearch}
              onChange={(_e, value) => setContractIdSearch(value)}
              onSearch={onSearchContractId}
              onClear={() => setContractIdSearch('')}
              style={{width: "170px" }}/>
          </ToolbarItem>&nbsp;
          <ToolbarItem>
            <Dropdown
              position='right'
              isFullHeight
              isOpen={userDropdownOpen}
              toggle={
                <DropdownToggle
                  icon={<Avatar src={imgAvatar} alt="Avatar" />}
                  onToggle={(isOpen: boolean) => setUserDropdownOpen(isOpen)}>
                  <LdapNameResolver ldap_uid={user} /><br />({roleLabel})
                </DropdownToggle>}
              dropdownItems={userDropDownItems} />
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>
  );

  const Header = (
    <Masthead>
      <MastheadToggle>
        <PageToggleButton
          variant="plain"
          aria-label='Global navigation'
          isNavOpen={isMobileView ? isNavOpenMobile : isNavOpen}
          onNavToggle={isMobileView ? onNavToggleMobile : onNavToggleDesktop}>
          <BarsIcon />
        </PageToggleButton>
      </MastheadToggle>
      <MastheadMain>
        <MastheadBrand><LogoImg /></MastheadBrand>
        <Nav id="nav-primary-simple" theme="dark" variant='horizontal'>
          <NavList id="nav-list-simple">
            <NavItem key="customers" id="customers">
              <NavLink id="customers-link" to="/customers">Customers</NavLink>
            </NavItem>
          </NavList>
        </Nav>
      </MastheadMain>
      <MastheadContent>{headerToolbar}</MastheadContent>
    </Masthead>
  );

  const SideNavigation = (
    <React.Fragment>
      <Nav id="nav-primary-simple" theme="dark">
        <NavList id="nav-list-simple">
          <NavItem key="dashboard-1" id="dashboard-1">
            <NavLink id="dashboard-link" to="/">Dashboard</NavLink>
          </NavItem>
          <NavItem key="customers" id="customers">
            <NavLink id="customers-link" to="/customers">Customers</NavLink>
          </NavItem>
          {role === "Admin" &&
            <NavItem key="issuers" id="issuers">
              <NavLink id="issuers-link" to="/issuers">Issuers</NavLink>
            </NavItem>
          }
          <NavItem key="license-exp" id="license-exp">
            <NavLink id="license-exp-link" to="/license-expiration">Expiring licenses</NavLink>
          </NavItem>
          <NavItem key="contract-exp" id="contract-exp">
            <NavLink id="contract-exp-link" to="/contract-expiration">Expiring contracts</NavLink>
          </NavItem>
          <NavItem key="contract-by-contact" id="contract-exp">
            <NavLink id="contract-by-contact-link" to="/contracts-by-contact">Contracts by email</NavLink>
          </NavItem>
          {(role === "Admin" || role === "Supporter") &&
            <NavExpandable id="reports-expandable" title="Reports">
              <NavItem key="reports-contract-registered-nodes" id="reports-contract-registered-nodes">
                <NavLink id="reports-contract-registered-nodes-link" to="/reports/contract-nodes-registered">Registered nodes</NavLink>
              </NavItem>
              <NavItem key="reports-customer-contacts" id="reports-customer-contacts">
                <NavLink id="reports-customer-contacts-link" to="/reports/customer-contacts">Customer contacts</NavLink>
              </NavItem>
            </NavExpandable>
          }
          {role === "Admin" &&
            <NavItem key="contract-options" id="contract-options">
              <NavLink id="contract-options-link" to="/contract-options">Contract options</NavLink>
            </NavItem>
          }
        </NavList>
      </Nav>
      <br />
      <Banner>Connected to {process.env.REACT_APP_API_HOST}</Banner>
      <Banner>Version <NavLink id="changelog" to="/changelog">{process.env.REACT_APP_VERSION}</NavLink></Banner>
    </React.Fragment>
  );

  const Sidebar = (
    <PageSidebar
      theme="dark"
      nav={SideNavigation}
      isNavOpen={isMobileView ? isNavOpenMobile : isNavOpen} />
  );

  const pageId = 'primary-app-container';

  const PageSkipToContent = (
    <SkipToContent onClick={(event) => {
      event.preventDefault();
      const primaryContentContainer = document.getElementById(pageId);
      primaryContentContainer && primaryContentContainer.focus();
    }} href={`#${pageId}`}>
      Skip to Content
    </SkipToContent>
  );
  return (
    <Page
      mainContainerId={pageId}
      header={Header}
      sidebar={Sidebar}
      onPageResize={onPageResize}
      skipToContent={PageSkipToContent}>
      <Modal
        variant={ModalVariant.medium}
        title="Switch organization"
        isOpen={isOrgModalOpen}
        onClose={handleOrganizationModalToggle}
        actions={[
          <Button key="confirm" variant="primary" onClick={onSwitchOrganization}>
            Switch
          </Button>,
          <Button key="cancel" variant="secondary" onClick={handleOrganizationModalToggle}>
            Abort
          </Button>
        ]}
      >
        <p>
          Select an organization you want to switch to
        </p>
        <Select
          variant={SelectVariant.single}
          onSelect={onSelectOrganization}
          isOpen={isOrgModalSelectOpen}
          onToggle={(isOpen) => setIsOrgModalSelectOpen(isOpen)}
          selections={orgModalSelection}
        >
          {orgSelectItems}
        </Select>
        {orgModalError && <Alert variant="danger" title={orgModalError} />}
        <div style={{ minHeight: "200px" }}></div>
      </Modal>
      <Outlet />
    </Page>
  );
}

export { App };
