import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
    ContractKind, ContractKindsResponse, ContractOption, ContractOptionsResponse,
    ManagerContractResponse, ContractContactResponse, ContractUnitResponse, Distribution,
    DistributionsResponse, ManagerContract, ManagerCustomer, ManagerCustomerResponse, TicketsResponse,
    LicenseExpiringResponse, LicenseTypeResponse, UserInfoResponse, IssuersResponse, CredentialsResponse,
    Credential,
    ClusterResponse,
} from "linbit-api-fetcher";
import {
    Claims,
    parseJwt,
    apiGet,
    apiGetBlob,
    apiPost,
    apiPut,
    apiDelete
} from "linbit-api-fetcher";

function getCurrentClaims(): Claims {
    const token = localStorage.getItem('access-token');
    let claims = token ?
        parseJwt(token) : { sub: "unkown", exp: 0, role: "Manager", issuer_id: 0, may_issue_licenses: false };
    if (claims) {
        if (!("may_issue_licenses" in claims)) {
            claims["may_issue_licenses"] = false;
        }
    }
    return claims;
}

function isReadOnlyContract(claims: Claims, issuerId: number): boolean {
    return claims["role"] !== "Admin" && issuerId !== claims["issuer_id"];
}

function isReadOnlyCustomer(claims: Claims, contracts: ManagerContract[]): boolean {
    if (claims["role"] === "Admin") {
        return false;
    }
    return contracts.find((contract) => contract.issuer_id === claims["issuer_id"]) === undefined;
}

async function fetchContractKinds(restricted: boolean): Promise<Map<number, ContractKind>> {
    let uri = '/manager/contract-kinds?restricted=' + restricted;
    return apiGet<ContractKindsResponse>(uri).then((ckr) => {
        return ckr.list
            .reduce((opts, co) => {
                opts.set(co.id, co);
                return opts;
            }, new Map<number, ContractKind>());
    });
}

function queryContractKinds(restricted: boolean): UseQueryResult<Map<number, ContractKind>, string> {
    return useQuery<Map<number, ContractKind>, string>(
        ['contract-kinds', restricted], () => fetchContractKinds(restricted), { staleTime: 60 * 60 * 1000 });
}


async function fetchContractOptions(restricted: boolean): Promise<Map<number, ContractOption>> {
    let uri = '/manager/contract-options?restricted=' + restricted;
    return apiGet<ContractOptionsResponse>(uri).then((cor) => {
        let options: Map<number, ContractOption> = cor.list
            .reduce((opts, co) => {
                opts.set(co.id, co);
                return opts;
            }, new Map<number, ContractOption>());
        return options;
    });
}

async function fetchContractContacts(contractId: number): Promise<ContractContactResponse> {
    let uri = '/manager/contracts/' + contractId + '/contacts';
    return apiGet<ContractContactResponse>(uri);
}

async function fetchContractUnits(customerId: number, contractId: number): Promise<ContractUnitResponse> {
    let uri = '/manager/customers/' + customerId + '/contracts/' + contractId + '/units';
    return apiGet<ContractUnitResponse>(uri);
}

async function fetchDistributions(): Promise<Map<number, Distribution>> {
    let uri = '/manager/distributions';
    return apiGet<DistributionsResponse>(uri)
        .then((distriResp) => {
            return distriResp.list
                .reduce((opts, co) => {
                    opts.set(co.id, co);
                    return opts;
                }, new Map<number, Distribution>());
        });
}

async function fetchContractsCommon(
    base_uri: string,
    support_until_from?: Date,
    support_until_till?: Date,
    account_manager?: string,
    issuer_id?: number,
    limit?: number,
    offset?: number): Promise<ManagerContract[]> {
    let qs = [];
    if (support_until_from) {
        qs.push("support_until_from=" + support_until_from.toISOString().slice(0, 10));
    }
    if (support_until_till) {
        qs.push("support_until_till=" + support_until_till.toISOString().slice(0, 10));
    }
    if (account_manager) {
        qs.push("account_manager=" + account_manager);
    }
    if (issuer_id) {
        qs.push("issuer_id=" + issuer_id);
    }
    if (limit) {
        qs.push("limit=" + limit);
    }
    if (offset) {
        qs.push("offset=" + offset);
    }
    const uri = base_uri + (qs.length > 0 ? '?' + qs.join('&') : "");
    return apiGet<ManagerContractResponse>(uri)
        .then((contractResp) => {
            return contractResp.list;
        });
}

async function fetchContracts(
    support_until_from?: Date,
    support_until_till?: Date,
    account_manager?: string,
    issuer_id?: number,
    limit?: number,
    offset?: number): Promise<ManagerContract[]> {
    return fetchContractsCommon(
        '/manager/contracts', support_until_from, support_until_till, account_manager, issuer_id, limit, offset);
}

function queryContracts(
    support_until_from?: Date,
    support_until_till?: Date,
    account_manager?: string,
    issuer_id?: number,
    limit?: number,
    offset?: number): UseQueryResult<ManagerContract[], string> {
    return useQuery<ManagerContract[], string>(
        ["contracts", support_until_from, support_until_till, account_manager, issuer_id, limit, offset],
        () => fetchContracts(support_until_from, support_until_till, account_manager, issuer_id, limit, offset));
}

async function fetchStatsContracts(
    support_until_from?: Date,
    support_until_till?: Date,
    account_manager?: string,
    issuer_id?: number,
    limit?: number,
    offset?: number): Promise<ManagerContract[]> {
    return fetchContractsCommon(
        '/manager/stats/contracts', support_until_from, support_until_till, account_manager, issuer_id, limit, offset);
}

async function fetchContractsByEmail(
    email?: string,
    limit?: number,
    offset?: number): Promise<ManagerContractResponse> {

    if (!email) {
        return new Promise((resolve, reject) => {
            resolve({ count: 0, list: [] })
        });
    }

    let qs = [];
    qs.push("email=" + email);
    if (limit) {
        qs.push("limit=" + limit);
    }
    if (offset) {
        qs.push("offset=" + offset);
    }
    let uri = '/manager/search/contracts-by-contact' + (qs.length > 0 ? '?' + qs.join('&') : "");
    return apiGet<ManagerContractResponse>(uri);
}

async function fetchLicensesExpiring(
    support_until_from?: Date,
    support_until_till?: Date,
    infinite?: boolean,
    limit?: number,
    offset?: number): Promise<LicenseExpiringResponse> {
    let qs = [];
    if (support_until_from) {
        qs.push("support_until_from=" + support_until_from.toISOString().slice(0, 10));
    }
    if (support_until_till) {
        qs.push("support_until_till=" + support_until_till.toISOString().slice(0, 10));
    }
    if (infinite) {
        qs.push("infinite=true");
    }
    if (limit) {
        qs.push("limit=" + limit);
    }
    if (offset) {
        qs.push("offset=" + offset);
    }
    let uri = '/manager/licenses-expiring' + (qs.length > 0 ? '?' + qs.join('&') : "");
    return apiGet<LicenseExpiringResponse>(uri);
}

async function fetchCustomerContracts(customerId: number, expired: boolean): Promise<ManagerContract[]> {
    let uri = '/manager/customers/' + customerId + '/contracts?expired=' + expired;
    return apiGet<ManagerContractResponse>(uri)
        .then((contractResp) => {
            return contractResp.list;
        });
}

function queryCustomerContracts(customerId: number, expired: boolean): UseQueryResult<ManagerContract[], string> {
    return useQuery<ManagerContract[], string>(
        ['contracts', customerId, expired], () => fetchCustomerContracts(customerId, expired));
}

async function fetchContract(contractId: number): Promise<ManagerContract> {
    let uri = '/manager/contracts/' + contractId;
    return apiGet<ManagerContract>(uri);
}

function queryContract(contractId: number): UseQueryResult<ManagerContract, string> {
    return useQuery<ManagerContract, string>(['contract', contractId], () => fetchContract(contractId));
}

async function fetchCustomers(expiredContracts: boolean, contractOptions?: number[], expired_only?: boolean): Promise<ManagerCustomer[]> {
    let uri = '/manager/customers?expired=' + expiredContracts;
    if (contractOptions && contractOptions.length > 0) {
        uri += "&contract_options=" + contractOptions.join(",");
    }
    if (expired_only) {
        uri += '&expired_only=true';
    }
    return apiGet<ManagerCustomerResponse>(uri)
        .then((data) => {
            return data.list;
        });
}

async function fetchTickets(contractId: number): Promise<TicketsResponse> {
    let uri = '/manager/contracts/' + contractId + '/tickets';
    return apiGet<TicketsResponse>(uri);
}

async function fetchIssuers(): Promise<IssuersResponse> {
    let uri = '/manager/issuers';
    return apiGet<IssuersResponse>(uri);
}

function queryIssuers(): UseQueryResult<IssuersResponse, string> {
    return useQuery<IssuersResponse, string>(['issuers'], fetchIssuers);
}

async function fetchLicTypes(): Promise<LicenseTypeResponse> {
    let uri = '/manager/license-types';
    return apiGet<LicenseTypeResponse>(uri);
}

function queryLicTypes(): UseQueryResult<LicenseTypeResponse, string> {
    return useQuery<LicenseTypeResponse, string>(["license-types"], fetchLicTypes);
}

async function fetchLdapUserInfo(): Promise<UserInfoResponse> {
    let uri = '/auth/user-info';
    return apiGet<UserInfoResponse>(uri);
}

function queryLdapUserInfo(): UseQueryResult<UserInfoResponse, string> {
    return useQuery<UserInfoResponse, string>(
        ['user-info'], fetchLdapUserInfo, { staleTime: 1000 * 60 * 30 });
}

async function fetchCustomerCredentials(customerId: number): Promise<Credential[]> {
    let uri = '/manager/customers/' + customerId + '/credentials';
    return apiGet<CredentialsResponse>(uri)
        .then((resp) => {
            return resp.list;
        });
}

async function fetchClusters(customerId: number): Promise<ClusterResponse> {
    let uri = '/manager/customers/' + customerId + '/clusters';
    return apiGet<ClusterResponse>(uri);
}

function queryClusters(customerId: number): UseQueryResult<ClusterResponse, string> {
    return useQuery<ClusterResponse, string>(
        ['clusters', customerId], () => fetchClusters(customerId)
    );
}

export {
    apiGet, apiGetBlob, apiPost, apiPut, apiDelete, fetchContractOptions, queryContractKinds,
    fetchCustomerContracts, fetchContractContacts, fetchContractUnits, fetchContractsByEmail,
    fetchDistributions, fetchContracts, fetchCustomers, fetchTickets, parseJwt, getCurrentClaims,
    fetchLicensesExpiring,
    queryLicTypes,
    queryLdapUserInfo,
    fetchCustomerCredentials,
    queryCustomerContracts,
    queryContract,
    queryIssuers,
    queryContracts,
    queryClusters,
    fetchStatsContracts,
    isReadOnlyContract, isReadOnlyCustomer,
};
