import * as React from 'react';
import {
    ActionGroup,
    Alert,
    AlertActionCloseButton,
    Button,
    Checkbox,
    Drawer,
    DrawerContent,
    DrawerContentBody,
    DrawerHead,
    DrawerPanelBody,
    DrawerPanelContent,
    Form,
    FormAlert,
    FormGroup,
    NumberInput,
    Spinner,
    TextArea,
    Tooltip,
} from '@patternfly/react-core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';
import PencilAltIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon';

import { apiPost, apiDelete, apiPut, fetchContractUnits } from './fetcher';
import { Unit, ContractUnitResponse, CreateUnitRequest, ModifyUnitRequest } from 'linbit-api-fetcher';
import * as styles from './lab.module.css';
import { useNavigate } from 'react-router';
import { formatDateTime } from './formatter';


const QUERY_KEY = ['contract-options'];

interface ContractUnitsTableProps {
    customerId: number;
    contractId: number;
}

const ContractUnitsTable: React.FunctionComponent<ContractUnitsTableProps> = (props: ContractUnitsTableProps) => {
    const [drawerExpanded, setDrawerExpanded] = React.useState(false);
    const [drawerMode, setDrawerMode] = React.useState('Create');
    const [responseError, setResponseError] = React.useState('');
    const [unitId, setUnitId] = React.useState(0);
    const [unitClusters, setUnitClusters] = React.useState(1);
    const [unitNodes, setUnitNodes] = React.useState(2);
    const [unitComment, setUnitComment] = React.useState('');
    const [unitInfinity, setUnitInfinity] = React.useState(false);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const QUERY_KEY = ['contract-units', props.contractId];
    const UNITS_URL = '/manager/customers/' + props.customerId + '/contracts/' + props.contractId + '/units';

    function deleteContractUnit(unit_id: number) {
        apiDelete<String>(UNITS_URL + "/" + unit_id)
            .then((_resp) => {
                queryClient.invalidateQueries(QUERY_KEY);
            })
            .catch((reason) => setResponseError(reason.toString()));
    }

    const triggerEdit = (unit: Unit) => {
        setDrawerMode('Edit');
        setDrawerExpanded(true);
        setUnitId(unit.id);
        setUnitClusters(unit.clusters);
        setUnitNodes(unit.nodes);
        setUnitComment(unit.comment);
        setUnitInfinity(unit.infinity)
    }

    const handleSubmitClick = () => {
        console.log(drawerMode);
        if (drawerMode == 'Create') {
            let createreq: CreateUnitRequest = {
                clusters: unitClusters,
                nodes: unitNodes,
                comment: unitComment,
                infinity: unitInfinity,
            }
            apiPost<Unit>(UNITS_URL, createreq)
                .then((_resp) => {
                    queryClient.invalidateQueries(QUERY_KEY);
                    handleCancelClick();
                }).catch((reason) => {
                    setResponseError(reason.toString());
                });
        } else {
            let createreq: ModifyUnitRequest = {
                clusters: unitClusters,
                nodes: unitNodes,
                comment: unitComment,
                infinity: unitInfinity,
            }
            apiPut<Unit>(UNITS_URL + '/' + unitId, createreq)
                .then((_resp) => {
                    queryClient.invalidateQueries(QUERY_KEY);
                    handleCancelClick();
                }).catch((reason) => {
                    setResponseError(reason.toString());
                });
        }
    }

    const handleCancelClick = () => {
        setDrawerMode('Create');
        setUnitInfinity(false);
        setDrawerExpanded(false);
        setResponseError('');
        setUnitComment('');
        setUnitNodes(2);
        setUnitClusters(1);
    }

    const isUnitTooOld = (unit: Unit): boolean => {
        let dtCreated = new Date(Date.parse(unit.created_at));
        let dt2YearsAgo = new Date(new Date().setDate(new Date().getDate() - 365 * 2));
        return dtCreated < dt2YearsAgo;
    }

    function renderContractUnitsRow(units: Unit[]) {
        return units.map((u) => {
            return (
                <Tr key={u.id}>
                    <Td>{u.clusters}</Td>
                    <Td textCenter>{u.nodes}</Td>
                    <Td textCenter>{formatDateTime(u.created_at)}</Td>
                    <Td textCenter>{u.comment}</Td>
                    <Td textCenter><Checkbox id={"infinity-" + u.id} checked={u.infinity} /></Td>
                    <Td textCenter>
                        <Tooltip content="This unit is too old, editing not allowed, maybe create a new unit.">
                            <Button
                                variant='plain'
                                className={styles.smallpadding}
                                isSmall
                                onClick={() => triggerEdit(u)}
                                isAriaDisabled={isUnitTooOld(u)}>
                                <PencilAltIcon />
                            </Button>
                        </Tooltip>
                        <Button
                            variant='plain'
                            className={styles.smallpadding}
                            isSmall
                            onClick={() => deleteContractUnit(u.id)}>
                            <TrashIcon />
                        </Button>
                    </Td>
                </Tr>
            )
        })
    }

    const panelContent = (
        <DrawerPanelContent>
            <DrawerHead>
                {drawerMode} Contract Unit
            </DrawerHead>
            <DrawerPanelBody>
                <Form>
                    {responseError && (
                        <FormAlert>
                            <Alert
                                variant='danger'
                                title={responseError}
                                isInline
                            />
                        </FormAlert>
                    )}
                    <FormGroup label="Clusters" fieldId='clusters'>
                        <NumberInput
                            id='clusters'
                            value={unitClusters}
                            inputName='clusters'
                            inputAriaLabel='Clusters'
                            onChange={(event: React.FormEvent<HTMLInputElement>) => { setUnitClusters(+event.currentTarget.value) }}
                            onMinus={() => setUnitClusters(unitClusters - 1)}
                            onPlus={() => setUnitClusters(unitClusters + 1)}
                            min={1}
                            isDisabled={unitInfinity}
                        />
                    </FormGroup>
                    <FormGroup label="Nodes" fieldId='nodes'>
                        <NumberInput
                            id='nodes'
                            value={unitNodes}
                            inputName='nodes'
                            inputAriaLabel='Nodes'
                            onChange={(event: React.FormEvent<HTMLInputElement>) => { setUnitNodes(+event.currentTarget.value) }}
                            onMinus={() => setUnitNodes(unitNodes - 1)}
                            onPlus={() => setUnitNodes(unitNodes + 1)}
                            min={1}
                            isDisabled={unitInfinity}
                        />
                    </FormGroup>
                    <FormGroup label="Comment" fieldId='comment'>
                        <TextArea value={unitComment} onChange={(str: string) => setUnitComment(str)} aria-label='comment' />
                    </FormGroup>
                    <FormGroup fieldId="check-infinity">
                        <Checkbox
                            label="Infinity?"
                            id="check-infinity"
                            name="check-infinity"
                            aria-label="infinity"
                            isChecked={unitInfinity}
                            onChange={(checked, _event) => { setUnitInfinity(checked) }}
                        />
                    </FormGroup>
                    <ActionGroup>
                        <Button variant="primary" onClick={handleSubmitClick} isDisabled={false}>{drawerMode === "Edit" ? "Save" : drawerMode}</Button>
                        <Button variant="secondary" onClick={handleCancelClick}>Cancel</Button>
                    </ActionGroup>
                </Form>
            </DrawerPanelBody>
        </DrawerPanelContent>
    )

    const { isLoading, isError, data, error } =
        useQuery<ContractUnitResponse, string>(QUERY_KEY, () => fetchContractUnits(props.customerId, props.contractId));

    if (isError) {
        return <Alert variant='danger' title={error} />
    }

    if (isLoading || !data) {
        return <Spinner isSVG />
    }

    const contractUnits = data?.list ?? [];

    return (
        <React.Fragment>
            <Drawer isExpanded={drawerExpanded}>
                <DrawerContent panelContent={panelContent}>
                    <DrawerContentBody>
                        <Button onClick={() => { setDrawerExpanded(!drawerExpanded) }} isSmall>Create</Button>
                        {responseError && (<Alert
                            variant='danger'
                            title={responseError}
                            actionClose={<AlertActionCloseButton onClose={() => setResponseError('')} />}
                        />)}
                        <TableComposable
                            variant='compact'
                            className={styles.tinytable}
                        >
                            <Thead>
                                <Tr>
                                    <Th>Clusters</Th>
                                    <Th textCenter>Nodes</Th>
                                    <Th textCenter>Created</Th>
                                    <Th textCenter>Comment</Th>
                                    <Th textCenter>Infinity</Th>
                                    <Th textCenter>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {renderContractUnitsRow(contractUnits)}
                            </Tbody>
                        </TableComposable>
                        <br />
                        <Button variant='secondary' onClick={() => navigate(-1)}>Back</Button>
                    </DrawerContentBody>
                </DrawerContent>
            </Drawer>
        </React.Fragment>
    )
}

export { ContractUnitsTable }
