import * as React from 'react';
import {
    Alert, PageSection, Spinner, Title, BackToTop, Button, Split, SplitItem
} from "@patternfly/react-core"
import { useQuery } from "@tanstack/react-query"

import { Ticket, TicketsResponse } from 'linbit-api-fetcher'
import { fetchTickets } from './fetcher';
import { formatTimeDuration } from './formatter';
import { useNavigate, useParams } from 'react-router-dom';
import { TableComposable, Thead, Tr, Th, Tbody, Td, ThProps } from '@patternfly/react-table';

interface TicketTableProps {
    tickets: Ticket[]
}

const TicketTable: React.FunctionComponent<TicketTableProps> = (props: TicketTableProps) => {
    const [activeSortIndex, setActiveSortIndex] = React.useState<number>(0);

    // Sort direction of the currently sorted column
    const [activeSortDirection, setActiveSortDirection] = React.useState<'asc' | 'desc'>('asc');

    const urlSourceMap: {[key: number]: string}  = {
        1: "https://support.linbit.com/otrs/index.pl?Action=AgentZoom&TicketID=",
        2: "https://tickets.linbit.com/agent/ticket/"
    }

    const getSortParams = (columnIndex: number): ThProps['sort'] => ({
        sortBy: {
            index: activeSortIndex,
            direction: activeSortDirection
        },
        onSort: (_event, index, direction) => {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
        },
        columnIndex
    });

    function renderTicketRows(tickets: Ticket[]) {
        return tickets.sort((a, b) => {
            if (activeSortIndex !== null) {
                if (activeSortIndex === 2) {
                    return activeSortDirection === 'asc' ? a.time_unit - b.time_unit : b.time_unit - a.time_unit;
                } else if (activeSortIndex === 3) {
                    const aStatus = a.status ? a.status : "";
                    const bStatus = b.status ? b.status : "";
                    return activeSortDirection === 'asc' ?
                        aStatus.localeCompare(bStatus) : bStatus.localeCompare(aStatus);
                }
            }
            return activeSortDirection === 'asc' ?
                a.ticket_number.localeCompare(b.ticket_number) : b.ticket_number.localeCompare(a.ticket_number);
        }).map((t) => {
            return (
                <Tr key={t.otrs_id}>
                    <Td style={{ fontFamily: "RedHatMono" }}>
                        <a
                            href={urlSourceMap[t.otrs_source] + t.otrs_id}
                            target="_blank"
                            rel="noopener noreferrer">
                            {t.ticket_number}
                        </a>
                    </Td>
                    <Td>{t.title}</Td>
                    <Td style={{ fontFamily: "RedHatMono", textAlign: "right" }}>{formatTimeDuration(t.time_unit)}</Td>
                    <Td>{t.status ? t.status : "unknown"}</Td>
                </Tr>)
        })
    }

    return (
        <TableComposable
            variant='compact'
        // className={styles.smalltable}
        >
            <Thead>
                <Tr>
                    <Th width={10} sort={getSortParams(0)}>Ticket number</Th>
                    <Th>Summary</Th>
                    <Th sort={getSortParams(2)}>Accounted Time</Th>
                    <Th sort={getSortParams(3)}>Status</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderTicketRows(props.tickets)}
            </Tbody>
        </TableComposable>
    )
}

const TicketPage: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const params = useParams();
    const contractId = params.contractId ? parseInt(params.contractId) : 0;

    const { isLoading, isError, data, error } =
        useQuery<TicketsResponse, string>([contractId, 'tickets'], () => fetchTickets(contractId));

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>);
    }

    const ticketCount = data ? data.count : 0;
    const tickets = data ? data.list : [];
    return (
        <React.Fragment>
            <PageSection hasOverflowScroll name="scrolling-section">
                <Split>
                    <SplitItem isFilled>
                        <Title
                            id="title-ticket-head"
                            headingLevel="h1"
                            size="xl">{ticketCount} Tickets for contract {contractId}</Title>
                    </SplitItem>
                    <SplitItem><Button variant='secondary' onClick={() => navigate(-1)}>Back</Button></SplitItem>
                </Split>
                <br />
                {isLoading ? <Spinner isSVG /> : <TicketTable tickets={tickets} />}
            </PageSection>
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>)
}

export {
    TicketPage
}
