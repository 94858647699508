import * as React from 'react';
import {
    Alert, Spinner, FormSelect, FormSelectOption, List, ListItem,
    TextInput, Button, InputGroup, ValidatedOptions
} from "@patternfly/react-core";
import PlusIcon from '@patternfly/react-icons/dist/esm/icons/plus-icon';
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';
import { useQuery } from "@tanstack/react-query";
import { ContractKind, Credential, ManagerContract, OrgUsersResponse } from 'linbit-api-fetcher';

import { apiGet, fetchCustomerContracts, fetchCustomerCredentials, queryLdapUserInfo, queryContractKinds, queryIssuers } from "./fetcher";
import * as styles from './lab.module.css';
import { isContractExpired } from './contract-components';
import { isValidRegex } from './util';


interface ContractIdSelectProps {
    id: string;
    customerId: number;
    contractId: number;
    showExpired: boolean;
    allowNone: boolean;
    onContractIdSelected: (contractId: number) => void;
}

const ContractIdSelect: React.FunctionComponent<ContractIdSelectProps> = (props: ContractIdSelectProps) => {
    const onSelectChange = (val: string) => {
        props.onContractIdSelected(+val);
    }

    const { isLoading, isError, data, error } = useQuery<ManagerContract[], string>(
        ['contracts', props.customerId], () => fetchCustomerContracts(props.customerId, true));

    React.useEffect(() => {
        if (props.contractId === 0 && !props.allowNone) {
            if (data) {
                props.onContractIdSelected(+contracts[0].id);
            }
        }
    }, [data]);

    const ckQuery = queryContractKinds(false);

    if (isError || ckQuery.isError) {
        return <Alert variant='danger' title={error} />
    }

    if (isLoading || ckQuery.isLoading || !data) {
        return <Spinner isSVG />
    }

    const ckMap: Map<number, ContractKind> = ckQuery.data || new Map();
    const contracts: ManagerContract[] = data ? data.filter((c) => {
        if (c.id === props.contractId) {
            return true;
        }
        return props.showExpired || !isContractExpired(c);
    }) : [];

    const monoStyle: React.CSSProperties = {fontFamily: "RedHatMono", fontSize: "0.8em"}
    let options = props.allowNone ? [(<FormSelectOption
        key={0} style={monoStyle} label="<none>" value={0} />)] : [];
    return (
        <FormSelect
            aria-label='Contract id'
            value={props.contractId}
            onChange={onSelectChange}
            id={props.id}>
            {
                options.concat(
                    contracts.map((c) => {
                        const isExpired = isContractExpired(c);
                        const expiredStyle = {...monoStyle, backgroundColor: "#f75353"};
                        return (
                            <FormSelectOption
                                key={c.id}
                                style={isExpired ? expiredStyle : monoStyle}
                                label={("#" + c.id).padEnd(6, "\u00A0") + " - "
                                    + ckMap.get(c.kind_id)?.name.padEnd(10, "\u00A0") + " - "
                                    + c.support_until + (isExpired ? " - expired" : "")}
                                value={c.id} />
                        )
                    }))
            }
        </FormSelect>
    )
}

interface CredentialSelectProps {
    id: string;
    customerId: number;
    credentialId: number;
    allowNone: boolean;
    onCredentialSelected: (credentialId: number) => void;
}

const CredentialSelect: React.FunctionComponent<CredentialSelectProps> = (props: CredentialSelectProps) => {
    const onSelectChange = (val: string) => {
        props.onCredentialSelected(+val);
    }

    const { isLoading, isError, data, error } = useQuery<Credential[], string>(
        ['credential', props.customerId], () => fetchCustomerCredentials(props.customerId));

    if (isError) {
        return <Alert variant='danger' title={error} />
    }

    if (isLoading || !data) {
        return <Spinner isSVG />
    }

    const credentials: Credential[] = data || [];

    let options = props.allowNone ? [(<FormSelectOption key={0} label="<none>" value={0} />)] : [];
    return (
        <FormSelect
            aria-label='Credential'
            value={props.credentialId}
            onChange={onSelectChange}
            id={props.id}>
            {
                options.concat(
                    credentials
                        .filter((c) => c.enabled)
                        .map((c) => {
                            return (
                                <FormSelectOption key={c.id} label={c.username + " - " + c.id} value={c.id} />
                            )
                        }))
            }
        </FormSelect>
    )
}

interface SelectListProps {
    id: string;
    values: Array<string>;
    onListChange: (vals: Array<string>) => void;
    inputRegex?: string;
    maxLength?: number;
    style?: React.CSSProperties | undefined;
}

const SelectList: React.FunctionComponent<SelectListProps> = (props: SelectListProps) => {
    const [editValue, setEditValue] = React.useState('');
    const [inputValidated, setInputValidated] = React.useState<ValidatedOptions>(ValidatedOptions.default);

    const handleAddValue = () => {
        let newVals = [...props.values];
        newVals.push(editValue);
        setEditValue('')
        props.onListChange(newVals);
        setInputValidated(ValidatedOptions.default);
    }

    const handleRemoveValue = (idx: number) => {
        let newVals = [...props.values];
        newVals.splice(idx, 1);
        props.onListChange(newVals);
    }

    const onKeyPressEditValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && inputValidated === ValidatedOptions.success) {
            handleAddValue();
        }
    }

    const onInputChange = (val: string) => {
        setInputValidated(isInputValid(val));
        setEditValue(val);
    }

    const isInputValid = (input: string) => {
        if (input && props.inputRegex) {
            const regex = isValidRegex(props.inputRegex) ? new RegExp(props.inputRegex) : new RegExp("");
            if (!input || regex.test(input) === false) {
                return ValidatedOptions.error;
            }
            return ValidatedOptions.success;
        }
        return ValidatedOptions.default;
    }

    return (
        <React.Fragment><List isPlain>
            {props.values.map((v, idx) =>
                <ListItem key={idx} style={props.style}>{v}
                    <Button
                        variant='plain'
                        className={styles.smallpadding}
                        isSmall
                        onClick={() => handleRemoveValue(idx)}><TrashIcon /></Button>
                </ListItem>)}
        </List>
            <InputGroup>
                <TextInput
                    name="addtolist"
                    style={props.style}
                    id={props.id}
                    type="text"
                    aria-label="add value"
                    value={editValue}
                    placeholder='new value'
                    onChange={onInputChange}
                    onKeyPress={onKeyPressEditValue}
                    maxLength={props.maxLength}
                    validated={inputValidated} />
                <Button
                    variant="control"
                    aria-label="add value button"
                    onClick={handleAddValue}
                    isDisabled={inputValidated !== ValidatedOptions.success}>
                    <PlusIcon />
                </Button>
            </InputGroup>
        </React.Fragment>
    )
}

interface AccountManagerSelectProps {
    noneLabel: string,
    accountManager: string,
    onChange: (accountManager: string) => void,
}

const AccountManagerSelect: React.FunctionComponent<AccountManagerSelectProps> = (props: AccountManagerSelectProps) => {
    const onChangeAccountManager = (val: string) => {
        props.onChange(val);
    }

    async function fetchOrganizationUsers(): Promise<string[]> {
        let uri = '/auth/organization-users';
        return apiGet<OrgUsersResponse>(uri)
            .then((resp) => {
                return resp.list;
            });
    }

    const { isLoading, isError, data, error } =
        useQuery<string[], string>(['organization-users'], fetchOrganizationUsers);

    const userInfoQuery = queryLdapUserInfo();

    if (isError) {
        return (<span>{error}</span>)
    } else {
        if (isLoading || !data) {
            return (<Spinner isSVG />)
        } else {
            return (<FormSelect id="form-acc-mgr" value={props.accountManager} onChange={onChangeAccountManager}>
                <FormSelectOption key={props.noneLabel} label={props.noneLabel} value="" />
                {data.sort((a, b) => a.localeCompare(b))
                    .map((accMgr) => {
                        let accMgrName = accMgr;
                        if (!(userInfoQuery.isError || userInfoQuery.isLoading || !userInfoQuery.data)
                                && accMgr in userInfoQuery.data.users) {
                            accMgrName = userInfoQuery.data?.users[accMgr].name;
                        }
                        return (<FormSelectOption key={accMgr} label={accMgrName} value={accMgr} />)
                    })}
            </FormSelect>)
        }
    }
}

interface IssuerSelectProps {
    noneLabel: string,
    issuerId: number,
    onChange: (issuerId: number) => void,
}

const IssuerSelect: React.FunctionComponent<IssuerSelectProps> = (props: IssuerSelectProps) => {
    const onChangeIssuer = (val: string) => {
        props.onChange(+val);
    }

    const { isLoading, isError, data, error } = queryIssuers();

    if (isError) {
        return (<span>{error}</span>)
    } else {
        if (isLoading || !data) {
            return (<Spinner isSVG />)
        } else {
            const issuers = data.list;
            return (<FormSelect id="form-issuer-select" value={props.issuerId} onChange={onChangeIssuer}>
                <FormSelectOption key={props.noneLabel} label={props.noneLabel} value="" />
                {issuers.sort((a, b) => a.name.localeCompare(b.name))
                    .map((issuer) => {
                        return (<FormSelectOption key={issuer.id} label={issuer.name} value={issuer.id} />)
                    })}
            </FormSelect>)
        }
    }
}

export {
    ContractIdSelect,
    SelectList,
    AccountManagerSelect,
    CredentialSelect,
    IssuerSelect,
}
