import * as React from 'react';
import {
    Button,
    Tooltip,
} from '@patternfly/react-core';
import RedoIcon from '@patternfly/react-icons/dist/esm/icons/redo-icon';
import ListIcon from '@patternfly/react-icons/dist/esm/icons/list-icon';
import PencilAltIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon';

import {
    TableComposable, Tbody, Th, Thead, Tr, Td
} from '@patternfly/react-table';

import * as styles from './lab.module.css';
import { getBgColor } from './table-helper';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatTimeDuration } from './formatter';
import { ContractContacts, ContractKindResolver, ContractOptionsView, CustomerNameResolver, isContractExpired, ToolTipLink } from './contract-components';
import { ManagerContract } from 'linbit-api-fetcher';
import { getCurrentClaims, isReadOnlyContract } from './fetcher';
import { LdapNameResolver } from './components';

interface ContractViewTableProps {
    contracts: ManagerContract[],
    showContacts?: boolean
}

const ContractViewTable: React.FunctionComponent<ContractViewTableProps> = (props: ContractViewTableProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const claims = getCurrentClaims();

    React.useEffect(() => {
        // get anchor links working, DOM not yet populated HACKY
        const anchor = window.location.hash.slice(1);
        if (anchor) {
            setTimeout(() => {
                const anchorEl = document.getElementById(anchor);
                if (anchorEl) {
                    anchorEl.scrollIntoView();
                }
            }, 500)
        }
    }, [location]);

    function renderContractData(contracts: ManagerContract[]) {
        return contracts
            .map((cs, index) => {
                const ctId = "ct-" + cs.id;
                const isExpired = isContractExpired(cs);
                return (
                    <Tr key={cs.id} style={{ backgroundColor: getBgColor(1, location.hash === "#" + ctId, isExpired) }} id={ctId}>
                        <Td style={{ whiteSpace: "nowrap" }}>
                            <Link to={"/customers/" + cs.customer_id + (isExpired ? "?tab=2" : "") + "#ct-" + cs.id}>{cs.id}</Link><br />
                            <ToolTipLink tooltipContent={"Followup contract id"} prefix={"f: "} contractId={cs.followup_contract_id} />
                            {cs.followup_contract_id && <br />}
                            <ToolTipLink tooltipContent={"Origin/Root contract id"} prefix={"o: "} contractId={cs.origin_contract_id} />
                        </Td>
                        <Td><Link to={"/customers/" + cs.customer_id}><CustomerNameResolver customerId={cs.customer_id} /></Link></Td>
                        <Td><LdapNameResolver ldap_uid={cs.account_manager_ldap} /></Td>
                        <Td><ContractKindResolver kind={cs.kind_id} /></Td>
                        <Td textCenter style={{ fontFamily: "RedHatMono", fontSize: "0.7em", whiteSpace: "nowrap" }}>
                            <p>{cs.support_from}</p>
                            <p>{cs.download_until}</p>
                            <p>{cs.support_until}</p>
                        </Td>
                        <Td textCenter>
                            <Tooltip content="Registered / Max">
                                <p>
                                    <Link to={`/customers/${cs.customer_id}/?tab=1&filter=` + encodeURIComponent(`contract=${cs.id}`)}>{cs.registered_nodes}</Link> / {cs.infinite_nodes ? "∞" : cs.supported_nodes}
                                </p>
                            </Tooltip>
                            <Tooltip content="Tickets / Max"><p>{cs.tickets} / {cs.max_tickets === 0 ? "∞" : cs.max_tickets}</p></Tooltip>
                            {cs.tickets > 0 ? <Tooltip content="Ticket list"><Link to={`/contracts/${cs.id}/tickets`}><ListIcon /></Link></Tooltip> : <ListIcon />}
                        </Td>
                        <Td textCenter>{formatTimeDuration(cs.accounted_time)}</Td>
                        <Td>
                            <p style={{ backgroundColor: '#e7dbc6' }}>Send reminder:<br />{cs.send_reminders ? 'yes' : 'no'}</p>
                            <p style={{ backgroundColor: '#b7bcdf' }}>Issuer:<br />{cs.issuer_name}</p>
                        </Td>
                        <Td>{cs.comment}</Td>
                        {props.showContacts ? <Td><ContractContacts contractId={cs.id} /></Td> : null}
                        <Td><ContractOptionsView options={cs.options} /></Td>
                        <Td textCenter>
                            <Button
                                variant='plain'
                                id={"edit-contract-" + cs.id}
                                onClick={() => navigate("/customers/" + cs.customer_id + "/contracts/" + cs.id)}
                                isDisabled={isReadOnlyContract(claims, cs.issuer_id)}>
                                <Tooltip content="Edit contract"><PencilAltIcon /></Tooltip>
                            </Button><br />
                            <Button
                                variant='plain'
                                id={"renew-contract-" + cs.id}
                                onClick={() => navigate("/customers/" + cs.customer_id + "/contracts/" + cs.id + "?renew=true")}>
                                <Tooltip content="Renew contract"><RedoIcon /></Tooltip>
                            </Button>
                        </Td>
                    </Tr>
                )
            })
    }

    return (
        <TableComposable
            variant='compact'
            className={styles.tinytable}
            style={{ border: "2px" }}
        >
            <Thead>
                <Tr>
                    <Th>ID</Th>
                    <Th>Customer</Th>
                    <Th>Account Mgr</Th>
                    <Th>Support level</Th>
                    <Th>From<br />Download<br />Expires</Th>
                    <Th>Nodes<br />Tickets</Th>
                    <Th>Time</Th>
                    <Th>Info</Th>
                    <Th width={25}>Comment</Th>
                    {props.showContacts ? <Th width={15}>Contacts</Th> : null}
                    <Th width={15}>Options</Th>
                    <Th textCenter>Actions</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderContractData(props.contracts)}
            </Tbody>
        </TableComposable>
    )
}

export { ContractViewTable }
