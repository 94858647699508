import * as React from 'react';
import {
    Alert, BackToTop, PageSection, Spinner, Title
} from "@patternfly/react-core"

import { ContractViewTable } from "./contracts-view"
import { queryContract } from './fetcher';
import { useParams } from 'react-router-dom';

const ContractPage: React.FunctionComponent = () => {
    const params = useParams();
    const contractId = params.contractId ? parseInt(params.contractId) : 0;

    const { isLoading, isError, data, error } = queryContract(contractId)

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>);
    }

    const contracts = data ? [data] : [];
    return (
        <React.Fragment>
            <PageSection hasOverflowScroll name="scrolling-section">
                <Title headingLevel="h1" size="xl">Contract {contractId}</Title>
                <br />
                {isLoading ? <Spinner isSVG /> :
                    <ContractViewTable contracts={contracts} />
                }
            </PageSection>
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>)
}

export {
    ContractPage
}
