import * as React from 'react';
import {
    Alert, BackToTop, Badge, DatePicker, isValidDate, PageSection, Spinner,
    Title, Toolbar, ToolbarContent, ToolbarItem
} from "@patternfly/react-core"

import { ContractViewTable } from "./contracts-view"
import { queryContracts } from './fetcher';
import { AccountManagerSelect, IssuerSelect } from './form-components';

const ContractsPage: React.FunctionComponent = () => {
    const [issuedFromDate, setIssuedFromDate] = React.useState(new Date(new Date().setDate(1)).toISOString().slice(0, 10));
    const [issuedUntilDate, setIssuedUntilDate] = React.useState(new Date().toISOString().slice(0, 10));
    const [filterIssuer, setFilterIssuer] = React.useState(0);
    const [filterAccManager, setFilterAccManager] = React.useState('');

    const onChangeIssuedFromDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (date && isValidDate(date)) {
            setIssuedFromDate(str);
        }
    }

    const onChangeIssuedUntilDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (date && isValidDate(date)) {
            setIssuedUntilDate(str);
        }
    }

    const support_until_from = new Date(Date.parse(issuedFromDate));
    const { isLoading, isError, data, error } = queryContracts(
        support_until_from, undefined, filterAccManager, filterIssuer);

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>);
    }

    const contractsAll = data ? data : [];
    const contracts = contractsAll.filter((c) => {
        const supportFrom = new Date(c.support_from);
        const filterIssuedFrom = new Date(issuedFromDate);
        const filterIssuedUntil = new Date(issuedUntilDate);
        return filterIssuedFrom < supportFrom && supportFrom < filterIssuedUntil;
    });

    return (
        <React.Fragment>
            <PageSection hasOverflowScroll name="scrolling-section">
                <Title headingLevel="h1" size="xl">Contracts</Title>
                <Toolbar>
                    <ToolbarContent>
                        <ToolbarItem>
                            Show contracts issued between
                            <DatePicker
                                id='issued-from-date'
                                appendTo={document.body}
                                onChange={onChangeIssuedFromDate}
                                value={issuedFromDate} />&nbsp;
                            and
                        </ToolbarItem>
                        <ToolbarItem>
                            <DatePicker
                                id='issued-from-date'
                                appendTo={document.body}
                                onChange={onChangeIssuedUntilDate}
                                value={issuedUntilDate} />
                        </ToolbarItem>
                        <ToolbarItem>
                            <AccountManagerSelect
                                noneLabel="<All-Account-Manager>"
                                accountManager={filterAccManager}
                                onChange={(val: string) => setFilterAccManager(val)} />
                        </ToolbarItem>
                        <ToolbarItem>
                            <IssuerSelect
                                noneLabel="<All-Issuers>"
                                issuerId={filterIssuer}
                                onChange={(issuerId: number) => setFilterIssuer(issuerId)} />
                        </ToolbarItem>

                    </ToolbarContent>
                </Toolbar>
                <p>
                    Found <Badge>{contracts.length}</Badge> contracts issued.
                </p>
                <br />
                {isLoading ? <Spinner isSVG /> :
                    <ContractViewTable contracts={contracts} />
                }
            </PageSection>
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>)
}

export {
    ContractsPage
}
