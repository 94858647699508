import * as React from 'react';
import {
    Alert, BackToTop, Badge, NumberInput, PageSection, Spinner,
    Title, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem
} from "@patternfly/react-core"
import { useQuery } from "@tanstack/react-query"

import { ManagerContract } from 'linbit-api-fetcher'
import { ContractViewTable } from "./contracts-view"
import { fetchContracts } from './fetcher';
import { AccountManagerSelect } from './form-components';
import { formatDate } from './formatter';

const ContractExpirationPage: React.FunctionComponent = () => {
    const [daysBefore, setDaysBefore] = React.useState(7);
    const [daysFuture, setDaysFuture] = React.useState(14);
    const [filterAccManager, setFilterAccManager] = React.useState('');

    const QUERY_KEY = ["contracts", daysBefore, daysFuture, filterAccManager];

    const support_until_from = new Date(new Date().setDate(new Date().getDate() - daysBefore));
    const support_until_till = new Date(new Date().setDate(new Date().getDate() + daysFuture));
    const { isLoading, isError, data, error } =
        useQuery<ManagerContract[], string>(QUERY_KEY, () => fetchContracts(support_until_from, support_until_till, filterAccManager));

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>);
    }

    const contracts = data ? data : [];
    return (
        <React.Fragment>
            <PageSection hasOverflowScroll name="scrolling-section">
                <Title headingLevel="h1" size="xl">Expiring contracts</Title>
                <Toolbar>
                    <ToolbarContent>
                        <ToolbarItem>
                            Show contracts expiring between
                            <NumberInput
                                id='days-before'
                                value={daysBefore}
                                inputName='days before'
                                inputAriaLabel='days before expiration'
                                onChange={(event) => setDaysBefore(+event.currentTarget.value)}
                                onMinus={() => setDaysBefore(daysBefore - 1)}
                                onPlus={() => setDaysBefore(daysBefore + 1)}
                                min={0}
                                widthChars={2}
                            />
                            days in the past and
                        </ToolbarItem>
                        <ToolbarItem>
                            <NumberInput
                                id='days-future'
                                value={daysFuture}
                                inputName='days after'
                                inputAriaLabel='days after expiration'
                                onChange={(event) => setDaysFuture(+event.currentTarget.value)}
                                onMinus={() => setDaysFuture(daysFuture - 1)}
                                onPlus={() => setDaysFuture(daysFuture + 1)}
                                min={1}
                                widthChars={2}
                            />
                            days in the future by
                        </ToolbarItem>
                        <ToolbarGroup>
                            <ToolbarItem>
                                <AccountManagerSelect
                                    noneLabel="<All-Account-Manager>"
                                    accountManager={filterAccManager}
                                    onChange={(val: string) => setFilterAccManager(val)} />
                            </ToolbarItem>
                        </ToolbarGroup>
                    </ToolbarContent>
                </Toolbar>
                <p>
                    Found <Badge>{contracts.length}</Badge> contracts expiring between {formatDate(support_until_from)} and {formatDate(support_until_till)}.
                </p>
                <br />
                {isLoading ? <Spinner isSVG /> :
                    <ContractViewTable contracts={contracts} />
                }
            </PageSection>
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>)
}

export {
    ContractExpirationPage
}
