import * as React from 'react';
import * as Mustache from 'mustache';
import {
    Alert, PageSection, Title, Button, TextArea, Form, FormGroup, TextInput, ActionGroup, FormAlert,
    ValidatedOptions, Switch, Grid, GridItem, Badge, ExpandableSection
} from "@patternfly/react-core"

import { useNavigate, useParams } from 'react-router-dom';
import { apiPost, fetchContractContacts, queryIssuers, queryContract, queryContractKinds, queryLdapUserInfo } from './fetcher';
import { ContractContactResponse, SendMailRequest } from 'linbit-api-fetcher';
import { Tr, Td, TableComposable, Thead, Th, Tbody } from '@patternfly/react-table';
import { useQuery } from '@tanstack/react-query';


interface MailContactsTableProps {
    contractId: number;
    contacts: MailContactEntry[];
    onSwitchSend: (idx: number) => void;
    onAddContactClick: (name: string, email: string) => void;
}

class MailContactEntry {
    name: string | undefined | null;
    email: string;
    send: boolean;
    isCC: boolean;

    public constructor(name: string | undefined | null, email: string) {
        this.name = name;
        this.email = email;
        this.send = true;
        this.isCC = false;
    }

    public toString() {
        return this.name ? this.name + " <" + this.email + ">" : this.email
    }
}

const MailContactsTable: React.FunctionComponent<MailContactsTableProps> = (props: MailContactsTableProps) => {
    const [editName, setEditName] = React.useState('');
    const [editEmail, setEditEmail] = React.useState('');
    const [emailValidated, setEmailValidated] = React.useState<ValidatedOptions>(ValidatedOptions.default);

    const clearFields = () => {
        setEditName('');
        setEditEmail('');
        setEmailValidated(ValidatedOptions.default);
    }

    const handleAddClick = () => {
        props.onAddContactClick(editName, editEmail);
        clearFields();
    }

    const handleEmailChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
        setEditEmail(value);
        setEmailValidated(isEmailValid(value));
    };

    const isEmailValid = (input: string) => {
        if (input) {
            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!input || regex.test(input) === false) {
                return ValidatedOptions.error;
            }
            return ValidatedOptions.success;
        }
        return ValidatedOptions.default;
    }

    function renderMailContactRow(contacts: MailContactEntry[]) {
        return contacts.map((c, index) => {
            const name = c.name ? c.name + " <" + c.email + ">" : c.email;
            return (
                <Tr key={index}>
                    <Td>{name}</Td>
                    <Td textCenter>
                        {c.isCC ?
                            <Badge>CC</Badge> :
                            <Switch
                                isChecked={c.send}
                                onChange={() => props.onSwitchSend(index)}
                                aria-label="send mail" />
                        }
                    </Td>
                </Tr>
            )
        })
    }

    return (
        <TableComposable
            variant='compact'
        >
            <Thead>
                <Tr>
                    <Th>Email recipients</Th>
                    <Th textCenter>Send mail?</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderMailContactRow(props.contacts)}
                <Tr>
                    <Td>
                        <Form>
                            <Grid hasGutter md={6}>
                                <GridItem>
                                    <FormGroup
                                        label="Name"
                                        fieldId="email-name">
                                        <TextInput
                                            type='text'
                                            id='email-name'
                                            name='email-name'
                                            value={editName}
                                            onChange={(val) => { setEditName(val) }}
                                        />
                                    </FormGroup>
                                </GridItem>
                                <GridItem>
                                    <FormGroup
                                        label="Email"
                                        isRequired
                                        fieldId="contract-email">
                                        <TextInput
                                            type='email'
                                            id='contract-email'
                                            name='contract-email'
                                            value={editEmail}
                                            onChange={handleEmailChange}
                                            validated={emailValidated}
                                        />
                                    </FormGroup>
                                </GridItem>
                            </Grid>
                        </Form>
                    </Td>
                    <Td textCenter style={{ verticalAlign: "bottom" }}>
                        <Button
                            onClick={handleAddClick}
                            isDisabled={emailValidated !== ValidatedOptions.success}>
                            Add</Button>
                    </Td>
                </Tr>
            </Tbody>
        </TableComposable >
    )
}


const SendMailPage: React.FunctionComponent = () => {
    const [mailSubject, setMailSubject] = React.useState('Your LINBIT Account Data');
    const [mailText, setMailText] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [mailContacts, setMailContacts] = React.useState<MailContactEntry[]>([]);

    const navigate = useNavigate();
    const params = useParams();
    const contractId = params.contractId ? parseInt(params.contractId) : 0;

    const mailTemplate = `Thank you for purchasing LINBIT {{support_level}} Support,

The best way to get LINBIT's software installed on your machines
is to download a small python helper from here
    https://my.linbit.com/linbit-manage-node.py
and execute it on your target machines.

For your convenience here is a copy and paste template:
    cd /tmp && curl -O https://my.linbit.com/linbit-manage-node.py && chmod u+x linbit-manage-node.py
    ./linbit-manage-node.py

The script will guide you through configuring the right
LINBIT package repositories.
(Supported operating systems are: RHEL/OL/Alma/Rocky, SLES, Debian/Ubuntu)

You can find more information at https://my.linbit.com/, regarding
    * your account
    * account password
    * subscription contracts
    * registered nodes and clusters
    * how to open support tickets
    * add/edit/remove contact e-mail addresses

If you do not have a password for https://my.linbit.com you can create it on
the site by following the "Forgot/Reset password" link. These e-mail addresses
are currently registered for this contract:

{{#contacts}}
  {{email}}
{{/contacts}}

For your reference, here are your account details:

    Level:          {{support_level}}
    Support until:  {{support_until}}
    Download until: {{download_until}}

PS: The most convenient way of asking LINBIT a technical question, is to
    send an e-mail to tickets@linbit.com. (it needs to come from one of the
    registered e-mail addresses, listed above)

Best regards,
    Your LINBIT Team`;

    const onSubmit = () => {
        let reply_to = undefined;

        if (issuersQuery.data) {
            const issuer = issuersQuery.data.list.find((i) => i.id == data?.issuer_id);
            if (issuer && issuer.email) {
                reply_to = { name: issuer.name, email: issuer.email }
            }
        }

        const ccContacts = mailContacts.filter((mc) => mc.isCC);

        const req: SendMailRequest = {
            subject: mailSubject,
            text: mailText,
            to_contacts: mailContacts.filter((mc) => mc.send && !mc.isCC),
            cc_contacts: ccContacts,
            reply_to: reply_to,
            from: ccContacts.length > 0 ? ccContacts[0] : undefined
        };
        console.log(req);
        apiPost('/manager/mail', req).then(() => {
            setErrorMessage('')
            navigate(-1);
        }).catch((reason) => {
            setErrorMessage(reason);
        })
    }

    const checkRequiredOk = () => {
        return mailText.trim().length > 0 && mailSubject.trim().length > 0;
    }

    const onSwitchSend = (idx: number) => {
        const entries = [...mailContacts];
        entries[idx].send = !entries[idx].send;
        setMailContacts(entries);
    }

    const onAddContactClick = (name: string, email: string) => {
        const entries = [...mailContacts];
        entries.push(new MailContactEntry(name ? name : null, email));
        setMailContacts(entries);
    }

    const { isLoading, isError, data, error } = queryContract(contractId);
    const ckQuery = queryContractKinds(false);

    const contactsQuery = useQuery<ContractContactResponse, string>(
        ['contract-contacts', contractId], () => fetchContractContacts(contractId));

    const userInfoQuery = queryLdapUserInfo();

    const issuersQuery = queryIssuers();

    React.useEffect(() => {
        const contractContacts = contactsQuery.data?.list ?? [];
        const ckId = data ? data.kind_id : 0;
        const ck = ckQuery.data?.get(ckId);
        const ckName = ck ? ck.name : "unknown";
        const view = {
            support_level: ckName.charAt(0).toUpperCase() + ckName.slice(1),
            support_until: data?.support_until,
            download_until: data?.download_until,
            contacts: contractContacts,
        }
        const output = Mustache.render(mailTemplate, view);
        setMailText(output);

        const mc = contractContacts.map((c) => new MailContactEntry(null, c.email));
        const ldapuid = data && data.account_manager_ldap ? data.account_manager_ldap : "_idonotexistsforever_";
        if (userInfoQuery.data && ldapuid in userInfoQuery.data.users) {
            const userInfo = userInfoQuery.data?.users[ldapuid];
            if (userInfo.email) {
                const ccContact = new MailContactEntry(userInfo.name, userInfo.email);
                ccContact.isCC = true;
                mc.push(ccContact);
            }
        }
        setMailContacts(mc);
    }, [data, ckQuery.data]);

    const recpList = mailContacts.filter((c) => c.send);
    const recpStr = recpList.map((c) => c.email.substring(0, c.email.indexOf("@") + 1)).join(", ");
    const recpToggleText = recpList.length + ' Reciepients [' + (recpStr.length > 99 ? recpStr.substring(0, 100) + "..." : recpStr) + "]";

    return (
        <React.Fragment>
            <PageSection>
                <Title
                    id="title-ticket-head"
                    headingLevel="h1"
                    size="xl">Sending mail for new Linbit contract</Title>
                <ExpandableSection
                    toggleText={recpToggleText}
                    displaySize='large'
                    isIndented>
                    <MailContactsTable
                        contractId={contractId}
                        contacts={mailContacts}
                        onSwitchSend={onSwitchSend}
                        onAddContactClick={onAddContactClick} />
                </ExpandableSection>
            </PageSection>
            <PageSection>
                <Form isWidthLimited>
                    <FormGroup
                        label="Subject"
                        isRequired
                        fieldId='mail-subject'>
                        <TextInput id="mail-subject" value={mailSubject} onChange={(val) => setMailSubject(val)} aria-label="mail subject" />
                    </FormGroup>
                    <FormGroup
                        label="Text"
                        isRequired
                        fieldId='mail-text'>
                        <TextArea
                            id="mail-text"
                            style={{ height: "600px", fontSize: "0.80em", fontFamily: "RedHatMono" }}
                            value={mailText}
                            onChange={(val) => setMailText(val)}
                            aria-label="mail text" />
                    </FormGroup>
                    {errorMessage && <FormAlert><Alert variant='danger' title={errorMessage} isInline /></FormAlert>}
                    <ActionGroup>
                        <Button variant='primary' onClick={onSubmit} isDisabled={!checkRequiredOk()}>Send mail</Button>
                        <Button variant='secondary' onClick={() => navigate(-1)}>Cancel</Button>
                    </ActionGroup>
                </Form>
            </PageSection>
        </React.Fragment>
    )
}

export {
    SendMailPage
}
