import * as React from 'react';
import {
    Alert, PageSection, Spinner, Title, BackToTop, Badge
} from "@patternfly/react-core"

import { Issuer } from 'linbit-api-fetcher'
import { queryIssuers } from './fetcher';
import { useNavigate } from 'react-router-dom';
import { TableComposable, Thead, Tr, Th, Tbody, Td, ThProps } from '@patternfly/react-table';

interface IssuersTableProps {
    tickets: Issuer[]
}

const IssuersTable: React.FunctionComponent<IssuersTableProps> = (props: IssuersTableProps) => {
    const [activeSortIndex, setActiveSortIndex] = React.useState<number>(0);

    // Sort direction of the currently sorted column
    const [activeSortDirection, setActiveSortDirection] = React.useState<'asc' | 'desc'>('asc');

    const getSortParams = (columnIndex: number): ThProps['sort'] => ({
        sortBy: {
            index: activeSortIndex,
            direction: activeSortDirection
        },
        onSort: (_event, index, direction) => {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
        },
        columnIndex
    });

    const boolBadge = (val: boolean) => {
        return val ?
            <Badge style={{backgroundColor: 'green'}}>yes</Badge> : <Badge style={{backgroundColor: 'red'}}>no</Badge>;
    }

    function renderIssuerRows(issuers: Issuer[]) {
        return issuers.sort((a, b) => {
            switch (activeSortIndex) {
                case 1: {
                    return activeSortDirection === 'asc'
                        ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                }
                case 2: {
                    return activeSortDirection === 'asc' ?
                        a.organization.localeCompare(b.organization) : b.organization.localeCompare(a.organization);
                }
                case 3: {
                    return activeSortDirection === 'asc' ?
                        a.email.localeCompare(b.email) : b.email.localeCompare(a.email);
                }
                default: {
                    return activeSortDirection === 'asc' ?
                        a.id - b.id : b.id - a.id;
                }
            }
        }).map((i) => {
            return (
                <Tr key={i.id}>
                    <Td>{i.id}</Td>
                    <Td>{i.name}</Td>
                    <Td>{i.organization}</Td>
                    <Td>{i.email}</Td>
                    <Td textCenter>{boolBadge(i.is_superuser)}</Td>
                    <Td textCenter>{boolBadge(i.is_supporting_partner)}</Td>
                    <Td textCenter>{boolBadge(i.may_issue_licenses)}</Td>
                    <Td textCenter>{boolBadge(i.direct_reminder)}</Td>
                </Tr>)
        })
    }

    return (
        <TableComposable
            variant='compact'
        // className={styles.smalltable}
        >
            <Thead>
                <Tr>
                    <Th sort={getSortParams(0)}>id</Th>
                    <Th sort={getSortParams(1)}>Name</Th>
                    <Th sort={getSortParams(2)}>LDAP Name</Th>
                    <Th sort={getSortParams(3)}>email</Th>
                    <Th>Superuser</Th>
                    <Th>Supporter</Th>
                    <Th>License issuer</Th>
                    <Th>Direct reminder</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderIssuerRows(props.tickets)}
            </Tbody>
        </TableComposable>
    )
}

const IssuersPage: React.FunctionComponent = () => {
    const navigate = useNavigate();

    const { isLoading, isError, data, error } = queryIssuers();

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>);
    }

    const issuersCount = data ? data.count : 0;
    const issuers = data ? data.list : [];
    return (
        <React.Fragment>
            <PageSection hasOverflowScroll name="scrolling-section">
                <Title
                    id="title-ticket-head"
                    headingLevel="h1"
                    size="xl">{issuersCount} Contract issuers</Title>
                <br />
                {isLoading ? <Spinner isSVG /> : <IssuersTable tickets={issuers} />}
            </PageSection>
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>)
}

export {
    IssuersPage
}
