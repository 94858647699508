import {
    ActionGroup, Alert, Button, Checkbox, DatePicker, Form, FormGroup, Grid, GridItem, isValidDate, NumberInput,
    PageSection, Popover, Select, SelectOption, SelectOptionObject, SelectVariant, Spinner, Tab, TabContent, TabContentBody,
    Tabs, TabTitleText, TextArea, Title, Tooltip
} from '@patternfly/react-core';
import HelpIcon from '@patternfly/react-icons/dist/esm/icons/help-icon';
import * as React from 'react';
import { FormEvent } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
    ContractContactResponse,
    ContractKind, ContractOption, ContractUnitResponse, CreateManagerContractRequest, ManagerContract,
    ModifyManagerContractRequest
} from 'linbit-api-fetcher';
import { ContractContactsTable } from './contract-contacts';
import { ContractUnitsTable } from './contract-units';
import { AccountManagerSelect, ContractIdSelect, CredentialSelect } from './form-components';
import { apiGet, apiPost, apiPut, fetchContractOptions, parseJwt, queryContractKinds } from './fetcher';
import * as styles from './lab.module.css';
import { ContractOptionSelect } from './contract-components';
import { formatDate } from './formatter';

interface ContractKindSelectProps {
    id: string;
    initial: number;
    onContractKindSelected: (kind: ContractKind) => void;
}

const ContractKindSelect: React.FunctionComponent<ContractKindSelectProps> = (props: ContractKindSelectProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isInitial, setIsInitial] = React.useState(true);

    const onToggle = (isExpanded: boolean) => {
        setIsOpen(isExpanded);
    }

    const onSelect = (
        event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
        value: string | SelectOptionObject,
        isPlaceholder: boolean | undefined) => {
        setIsOpen(false);
        // find contract kind, it is not possible to not find it
        let item = Array.from(contractKinds).find(([key, ck]) => ck.name === value);
        item && props.onContractKindSelected(item[1]);
    }

    const { isLoading, isError, data, error } = queryContractKinds(true);

    if (isError) {
        return <Alert variant='danger' title={error} />
    }

    if (isLoading || !data) {
        return <Spinner isSVG />
    }

    const contractKinds: Map<number, ContractKind> = data || new Map<number, ContractKind>();

    let selected = Array.from(contractKinds).filter(([key, ck]) => key == +props.initial).map(([key, ck]) => ck.name);
    if (selected.length === 0 && isInitial) {
        setIsInitial(false);
        if (contractKinds.size > 0) {
            const item = contractKinds.entries().next().value[1]; // [key, ContractKind]
            selected = [item.name];
            props.onContractKindSelected(item);
        }
    }
    return (
        <Select
            variant={SelectVariant.single}
            className={styles.smallselect}
            aria-label='Contract kinds'
            onToggle={onToggle}
            onSelect={onSelect}
            selections={selected}
            isOpen={isOpen}
            id={props.id}>
            {
                Array.from(contractKinds)
                    .filter(([ckId, ck]) => ck.ui_show || ckId === props.initial)
                    .map(([key, ck]) => {
                        return (
                            <SelectOption key={key} id={key + ""} value={ck.name} />
                        )
                    })
            }
        </Select>
    )
}

enum Mode {
    Create = "Create",
    Edit = "Edit",
    Renew = "Renew",
}

interface ContractProps {
}

const ContractEditPage: React.FunctionComponent<ContractProps> = (props: ContractProps) => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [linkSupportDates, setLinkSupportDates] = React.useState(true);
    const [supportFromDate, setSupportFromDate] = React.useState(formatDate(new Date()));
    const [supportUntilDate, setSupportUntilDate] = React.useState('');
    const [downloadUntilDate, setDownloadUntilDate] = React.useState('');
    const [paymentDate, setPaymentDate] = React.useState('');
    const [nodes, setNodes] = React.useState(2);
    const [comment, setComment] = React.useState('');
    const [sendReminders, setSendReminders] = React.useState(true);
    const [maxTickets, setMaxTickets] = React.useState<number>(0);
    const [maxTime, setMaxTime] = React.useState(0);
    const [followsContract, setFollowsContract] = React.useState(0);
    const [credential, setCredential] = React.useState(0);
    const [accountManager, setAccountManager] = React.useState('');
    const [contractKind, setContractKind] = React.useState<ContractKind>({ id: 16, name: "enterprise", ui_show: true });
    const [contractOpts, setContractOpts] = React.useState<number[]>([]);
    const [initial, setInitial] = React.useState(true);
    const [activeTabKey, setActiveTabKey] = React.useState(0);

    const queryClient = useQueryClient();

    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const mode = params.contractId && +params.contractId > 0 ?
        searchParams.get("renew") == "true" ? Mode.Renew : Mode.Edit : Mode.Create;
    const customerId = params.customerId ? parseInt(params.customerId) : 0;

    React.useEffect(() => {
        if (mode === Mode.Edit) {
            apiGet<ManagerContract>('/manager/customers/' + params.customerId + '/contracts/' + params.contractId)
                .then((resp) => {
                    setSupportFromDate(resp.support_from);
                    setSupportUntilDate(resp.support_until);
                    setDownloadUntilDate(resp.download_until);
                    setPaymentDate(resp.payment_date ? resp.payment_date : '');
                    setNodes(resp.supported_nodes);
                    setComment(resp.comment);
                    setContractKind({ id: resp.kind_id, name: "fake", ui_show: true })
                    setSendReminders(resp.send_reminders);
                    setContractOpts(resp.options);
                    setMaxTickets(resp.max_tickets ? resp.max_tickets : 0);
                    setMaxTime(resp.max_time ? resp.max_time : 0);
                    setAccountManager(resp.account_manager_ldap ? resp.account_manager_ldap : "");
                    setCredential(resp.credential_id);
                    setFollowsContract(resp.followup_contract_id ? resp.followup_contract_id : 0);
                }).catch((reason) => {
                    setErrorMessage(reason.toString());
                });
        } else if (mode == Mode.Renew) {
            apiGet<ManagerContract>('/manager/customers/' + params.customerId + '/contracts/' + params.contractId)
                .then((resp) => {
                    setSupportFromDate(resp.support_from);
                    setNodes(resp.supported_nodes);
                    setComment(resp.comment);
                    setContractKind({ id: resp.kind_id, name: "fake", ui_show: true })
                    setSendReminders(resp.send_reminders);
                    setContractOpts(resp.options);
                    setMaxTickets(resp.max_tickets ? resp.max_tickets : 0);
                    setMaxTime(resp.max_time ? resp.max_time : 0);
                    setAccountManager(resp.account_manager_ldap ? resp.account_manager_ldap : "");
                    setCredential(resp.credential_id);
                    setFollowsContract(params.contractId ? +params.contractId : 0);
                }).catch((reason) => {
                    setErrorMessage(reason.toString());
                });
        } else if (mode == Mode.Create) {
            let value = localStorage.getItem('access-token');
            if (value) {
                let jwt = parseJwt(value);
                setAccountManager(jwt['sub']);
            }
        }
    }, []);

    const handleTabClick = (_event: React.MouseEvent<HTMLElement, MouseEvent>, tabIndex: number | string) => {
        setActiveTabKey(+tabIndex);
    }

    const onChangeSupportFromDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (date && isValidDate(date)) {
            setSupportFromDate(str);
        }
    }

    const onChangeSupportUntilDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (date && isValidDate(date)) {
            if (linkSupportDates) {
                setDownloadUntilDate(str);
            }
            setSupportUntilDate(str);
        }
    }

    const onChangeDownloadUntilDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (date && isValidDate(date)) {
            setDownloadUntilDate(str);
        }
    }

    const changePaymentDate = (str: string, date?: Date | undefined) => {
        if (str.trim() == "") {
            setPaymentDate("");
        } else if (date && isValidDate(date)) {
            setPaymentDate(str);
        }
    }

    const onChangePaymentDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        changePaymentDate(str, date);
    }

    const setAdvancedYear = (years: number) => {
        let future = new Date(new Date().setFullYear(new Date().getFullYear() + years));
        setDownloadUntilDate(formatDate(future));
        setSupportUntilDate(formatDate(future));
    }

    const changeDatesByYear = (years: number) => {
        let curDateStr = supportUntilDate;
        let curDate = curDateStr ? new Date(Date.parse(curDateStr)) : new Date();
        curDate.setFullYear(curDate.getFullYear() + years);
        setDownloadUntilDate(formatDate(curDate));
        setSupportUntilDate(formatDate(curDate));
    }

    const onChangeNodes = (event: FormEvent<HTMLInputElement>) => {
        setNodes(+event.currentTarget.value);
    }

    const onPlusNodes = () => {
        setNodes(nodes + 1);
    }

    const onMinusNodes = () => {
        setNodes(nodes - 1);
    }

    const onChangeComment = (str: string) => {
        setComment(str);
    }

    const onContractKindSelected = (kind: ContractKind) => {
        setContractKind(kind);
        setMaxTickets(kind.max_tickets_default ? kind.max_tickets_default : 0);
    }

    const onContractOptionsSelected = (contractOptions_: ContractOption[]) => {
        setContractOpts(contractOptions_.map(co => co.id));
    }

    const onCredentialSelected = (credential_id: number) => {
        setCredential(credential_id);
    }

    const onFollowContractSelected = (contract_id: number) => {
        setFollowsContract(contract_id);
    }

    const onChangeMaxTickets = (event: FormEvent<HTMLInputElement>) => {
        setMaxTickets(+event.currentTarget.value);
    }

    const onAccountManagerChange = (val: string) => {
        setAccountManager(val);
    }

    const onLinkedDatesChange = (checked: boolean) => {
        setLinkSupportDates(checked);
        if (checked) {
            setDownloadUntilDate(supportUntilDate);
        }
    }

    const renewContract = () => {
        let createreq: CreateManagerContractRequest = {
            support_from: supportFromDate,
            support_until: supportUntilDate,
            download_until: downloadUntilDate,
            payment_date: paymentDate ? paymentDate : null,
            nodes: nodes,
            comment: comment,
            kind_id: contractKind.id,
            max_tickets: maxTickets,
            max_time: maxTime,
            send_reminders: sendReminders,
            contract_options: contractOpts,
        };
        if (credential !== 0) {
            createreq.credential_id = credential;
        }
        if (followsContract !== 0) {
            createreq.follows_contract = followsContract;
        }
        if (accountManager !== "") {
            createreq.account_manager = accountManager;
        }
        console.log(createreq);
        apiPost<ManagerContract>('/manager/customers/' + customerId + '/contracts', createreq)
            .then((resp) => {
                apiPost<ContractContactResponse>('/manager/contracts/' + resp.id + '/contacts-copy/' + params.contractId, {})
                    .then((_) => apiPost<ContractUnitResponse>('/manager/customers/' + customerId + '/contracts/' + resp.id + '/units-copy/' + params.contractId, {})
                        .then((_) => apiPost<string>('/manager/contracts/' + resp.id + '/nodes-move/' + params.contractId, {})
                            .then((_) => {
                                navigate("/customers/" + customerId + '#ct-' + resp.id);
                            }).catch((reason) => {
                                console.error(reason);
                                navigate("/customers/" + customerId + '#ct-' + resp.id);
                                alert("Error moving nodes: " + reason);
                            })
                        )
                        .catch((reason) => {
                            console.error(reason);
                            navigate("/customers/" + customerId + '#ct-' + resp.id);
                            alert("Error copying units: " + reason);
                        })
                    )
                    .catch((reason) => {
                        console.error(reason);
                        navigate("/customers/" + customerId + '#ct-' + resp.id);
                        alert("Error copying contacts: " + reason);
                    })
            })
            .catch((reason) => {
                setErrorMessage(reason.toString());
            })
            .finally(() => {
                queryClient.invalidateQueries(['contracts', customerId]);
            });
    }

    const navigate = useNavigate();

    const onSubmit = () => {
        setErrorMessage('');
        switch (mode) {
            case Mode.Edit:
                {
                    let modifyreq: ModifyManagerContractRequest = {
                        support_from: supportFromDate,
                        support_until: supportUntilDate,
                        download_until: downloadUntilDate,
                        payment_date: paymentDate ? paymentDate : null,
                        comment: comment,
                        kind_id: contractKind.id,
                        max_tickets: maxTickets,
                        max_time: maxTime,
                        send_reminders: sendReminders,
                        contract_options: contractOpts,
                        account_manager: accountManager === "" ? null : accountManager,
                        follows_contract: followsContract,
                    };
                    if (credential !== 0) {
                        modifyreq.credential_id = credential;
                    }
                    console.log(modifyreq);
                    apiPut<ManagerContract>('/manager/customers/' + customerId + '/contracts/' + params.contractId, modifyreq)
                        .then((resp) => {
                            queryClient.invalidateQueries(['contracts', customerId]);
                            navigate("/customers/" + customerId + '#ct-' + resp.id);
                        }).catch((reason) => {
                            setErrorMessage(reason.toString());
                        });
                }
                break;
            case Mode.Renew:
                renewContract();
                break;
            case Mode.Create:
                {
                    let createreq: CreateManagerContractRequest = {
                        support_from: supportFromDate,
                        support_until: supportUntilDate,
                        download_until: downloadUntilDate,
                        payment_date: paymentDate ? paymentDate : null,
                        nodes: nodes,
                        comment: comment,
                        kind_id: contractKind.id,
                        max_tickets: maxTickets,
                        max_time: maxTime,
                        send_reminders: sendReminders,
                        contract_options: contractOpts,
                    };
                    if (credential !== 0) {
                        createreq.credential_id = credential;
                    }
                    if (followsContract !== 0) {
                        createreq.follows_contract = followsContract;
                    }
                    if (accountManager !== "") {
                        createreq.account_manager = accountManager;
                    }
                    console.log(createreq);
                    apiPost<ManagerContract>('/manager/customers/' + customerId + '/contracts', createreq)
                        .then((resp) => {
                            queryClient.invalidateQueries(['contracts', customerId]);
                            navigate("/customers/" + customerId + '#ct-' + resp.id);
                        }).catch((reason) => {
                            setErrorMessage(reason.toString());
                        });
                }
                break;

        }
    }

    const checkRequiredOk = () => {
        let supFrom = new Date(supportFromDate);
        let supUntil = new Date(supportUntilDate);
        let downloadUntil = new Date(downloadUntilDate)
        return isValidDate(supFrom) && isValidDate(supUntil) && isValidDate(downloadUntil);
    }

    const { isLoading, isError, data, error } =
        useQuery<Map<number, ContractOption>, string>(['contract-options', true], () => fetchContractOptions(true));

    if (isError) {
        return <Alert variant='danger' title={error} />
    }

    if (isLoading || !data) {
        return <Spinner isSVG />
    }

    const contractOptions: Map<number, ContractOption> = data || new Map<number, ContractOption>();

    if (initial && mode === Mode.Create) {
        setContractOpts(Array.from(contractOptions)
            .filter(([key, co]) => co.initial_value)
            .map(([key, co]) => co.id));
        setInitial(false);
    }

    const basicInfo = (
        <Form isHorizontal>
            <Grid hasGutter md={6}>
                <GridItem span={6}>
                    <FormGroup label="Support from" isRequired fieldId='support-from-date' helperText="Support starting from this date.">
                        <DatePicker
                            id='support-from-date'
                            onChange={onChangeSupportFromDate}
                            value={supportFromDate} />
                    </FormGroup>
                </GridItem>
                <GridItem span={6} style={{ display: 'flex' }}>
                    <div>
                        <FormGroup label="Support until" isRequired fieldId='support-until-date' helperText="Support until this date.">
                            <DatePicker
                                id='support-until-date'
                                onChange={onChangeSupportUntilDate}
                                value={supportUntilDate} />
                        </FormGroup>
                        <FormGroup label="Download until" isRequired fieldId='download-until-date' helperText="Download until this date.">
                            <DatePicker
                                id='download-until-date'
                                isDisabled={linkSupportDates}
                                onChange={onChangeDownloadUntilDate}
                                value={downloadUntilDate} />
                        </FormGroup>
                        <Button onClick={() => { setAdvancedYear(1) }} variant='secondary'>1y</Button>
                        <Button onClick={() => { setAdvancedYear(2) }} variant='secondary'>2y</Button>
                        <Button onClick={() => { setAdvancedYear(3) }} variant='secondary'>3y</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button onClick={() => { changeDatesByYear(1) }} variant='secondary'>+1y</Button>
                        <Button onClick={() => { changeDatesByYear(-1) }} variant='secondary'>-1y</Button>
                    </div>
                    <div style={{ padding: "15px 5px 0" }}>
                        <span {...(linkSupportDates ? { style: { fontWeight: "bold" } } : {})}>&#8989;</span>
                        <Checkbox
                            label="same date"
                            id="link-dates"
                            isChecked={linkSupportDates}
                            onChange={onLinkedDatesChange}
                        />
                        <span {...(linkSupportDates ? { style: { fontWeight: "bold" } } : {})}>&#8991;</span>
                    </div>
                </GridItem>
                <GridItem>
                    <FormGroup label="Payment date" fieldId='payment-date'>
                        <Button onClick={() => { changePaymentDate(formatDate(new Date()), new Date()) }} variant='secondary'>Today</Button>
                        <DatePicker
                            id='payment-date'
                            onChange={onChangePaymentDate}
                            value={paymentDate} />
                    </FormGroup>
                </GridItem>
                <FormGroup label="Nodes" fieldId='nodes'>
                    <Tooltip content="To edit use the 'Sales units' tab" hidden={params.contractId === undefined}>
                        <NumberInput
                            id='nodes'
                            value={nodes}
                            inputName='nodes'
                            inputAriaLabel='Nodes'
                            onChange={onChangeNodes}
                            onMinus={onMinusNodes}
                            onPlus={onPlusNodes}
                            min={1}
                            isDisabled={params.contractId !== undefined}
                        />
                    </Tooltip>
                </FormGroup>
                <GridItem md={12}>
                    <FormGroup label="Comment" fieldId='comment'>
                        <TextArea value={comment} onChange={onChangeComment} aria-label='comment' />
                    </FormGroup>
                </GridItem>
                <FormGroup label="Support level" fieldId='support-level' isRequired>
                    <ContractKindSelect id='support-level' initial={contractKind.id} onContractKindSelected={onContractKindSelected} />
                </FormGroup>
                <FormGroup
                    label="Send Reminders"
                    fieldId='send-reminders'
                    labelIcon={<Popover bodyContent="Send automatic email reminder">
                        <button
                            type="button"
                            aria-label="More info for send reminders field"
                            onClick={e => e.preventDefault()}
                            className="pf-c-form__group-label-help"
                        ><HelpIcon noVerticalAlign /></button>
                    </Popover>}
                >
                    <Checkbox
                        id='send-reminders'
                        style={{ marginTop: "12px" }}
                        isChecked={sendReminders}
                        onChange={(checked, _event) => { setSendReminders(checked) }} />
                </FormGroup>
                <GridItem md={10}>
                    <FormGroup label="Contract Options" fieldId='contract-options'>
                        <ContractOptionSelect id='contract-options' selected={contractOpts} onContractOptionSelected={onContractOptionsSelected} />
                    </FormGroup>
                </GridItem>
                <FormGroup label="Max tickets" fieldId='max-tickets' helperText='0 is unlimited'>
                    <NumberInput
                        id='max-tickets'
                        value={maxTickets}
                        inputName='maxTickets'
                        inputAriaLabel='Max tickets'
                        onChange={onChangeMaxTickets}
                        onMinus={() => setMaxTickets(maxTickets - 1)}
                        onPlus={() => setMaxTickets(maxTickets + 1)}
                        min={0}
                    />
                </FormGroup>
                <FormGroup
                    label="Max time"
                    fieldId='max-time'
                    helperText='0 is unlimited'
                    labelIcon={<Popover bodyContent="Maximum support minutes allowed for this contract">
                        <button
                            type="button"
                            aria-label="More info for max time field"
                            onClick={e => e.preventDefault()}
                            className="pf-c-form__group-label-help"
                        ><HelpIcon noVerticalAlign /></button>
                    </Popover>}
                >
                    <NumberInput
                        id='max-time'
                        value={maxTime}
                        inputName='maxTickets'
                        inputAriaLabel='Max tickets'
                        onChange={(event) => setMaxTime(+event.currentTarget.value)}
                        onMinus={() => setMaxTime(maxTime - 1)}
                        onPlus={() => setMaxTime(maxTime + 1)}
                        min={0}
                    />
                </FormGroup>
                <FormGroup label="Account Manager" fieldId='account-mgr'>
                    <AccountManagerSelect
                        noneLabel="<None>"
                        accountManager={accountManager}
                        onChange={onAccountManagerChange} />
                </FormGroup>
                <FormGroup label="Credential" fieldId='credential'>
                    <CredentialSelect
                        id='credential'
                        customerId={customerId}
                        credentialId={credential}
                        allowNone={true}
                        onCredentialSelected={onCredentialSelected} />
                </FormGroup>
                <FormGroup label="Follow contract" fieldId='follow-contract'>
                    <ContractIdSelect
                        id='follow-contract'
                        customerId={customerId}
                        contractId={followsContract}
                        showExpired={true}
                        allowNone={true}
                        onContractIdSelected={onFollowContractSelected} />
                </FormGroup>
                <GridItem md={12}>
                    <ActionGroup>
                        <Button variant='primary' onClick={onSubmit} isDisabled={!checkRequiredOk()}>{mode == Mode.Edit ? "Save" : mode}</Button>
                        <Button variant='secondary' onClick={() => navigate(-1)}>Cancel</Button>
                    </ActionGroup>
                </GridItem>
            </Grid>
        </Form>
    );

    return (
        <React.Fragment>
            <PageSection>
                <Title headingLevel='h1'>{mode} Contract</Title>
            </PageSection>
            {errorMessage && (
                <PageSection>
                    <Alert
                        variant='danger'
                        title={errorMessage}
                        isInline
                    />
                </PageSection>
            )}
            <PageSection type="tabs" padding={{ default: 'noPadding' }}>
                <Tabs activeKey={activeTabKey} onSelect={handleTabClick} usePageInsets={true} isBox>
                    <Tab eventKey={0} title={<TabTitleText>Basic</TabTitleText>} />
                    <Tab eventKey={1} title={<TabTitleText>Sales units</TabTitleText>} isDisabled={mode !== Mode.Edit} />
                    <Tab eventKey={2} title={<TabTitleText>Contacts</TabTitleText>} isDisabled={mode !== Mode.Edit} />
                </Tabs>
            </PageSection>
            <PageSection>
                <TabContent key={0} eventKey={0} id={`tabContent${0}`} activeKey={activeTabKey} hidden={0 !== activeTabKey}>
                    <TabContentBody>{basicInfo}</TabContentBody>
                </TabContent>
                <TabContent key={1} eventKey={1} id={`tabContent${1}`} activeKey={activeTabKey} hidden={1 !== activeTabKey}>
                    <TabContentBody>
                        <ContractUnitsTable customerId={customerId} contractId={params.contractId ? +params.contractId : 0} />
                    </TabContentBody>
                </TabContent>
                <TabContent key={2} eventKey={2} id={`tabContent${2}`} activeKey={activeTabKey} hidden={2 !== activeTabKey}>
                    <TabContentBody>
                        <ContractContactsTable contractId={params.contractId ? +params.contractId : 0} />
                    </TabContentBody>
                </TabContent>
            </PageSection>
        </React.Fragment>
    )
}

export { ContractEditPage };
