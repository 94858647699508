function isValidRegex(regExp: string) {
    try {
        new RegExp(regExp, "i");
        return true
    } catch (e) {
        console.error(e);
        return false;
    }
}

export {
    isValidRegex
}
