import * as React from 'react';
import {
    Alert,
    AlertActionCloseButton,
    Button,
    Checkbox,
    Divider,
    Spinner,
    TextInput,
    ValidatedOptions,
} from '@patternfly/react-core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';
import PencilAltIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon';

import { apiPost, apiDelete, fetchContractContacts } from './fetcher';
import { AddContactRequest, Contact, ContractContact, ContractContactResponse, CreateContactRequest } from 'linbit-api-fetcher';
import * as styles from './lab.module.css';
import { useNavigate } from 'react-router';


interface ContractContactsTableProps {
    contractId: number;
}

const ContractContactsTable: React.FunctionComponent<ContractContactsTableProps> = (props: ContractContactsTableProps) => {
    const [responseError, setResponseError] = React.useState('');
    const [editEmail, setEditEmail] = React.useState('');
    const [emailValidated, setEmailValidated] = React.useState<ValidatedOptions>(ValidatedOptions.default);
    const [editTechnical, setEditTechnical] = React.useState(false);
    const [editSales, setEditSales] = React.useState(false);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const QUERY_KEY = ['contract-contacts', props.contractId];

    function deleteContractContact(contact_id: number) {
        apiDelete<String>("/manager/contracts/" + props.contractId + "/contacts/" + contact_id)
            .then((_resp) => {
                queryClient.invalidateQueries(QUERY_KEY);
            })
            .catch((reason) => setResponseError(reason.toString()));
    }

    const clearFields = () => {
        setEditEmail('');
        setEditTechnical(false);
        setEditSales(false);
        setEmailValidated(ValidatedOptions.default);
    }

    const handleAddClick = () => {
        let createContactReq: CreateContactRequest = {
            email: editEmail,
        };
        let addContactReq: AddContactRequest = {
            technical: editTechnical,
            sales: editSales,
        };
        let contact = contractContacts.find((cc) => cc.email === editEmail);
        if (contact) {
            if (contact.sales !== editSales || contact.technical !== editTechnical) {
                apiPost("/manager/contracts/" + props.contractId + "/contacts/" + contact.id, addContactReq)
                    .then((_) => {
                        clearFields();
                        queryClient.invalidateQueries(QUERY_KEY);
                    })
                    .catch((reason) => setResponseError(reason.toString()));
            }
        } else {
            apiPost<Contact>("/manager/contacts", createContactReq)
                .then((resp) => {
                    apiPost("/manager/contracts/" + props.contractId + "/contacts/" + resp.id, addContactReq)
                        .then((_) => {
                            clearFields();
                            queryClient.invalidateQueries(QUERY_KEY);
                        })
                        .catch((reason) => setResponseError(reason.toString()));
                })
                .catch((reason) => setResponseError(reason.toString()));
        }
    }

    const handleEmailChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
        setEditEmail(value);
        setEmailValidated(isEmailValid(value));
    };

    const isEmailValid = (input: string) => {
        if (input) {
            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!input || regex.test(input) === false) {
                return ValidatedOptions.error;
            }
            return ValidatedOptions.success;
        }
        return ValidatedOptions.default;
    }

    const triggerEdit = (contractContact: ContractContact) => {
        setEditEmail(contractContact.email);
        setEditTechnical(contractContact.technical);
        setEditSales(contractContact.sales);
        setEmailValidated(isEmailValid(contractContact.email));
    }

    function renderContractContactRow(contractContacts: ContractContact[]) {
        return contractContacts
            .sort((a, b) => {
                return a.email.localeCompare(b.email);
            }).map((cc) => {
                return (
                    <Tr key={cc.id}>
                        <Td>{cc.email}</Td>
                        <Td textCenter>
                            <Checkbox id={"technical-" + cc.id} style={{ cursor: "default" }} checked={cc.technical} />
                        </Td>
                        <Td textCenter>
                            <Checkbox id={"sales-" + cc.id} style={{ cursor: "default" }} checked={cc.sales} />
                        </Td>
                        <Td textCenter>
                            <Button variant='plain' className={styles.smallpadding} isSmall onClick={() => triggerEdit(cc)}><PencilAltIcon /></Button>
                            <Button variant='plain' className={styles.smallpadding} isSmall onClick={() => deleteContractContact(cc.id)}><TrashIcon /></Button>
                        </Td>
                    </Tr>
                )
            })
    }

    const { isLoading, isError, data, error } =
        useQuery<ContractContactResponse, string>(QUERY_KEY, () => fetchContractContacts(props.contractId));

    if (isError) {
        return <Alert variant='danger' title={error} />
    }

    if (isLoading || !data) {
        return <Spinner isSVG />
    }

    const contractContacts = data?.list ?? [];

    return (
        <React.Fragment>
            {responseError && (<Alert
                variant='danger'
                title={responseError}
                actionClose={<AlertActionCloseButton onClose={() => setResponseError('')} />}
            />)}
            <TableComposable
                variant='compact'
                className={styles.tinytable}
            >
                <Thead>
                    <Tr>
                        <Th>Email</Th>
                        <Th textCenter>Technical</Th>
                        <Th textCenter>Sales</Th>
                        <Th textCenter>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {renderContractContactRow(contractContacts)}
                    <Tr>
                        <td colSpan={4}><Divider /></td>
                    </Tr>
                    <Tr>
                        <Td>
                            <TextInput
                                type='email'
                                id='contract-email'
                                name='contract-email'
                                value={editEmail}
                                onChange={handleEmailChange}
                                validated={emailValidated}
                            />
                        </Td>
                        <Td textCenter>
                            <Checkbox
                                id="check-technical"
                                name="check-technical"
                                isChecked={editTechnical}
                                onChange={(checked, _event) => { setEditTechnical(checked) }}
                            />
                        </Td>
                        <Td textCenter>
                            <Checkbox
                                id="check-sales"
                                name="check-sales"
                                isChecked={editSales}
                                onChange={(checked, _event) => { setEditSales(checked) }}
                            />
                        </Td>
                        <Td textCenter>
                            <Button
                                onClick={handleAddClick}
                                isDisabled={emailValidated !== ValidatedOptions.success}>
                                {contractContacts.find((cc) => cc.email === editEmail) ? "Edit" : "Add"}</Button>
                        </Td>
                    </Tr>
                </Tbody>
            </TableComposable>
            <br />
            <Button variant='secondary' onClick={() => navigate(-1)}>Back</Button>
        </React.Fragment>
    )
}

export { ContractContactsTable }
