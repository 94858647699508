import * as React from 'react';
import {
    PageSection,
    Title,
    Spinner,
    Alert,
    List,
    ListItem,
    Button,
    InputGroup,
    TextInput,
    PageSectionVariants,
    Grid,
    GridItem,
    TextInputGroup,
    Tabs,
    TabTitleText,
    Tab,
    TabContent,
    TabContentBody,
    AlertActionCloseButton,
    Badge
} from '@patternfly/react-core';
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon';
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon';
import PlusIcon from '@patternfly/react-icons/dist/esm/icons/plus-icon';
import PencilAltIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Customer, CustomerLink, CustomerLinksResponse } from 'linbit-api-fetcher';
import { apiGet, apiPost, apiPut, apiDelete, getCurrentClaims, queryCustomerContracts, queryClusters } from './fetcher';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ContractTable, View } from './contracts';
import { ClusterView } from './clusters';
import { isContractExpired } from './contract-components';
import { LicenseView } from './customer/licenses';

interface CustomerData {
    id: number,
    name: string,
}

interface CustomerLinksProps {
    customerId: number,
}

const CustomerLinks: React.FunctionComponent<CustomerLinksProps> = (props: CustomerLinksProps) => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [newLinkValue, setNewLinkValue] = React.useState('');
    const QUERY_KEY = ['customer-links', props.customerId];

    const queryClient = useQueryClient();

    const handleNewLinkInputChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
        setNewLinkValue(value);
    };

    const handleDeleteLinkClick = (linkId: number) => {
        apiDelete<string>('/manager/customers/' + props.customerId + '/links/' + linkId)
            .then(() => {
                queryClient.invalidateQueries(QUERY_KEY);
            })
            .catch((reason) => {
                console.error(reason);
                setErrorMessage(reason);
            });
    }

    const handleAddLinkClick = () => {
        apiPost<CustomerLink>('/manager/customers/' + props.customerId + '/links', { link: newLinkValue })
            .then(() => {
                queryClient.invalidateQueries(QUERY_KEY);
                setNewLinkValue('');
            })
            .catch((reason) => {
                console.error(reason);
                setErrorMessage(reason);
            });
    }

    function renderLinks(links: CustomerLink[] | undefined) {
        if (links !== undefined) {
            return links.map((l, index) => {
                return (
                    <ListItem key={l.id} style={{ fontSize: "0.9rem", marginTop: 0 }} >
                        <a href={l.link} target="_blank" rel="noopener noreferrer">{l.link}</a>
                        <Button variant='plain' aria-label='delete'
                            style={{ fontSize: "0.9rem", paddingTop: "2px", paddingBottom: "2px" }}
                            onClick={() => handleDeleteLinkClick(l.id)}><TimesIcon /></Button>
                    </ListItem>
                )
            })
        }
        return (<React.Fragment />)
    }

    async function fetchCustomerLinks(): Promise<CustomerLink[]> {
        let uri = '/manager/customers/' + props.customerId + '/links';
        return apiGet<CustomerLinksResponse>(uri).then((clr) => {
            let links: CustomerLink[] = clr.list
                .map((l) => {
                    return {
                        id: l.id,
                        link: l.link,
                    }
                });
            return links;
        })
    }

    const { isLoading, isError, data, error } = useQuery<CustomerLink[], string>(QUERY_KEY, fetchCustomerLinks)

    if (isError) {
        return (<Alert variant="danger" title={error} />)
    } else {
        return (
            <React.Fragment>
                {isLoading ? <Spinner isSVG /> :
                    <Grid>
                        <GridItem span={12}>
                            {errorMessage && <Alert variant='danger' title={errorMessage} actionClose={<AlertActionCloseButton onClose={() => setErrorMessage('')} />} />}
                        </GridItem>
                        <GridItem span={12}>
                            <List>
                                {renderLinks(data)}
                            </List>
                        </GridItem>
                        <GridItem span={6}>
                            <InputGroup>
                                <TextInput name="addLink" id="addLink" type="url" aria-label="add link"
                                    value={newLinkValue} placeholder='Add link' onChange={handleNewLinkInputChange} />
                                <Button variant="control" aria-label="add link button" onClick={handleAddLinkClick}
                                    isDisabled={!newLinkValue.startsWith("http")}>
                                    <PlusIcon />
                                </Button>
                            </InputGroup>
                        </GridItem>
                    </Grid>
                }
            </React.Fragment>
        )
    }
}


interface CustomerSectionProps {
    customerId: number,
    showDelete: boolean,
}

const CustomerSection: React.FunctionComponent<CustomerSectionProps> = (props: CustomerSectionProps) => {
    const [isEditMode, setIsEditMode] = React.useState(false);
    const [customerName, setCustomerName] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    const QUERY_KEY = ['customers', props.customerId];
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const handleEditNameCheckClick = () => {
        apiPut<Customer>('/manager/customers/' + props.customerId, { name: customerName })
            .then(() => {
                queryClient.invalidateQueries(['customers']);
                setIsEditMode(false);
            })
            .catch((reason) => {
                console.error(reason);
                setErrorMessage(reason);
            });
    }

    const handleEditNameInputChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
        setCustomerName(value);
    };

    const onCancelEdit = () => {
        setIsEditMode(false);
        setErrorMessage('');
    }

    const handleDeleteCustomerClick = () => {
        apiDelete<Customer>('/manager/customers/' + props.customerId)
            .then(() => {
                queryClient.invalidateQueries(['customers']);
                navigate("/customers");
            })
            .catch((reason) => {
                console.error(reason);
                setErrorMessage(reason);
            });
    }

    async function fetchCustomer(): Promise<Customer> {
        let uri = '/manager/customers/' + props.customerId;
        return apiGet<Customer>(uri);
    }

    const { isLoading, isError, data, error } = useQuery<Customer, string>(QUERY_KEY, fetchCustomer)

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>)
    } else {
        const customer = data ?? { name: '' }
        return (
            <PageSection variant={PageSectionVariants.light}>
                {isLoading ? <Spinner isSVG /> :
                    <React.Fragment>
                        {errorMessage && <Alert
                            variant='danger'
                            title={errorMessage}
                            actionClose={<AlertActionCloseButton onClose={() => setErrorMessage('')} />} />}
                        {isEditMode ?
                            <TextInputGroup>
                                <TextInput id="edit-customer-name" aria-label="edit name input"
                                    defaultValue={customer.name} onChange={handleEditNameInputChange} />
                                <Button variant="control" aria-label="edit name" onClick={handleEditNameCheckClick} >
                                    <CheckIcon />
                                </Button>
                                <Button variant="control" aria-label="cancel edit name" onClick={onCancelEdit} >
                                    <TimesIcon />
                                </Button>
                            </TextInputGroup>
                            :
                            <Title headingLevel="h1" size="xl" className='pf-c-inline-edit'>{customer.name}
                                <Button
                                    variant='plain'
                                    id="inline-edit-toggle-example-edit-button"
                                    aria-label="Edit"
                                    aria-labelledby="inline-edit-toggle-example-edit-button inline-edit-toggle-example-label"
                                    onClick={() => setIsEditMode(true)}
                                >
                                    <PencilAltIcon />
                                </Button>
                            </Title>
                        }
                        <CustomerLinks customerId={props.customerId} />
                        {props.showDelete && <br />}
                        {props.showDelete && <Button variant='danger' onClick={handleDeleteCustomerClick}>Delete customer</Button>}
                    </React.Fragment>
                }
            </PageSection>
        )
    }
}


const CustomerPage: React.FunctionComponent<{}> = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const claims = getCurrentClaims();

    const params = useParams();

    const handleTabClick = (_event: React.MouseEvent<HTMLElement, MouseEvent>, tabIndex: number | string) => {
        setSearchParams({ tab: tabIndex + "" });
    }

    const tabKeyRaw = searchParams.get("tab");
    const tabKey = tabKeyRaw !== null ? +tabKeyRaw : 0;
    const customerId = params.customerId ? parseInt(params.customerId) : 0;

    const { isLoading, isError, data, error } = queryCustomerContracts(customerId, true);
    const qryResClusters = queryClusters(customerId);

    if (isError) {
        return <Alert title={error} />;
    }

    const activeCount = data?.filter((c) => !isContractExpired(c)).length;
    const expiredCount = data?.filter((c) => isContractExpired(c)).length;

    return (
        <React.Fragment>
            <CustomerSection customerId={customerId} showDelete={data?.length === 0 && qryResClusters.data?.count === 0} />
            {isLoading ? <Spinner isSVG /> :
                <React.Fragment>
                    <PageSection type="tabs" padding={{ default: 'noPadding' }}>
                        <Tabs activeKey={tabKey} onSelect={handleTabClick} usePageInsets={true} isBox>
                            <Tab eventKey={0} title={<TabTitleText>Contracts <Badge>{activeCount}</Badge></TabTitleText>} />
                            <Tab eventKey={1} title={<TabTitleText>Clusters</TabTitleText>} />
                            <Tab eventKey={3} title={<TabTitleText>Licenses</TabTitleText>} isDisabled={!claims.may_issue_licenses} />
                            <Tab eventKey={2} title={<TabTitleText>Contracts - Expired <Badge>{expiredCount}</Badge></TabTitleText>} />
                        </Tabs>
                    </PageSection>
                    <PageSection padding={{ default: 'noPadding' }}>
                        <TabContent key={0} eventKey={0} id={`tabContent${0}`} activeKey={tabKey} hidden={0 !== tabKey}>
                            <TabContentBody>{0 === tabKey ? <ContractTable customerId={customerId} contracts={data} viewMode={View.ActiveOnly} /> : <div />}</TabContentBody>
                        </TabContent>
                        <TabContent key={1} eventKey={1} id={`tabContent${1}`} activeKey={tabKey} hidden={1 !== tabKey}>
                            <TabContentBody>{1 === tabKey ? <ClusterView customerId={customerId} /> : <div />}</TabContentBody>
                        </TabContent>
                        <TabContent key={3} eventKey={3} id={`tabContent${3}`} activeKey={tabKey} hidden={3 !== tabKey}>
                            <TabContentBody>{3 === tabKey ? <LicenseView customerId={customerId} /> : <div />}</TabContentBody>
                        </TabContent>
                        <TabContent key={2} eventKey={2} id={`tabContent${2}`} activeKey={tabKey} hidden={2 !== tabKey}>
                            <TabContentBody>{2 === tabKey ? <ContractTable customerId={customerId} contracts={data} viewMode={View.ExpiredOnly} /> : <div />}</TabContentBody>
                        </TabContent>
                    </PageSection>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export { CustomerPage };
