import * as React from 'react';
import { Spinner, Tooltip } from "@patternfly/react-core";
import { useQuery } from "@tanstack/react-query";

import BanIcon from '@patternfly/react-icons/dist/esm/icons/ban-icon';

import { Credential } from 'linbit-api-fetcher';
import { fetchCustomerCredentials, queryLdapUserInfo, queryLicTypes } from "./fetcher";


interface LicenseTypeResolverProps {
    licenseTypeId: number
}

const LicenseTypeResolver: React.FunctionComponent<LicenseTypeResolverProps> = (props: LicenseTypeResolverProps) => {
    const { isLoading, isError, data, error } = queryLicTypes();

    if (isError) {
        return <span>{props.licenseTypeId}</span>;
    }

    if (isLoading || !data) {
        return (<Spinner isSVG size='sm' />)
    } else {
        const licenseType = data.licenses[props.licenseTypeId];
        if (licenseType) {
            return (<span>{licenseType.name}</span>)
        } else {
            return (<span>{props.licenseTypeId}</span>)
        }
    }
}

interface LdapNameResolverProps {
    ldap_uid: string | undefined | null
}

const LdapNameResolver: React.FunctionComponent<LdapNameResolverProps> = (props: LdapNameResolverProps) => {

    const { isLoading, isError, data, error } = queryLdapUserInfo();

    if (isError || !props.ldap_uid) {
        return <span>{props.ldap_uid}</span>;
    }

    if (isLoading || !data) {
        return <div>{props.ldap_uid}<Spinner size='sm' /></div>
    }

    const userInfo = data.users[props.ldap_uid];
    if (userInfo) {
        return (<span>{userInfo.name}</span>)
    } else {
        return (<span>{props.ldap_uid}</span>)
    }
}

interface CredentialNameResolverProps {
    credential_id: number,
    customer_id: number,
}

const CredentialNameResolver: React.FunctionComponent<CredentialNameResolverProps> = (props: CredentialNameResolverProps) => {

    const { isLoading, isError, data, error } =
        useQuery<Credential[], string>(
            ['credentials', props.customer_id], () => fetchCustomerCredentials(props.customer_id),
            { staleTime: 1000 * 60 * 30 })

    if (isError || !props.credential_id) {
        return <span>{props.credential_id}</span>;
    }

    if (isLoading || !data) {
        return <React.Fragment>{props.credential_id}<Spinner size='sm' /></React.Fragment>
    }

    const credential = data.find((c) => c.id === props.credential_id);
    if (credential) {
        return (<span>{credential.username} {credential.enabled === false ? <Tooltip content="disabled" ><BanIcon /></Tooltip> : null}</span>)
    } else {
        return (<span>{props.credential_id}</span>)
    }
}

export {
    LicenseTypeResolver,
    LdapNameResolver,
    CredentialNameResolver,
}
