import {
    ActionGroup,
    Alert, AlertActionCloseButton, Button, Card, CardBody, CardFooter, CardTitle, Checkbox, DatePicker, DescriptionList,
    DescriptionListDescription, DescriptionListGroup, DescriptionListTerm, Divider, Drawer, DrawerContent,
    DrawerContentBody, DrawerHead, DrawerPanelBody, DrawerPanelContent, Form, FormAlert, FormGroup,
    FormSelect, FormSelectOption, Gallery, isValidDate, List, ListItem, Modal, ModalVariant, PageSection,
    Pagination, PaginationVariant, Popover,
    Spinner, TextArea, TextInput, TextInputGroup, TextInputGroupMain, TextInputGroupUtilities, Title, Tooltip
} from '@patternfly/react-core';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import HistoryIcon from '@patternfly/react-icons/dist/esm/icons/history-icon';
import LockIcon from '@patternfly/react-icons/dist/esm/icons/lock-icon';
import UnlockIcon from '@patternfly/react-icons/dist/esm/icons/unlock-icon';
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import ListIcon from '@patternfly/react-icons/dist/esm/icons/list-icon';
import DownloadIcon from '@patternfly/react-icons/dist/esm/icons/download-icon';
import MailIcon from '@patternfly/react-icons/dist/esm/icons/mail-bulk-icon';
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon';
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon';
import HelpIcon from '@patternfly/react-icons/dist/esm/icons/help-icon';
import PencilAltIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon';
import * as React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { saveAs } from 'file-saver';

import {
    Cluster,
    Node,
    Distribution,
    LicenseTypeResponse,
    LicensesResponse,
    License,
    LicenseOptionDescription,
    LicenseType,
    CreateClusterLicenseRequest,
    CreateNodeRequest,
    ModifyNodeRequest,
    ManagerContract,
    NodeAccessInfo,
    NodeAccessResponse,
} from 'linbit-api-fetcher';

import * as styles from './lab.module.css';
import {
    apiDelete, apiGet, apiGetBlob, apiPost, apiPut, fetchCustomerContracts, fetchDistributions,
    getCurrentClaims, isReadOnlyCustomer, queryClusters, queryLicTypes
} from './fetcher';
import { formatDateTime } from './formatter';
import { ContractIdSelect, SelectList } from './form-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isContractExpired } from './contract-components';
import { isValidRegex } from './util';


interface NodeAccessInfoTableProps {
    customer_id: number,
    cluster_id: number,
    node_id: number,
}

const NodeAccessInfoTable: React.FunctionComponent<NodeAccessInfoTableProps> = (props: NodeAccessInfoTableProps) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);

    const onSetPage = (_event: any, pageNumber: number) => {
        setCurrentPage(pageNumber);
    }

    const onPerPageSelect = (_event: any, itemsPerPage: number, newPage: number) => {
        setItemsPerPage(itemsPerPage);
        setCurrentPage(newPage);
    }

    const renderAccessRows = (access_infos: NodeAccessInfo[]) => {
        return access_infos.map((nai) =>
            <Tr>
                <Td style={{ fontFamily: "RedHatMono", fontSize: "0.8em", whiteSpace: "nowrap" }}>{nai.datetime}</Td>
                <Td>{nai.distribution_id ? <DistributionResolver distri_id={nai.distribution_id} /> : '?'}</Td>
                <Td>{nai.product + '-' + nai.version}</Td>
                <Td>{nai.http_status}</Td>
            </Tr>);
    }

    async function fetchNodeAccessInfo(offset: number, limit: number): Promise<NodeAccessResponse> {
        let uri = `/manager/customers/${props.customer_id}/clusters/${props.customer_id}/nodes/${props.node_id}/` +
            `access?limit=${limit}&offset=${offset}`;
        return apiGet<NodeAccessResponse>(uri)
            .then((node_access_resp) => {
                return node_access_resp;
            });
    }

    const { isLoading, isError, data, error } =
        useQuery<NodeAccessResponse, string>(['node-access', props.node_id, itemsPerPage * (currentPage - 1), itemsPerPage],
            () => fetchNodeAccessInfo(itemsPerPage * (currentPage - 1), itemsPerPage))

    if (isError) {
        return (<Alert variant="danger" title={error} />)
    }

    const access_resp = data ? data : { count: 0, list: [] }
    return (<React.Fragment>
        <Pagination
            itemCount={access_resp.count}
            widgetId="pagination-customers-menu-bottom"
            perPage={itemsPerPage}
            page={currentPage}
            onSetPage={onSetPage}
            onPerPageSelect={onPerPageSelect}
            variant={PaginationVariant.top}
        />
        <TableComposable
            variant='compact'
        >
            <Thead>
                <Tr>
                    <Th>Time</Th>
                    <Th>Distri</Th>
                    <Th>Software</Th>
                    <Th>HTTP</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderAccessRows(access_resp.list)}
            </Tbody>
        </TableComposable>
    </React.Fragment>)
}

interface DistributionResolverProps {
    distri_id: number | null
}

const DistributionResolver: React.FunctionComponent<DistributionResolverProps> = (props: DistributionResolverProps) => {
    if (props.distri_id == null) {
        return (<span></span>)
    }

    const { isLoading, isError, data, error } =
        useQuery<Map<number, Distribution>, string>(['distributions'], fetchDistributions);

    if (isError) {
        return (<span>{error}</span>)
    } else {
        if (isLoading || !data) {
            return (<Spinner isSVG />)
        } else {
            let distriData = data.get(props.distri_id);
            if (distriData) {
                return (<span>{distriData.name}</span>)
            } else {
                return (<span>{props.distri_id}</span>)
            }
        }
    }
}

interface DistributionSelectProps {
    distributionId: number,
    onChange: (distriId: number) => void,
}

const DistributionSelect: React.FunctionComponent<DistributionSelectProps> = (props: DistributionSelectProps) => {
    const onChangeDistribution = (val: string) => {
        props.onChange(+val);
    }

    const { isLoading, isError, data, error } =
        useQuery<Map<number, Distribution>, string>(['distributions'], fetchDistributions);

    if (isError) {
        return (<span>{error}</span>)
    } else {
        if (isLoading || !data) {
            return (<Spinner isSVG />)
        } else {
            return (<FormGroup
                label="Distribution"
                fieldId="form-distribution">
                <FormSelect id="form-distribution" value={props.distributionId} onChange={onChangeDistribution}>
                    <FormSelectOption key={0} label="<none>" value={0} />
                    {Array.from(data)
                        .filter(([key, distri]) => distri.show)
                        .sort((a, b) => a[1].name.localeCompare(b[1].name))
                        .map(([key, distri]) => {
                            return (<FormSelectOption key={key} label={distri.name} value={key} />)
                        })}
                </FormSelect>
            </FormGroup>)
        }
    }
}

interface NodeTableProps {
    customerId: number,
    clusterId: number,
    nodes: Node[],
    contracts: ManagerContract[],
}

const NodeTable: React.FunctionComponent<NodeTableProps> = (props: NodeTableProps) => {
    const [drawerExpanded, setDrawerExpanded] = React.useState(false);
    const [drawerMode, setDrawerMode] = React.useState('Create');
    const [responseError, setResponseError] = React.useState('');

    const [nodeId, setNodeId] = React.useState(0);
    const [hostname, setHostname] = React.useState('');
    const [comment, setComment] = React.useState('');
    const [macAddresses, setMacAddresses] = React.useState<Array<string>>([]);
    const [ipAddresses, setIpAddresses] = React.useState<Array<string>>([]);
    const [ibAddresses, setIbAddresses] = React.useState<Array<string>>([]);
    const [distributionId, setDistributionId] = React.useState(0);
    const [contractId, setContractId] = React.useState(0);
    const [originalNode, setOriginalNode] = React.useState<Node | null>(null);

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const claims = getCurrentClaims();

    const removeNode = (nodeId: number) => {
        if (confirm("Really delete node?")) {
            apiDelete<String>('/manager/customers/' + props.customerId + '/clusters/' + props.clusterId + "/nodes/" + nodeId)
                .then((_resp) => {
                    queryClient.invalidateQueries(['clusters', props.customerId])
                }).catch((reason) => {
                    console.error("Error deleting node: " + reason);
                });
        }
    }

    const reactivateNode = (nodeId: number) => {
        apiPost<Node>('/manager/customers/' + props.customerId + '/clusters/' + props.clusterId + "/nodes/" + nodeId + "/restore", {})
            .then((_resp) => {
                queryClient.invalidateQueries(['clusters', props.customerId])
            }).catch((reason) => {
                console.error("Error reactive node: " + reason);
            });
    }

    const changeNodeAccess = (nodeId: number, lock: boolean) => {
        let preUrl = '/manager/customers/' + props.customerId + '/clusters/' + props.clusterId + "/nodes/" + nodeId + '/';
        let url = lock ? preUrl + "lock" : preUrl + "unlock";
        apiPost<Node>(url, {})
            .then((_resp) => {
                queryClient.invalidateQueries(['clusters', props.customerId])
            }).catch((reason) => {
                console.error("Error (un)lock node: " + reason);
            });
    }

    const editNode = (node: Node) => {
        setDrawerMode('Edit');
        setDrawerExpanded(true);
        setOriginalNode(node);

        setNodeId(node.id);
        setHostname(node.hostname ? node.hostname : '');
        setMacAddresses(node.mac_addresses);
        setIpAddresses(node.ip_addresses);
        setIbAddresses(node.ib_addresses);
        setComment(node.comment ? node.comment : '');
        setDistributionId(node.distribution_id ? node.distribution_id : 0);
        setContractId(node.current_contract ? node.current_contract : 0);
    }

    const handleHostnameInputChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
        setHostname(value);
    };


    const handleDistriChanged = (distriId: number) => {
        setDistributionId(distriId);
    }

    const handleSubmitClick = () => {
        const NODES_URL = '/manager/customers/' + props.customerId + '/clusters/' + props.clusterId + '/nodes';
        if (drawerMode == 'Create') {
            let createReq: CreateNodeRequest = {
                hostname: hostname,
                current_contract: contractId,
                ip_addresses: ipAddresses,
                mac_addresses: macAddresses,
                ib_addresses: ibAddresses,
                comment: comment,
            }
            if (distributionId !== 0) {
                createReq.distribution_id = distributionId;
            }
            console.log(createReq);
            apiPost<Node>(NODES_URL, createReq)
                .then((_resp) => {
                    queryClient.invalidateQueries(['clusters', props.customerId])
                    handleCancelClick();
                }).catch((reason) => {
                    setResponseError(reason.toString());
                });
        } else {
            let modifyReq: ModifyNodeRequest = {
                hostname: hostname,
                current_contract: contractId,
                ip_addresses: ipAddresses,
                mac_addresses: macAddresses,
                ib_addresses: ibAddresses,
                comment: comment,
            }
            if (distributionId > 0) {
                modifyReq.distribution_id = distributionId;
            } else {
                modifyReq.distribution_id = null;
            }
            console.log(modifyReq);
            apiPut<Node>(NODES_URL + '/' + nodeId, modifyReq)
                .then((_resp) => {
                    queryClient.invalidateQueries(['clusters', props.customerId])
                    handleCancelClick();
                }).catch((reason) => {
                    setResponseError(reason.toString());
                });
        }
    }

    const handleCreateNodeClick = () => {
        setDrawerMode('Create');
        setDrawerExpanded(true);
        setResponseError('');
        setOriginalNode(null);

        setNodeId(0);
        setHostname('');
        setMacAddresses([]);
        setIpAddresses([]);
        setIbAddresses([]);
        setComment('');
        setDistributionId(0);
        setContractId(0);
    }

    const saveDisabled = () => {
        let disabled = hostname ? false : true;
        if (originalNode !== null) {
            const orig_distri_id = originalNode.distribution_id === null ? 0 : originalNode.distribution_id;
            disabled ||= originalNode.hostname === hostname && originalNode.comment === comment
                && orig_distri_id === distributionId && originalNode.current_contract === contractId
                && originalNode.mac_addresses.toString() === macAddresses.toString()
                && originalNode.ib_addresses.toString() === ibAddresses.toString()
                && originalNode.ip_addresses.toString() === ipAddresses.toString();
        }
        return disabled;
    }

    const handleCancelClick = () => {
        setDrawerMode('Create');
        setDrawerExpanded(false);
        setResponseError('');
    }

    function getContractById(id: number): ManagerContract | undefined {
        return props.contracts.find((contract) => contract.id === id);
    }

    function renderNodeRow(distris: Map<number, Distribution>, nodes: Node[]) {
        return nodes
            .sort((a, b) => ("" + a.hostname).localeCompare(b.hostname + "") + (a.deleted_at ? 10000000 : 0) - (b.deleted_at ? 10000000 : 0))
            .map((n, index) => {
                const distri = distris.get(n.distribution_id ? n.distribution_id : 0);
                const contract = n.current_contract ? getContractById(n.current_contract) : undefined;
                const isExpired = contract ? isContractExpired(contract) : false;
                return (
                    <Tr key={n.id} {...(n.deleted_at ? { style: { backgroundColor: "#ff8383" } } : {})}>
                        <Td>{n.hostname}</Td>
                        <Td><DistributionResolver distri_id={n.distribution_id == undefined ? null : n.distribution_id} /></Td>
                        <Td style={{ fontFamily: "RedHatMono", fontSize: "0.8em", whiteSpace: "nowrap" }}>{formatDateTime(n.created_at)}{n.deleted_at && <br />}{formatDateTime(n.deleted_at)}</Td>
                        <Td style={{ fontFamily: "RedHatMono", fontSize: "0.7em" }}>{n.url_hash}</Td>
                        <Td {...(isExpired ? { style: { backgroundColor: "#f75353" } } : {})}>
                            <Button
                                variant='link'
                                onClick={() => { navigate("?tab=" + (isExpired ? "2" : "0") + "#ct-" + n.current_contract, { replace: true }) }}
                                isSmall
                                style={{ paddingTop: 0, paddingBottom: 0 }}>{n.current_contract}
                            </Button>
                        </Td>
                        <Td textCenter style={{ whiteSpace: "nowrap" }}>
                            <Button variant='plain' className={styles.smallpadding} isSmall onClick={() => editNode(n)}
                                isDisabled={isReadOnlyCustomer(claims, props.contracts)}>
                                <Tooltip content="Edit"><PencilAltIcon /></Tooltip></Button>
                            <Popover
                                bodyContent={<NodeAccessInfoTable customer_id={props.customerId} cluster_id={props.clusterId} node_id={n.id} />}
                                hasAutoWidth={true}
                                >
                                    <Button variant='plain' className={styles.smallpadding} isSmall onClick={(e) => e.preventDefault()}>
                                        <Tooltip content="Access History"><ListIcon /></Tooltip></Button>
                            </Popover>
                            {n.deleted_at ?
                                <Button variant='plain' className={styles.smallpadding} isSmall onClick={() => reactivateNode(n.id)}
                                    isDisabled={isReadOnlyCustomer(claims, props.contracts)}>
                                    <Tooltip content="Reactivate"><HistoryIcon /></Tooltip></Button> :
                                <Button variant='plain' className={styles.smallpadding} isSmall onClick={() => removeNode(n.id)}
                                    isDisabled={isReadOnlyCustomer(claims, props.contracts)}>
                                    <Tooltip content="Remove Node"><TrashIcon /></Tooltip></Button>}
                            {n.reg_version > 0 && distri && distri.strictcheck ?
                                n.allow_unrestricted_dl ?
                                    <Tooltip content="Currently, repository access is unrestricted. Click here to strictly enforce repository access">
                                        <Button variant='plain' className={styles.smallpadding} isSmall
                                            onClick={() => changeNodeAccess(n.id, true)}
                                            isDisabled={isReadOnlyCustomer(claims, props.contracts)}>
                                            <UnlockIcon /></Button></Tooltip> :
                                    <Tooltip content="Currently, repository access is strictly enforced. Click here to allow unrestricted repository access">
                                        <Button variant='plain' className={styles.smallpadding} isSmall
                                            onClick={() => changeNodeAccess(n.id, false)}
                                            isDisabled={isReadOnlyCustomer(claims, props.contracts)}>
                                            <LockIcon /></Button></Tooltip> :
                                <Button variant='plain' className={styles.smallpadding} isSmall
                                    isDisabled={true}>
                                    <Tooltip content="Not able to restrict access for this node"><LockIcon /></Tooltip></Button>
                            }
                        </Td>
                    </Tr >
                )
            })
    }

    const { isLoading, isError, data, error } =
        useQuery<Map<number, Distribution>, string>(['distributions'], fetchDistributions);

    if (isError) {
        return (<span>{error}</span>)
    }

    if (isLoading || !data) {
        return (<Spinner isSVG />)
    }

    let distri_map = data ? data : new Map<number, Distribution>();

    const panelContent = (
        <DrawerPanelContent>
            <DrawerHead>
                <Title headingLevel={'h3'}>{drawerMode} Node</Title>
            </DrawerHead>
            <DrawerPanelBody>
                <Form isHorizontal>
                    {responseError && (
                        <FormAlert>
                            <Alert
                                variant='danger'
                                title={responseError}
                                isInline
                            />
                        </FormAlert>
                    )}
                    <FormGroup label="Hostname" fieldId='edit-node-hostname' isRequired>
                        <TextInput id="edit-node-hostname" aria-label="edit hostname input"
                            defaultValue={hostname} onChange={handleHostnameInputChange} />
                    </FormGroup>
                    <FormGroup label="MAC Addresses" fieldId='edit-node-macs'>
                        <SelectList
                            id="edit-node-macs"
                            style={{ fontFamily: "RedHatMono", fontSize: "0.9em" }}
                            values={macAddresses}
                            onListChange={(vals: Array<string>) => setMacAddresses(vals)}
                            inputRegex="^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$"
                            maxLength={17} />
                    </FormGroup>
                    <FormGroup label="IB-GUIDS" fieldId='edit-node-ibs'>
                        <SelectList
                            id="edit-node-ibs"
                            style={{ fontFamily: "RedHatMono", fontSize: "0.9em" }}
                            values={ibAddresses}
                            onListChange={(vals: Array<string>) => setIbAddresses(vals)}
                            inputRegex="^0x[0-9A-Fa-f]+"
                            maxLength={18} />
                    </FormGroup>
                    <FormGroup label="IP Addresses" fieldId='edit-node-ips'>
                        <SelectList
                            id="edit-node-ips"
                            style={{ fontFamily: "RedHatMono", fontSize: "0.9em" }}
                            values={ipAddresses}
                            onListChange={(vals: Array<string>) => setIpAddresses(vals)}
                            inputRegex="((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))"
                            maxLength={64} />
                    </FormGroup>
                    <FormGroup label="Comment" fieldId='edit-node-comment'>
                        <TextArea value={comment} onChange={(str: string) => setComment(str)} aria-label='comment' />
                    </FormGroup>
                    <DistributionSelect distributionId={distributionId} onChange={handleDistriChanged} />
                    <FormGroup label="Contract for node" fieldId='edit-node-contract'>
                        <ContractIdSelect
                            id='edit-node-contract'
                            customerId={props.customerId}
                            contractId={contractId}
                            showExpired={false}
                            allowNone={false}
                            onContractIdSelected={(contractId: number) => setContractId(contractId)} />
                    </FormGroup>
                    <ActionGroup>
                        <Button
                            variant="primary"
                            onClick={handleSubmitClick}
                            isDisabled={saveDisabled()}>{drawerMode == 'Edit' ? "Save" : 'Create'}</Button>
                        <Button variant="secondary" onClick={handleCancelClick}>Cancel</Button>
                    </ActionGroup>
                </Form>
            </DrawerPanelBody>
        </DrawerPanelContent>
    )

    return (
        <Drawer isExpanded={drawerExpanded}>
            <DrawerContent panelContent={panelContent}>
                <DrawerContentBody>
                    {responseError && (<Alert
                        variant='danger'
                        title={responseError}
                        actionClose={<AlertActionCloseButton onClose={() => setResponseError('')} />}
                    />)}
                    <TableComposable
                        variant='compact'
                        className={styles.tinytable}
                    >
                        <Thead>
                            <Tr>
                                <Th width={30}>Name</Th>
                                <Th width={10}>Distri</Th>
                                <Th width={15}>Created/Deleted at</Th>
                                <Th width={30}>Hash</Th>
                                <Th>Contract</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {renderNodeRow(distri_map, props.nodes)}
                        </Tbody>
                    </TableComposable><br />
                    <Button onClick={handleCreateNodeClick} isSmall isDisabled={isReadOnlyCustomer(claims, props.contracts)}>New Node</Button>
                </DrawerContentBody>
            </DrawerContent>
        </Drawer>
    )
}

interface LicenseTableProps {
    customerId: number,
    clusterId: number,
    contracts: ManagerContract[],
}

const LicenseTable: React.FunctionComponent<LicenseTableProps> = (props: LicenseTableProps) => {
    const QUERY_KEY_LICENSES = ["licenses", props.clusterId];

    const queryClient = useQueryClient();
    const claims = getCurrentClaims();

    async function fetchClusterLicenses(): Promise<License[]> {
        let uri = '/manager/customers/' + props.customerId + '/clusters/' + props.clusterId + '/licenses';
        return apiGet<LicensesResponse>(uri)
            .then((resp) => resp.list);
    }

    async function downloadLicense(license_id: number, fileName: string) {
        let uri = '/manager/customers/' + props.customerId + '/clusters/' + props.clusterId + '/licenses/' + license_id + '/file';
        await apiGetBlob(uri)
            .then(blob => saveAs(blob, fileName))
            .catch((err) => {
                let errMsg = err.toString();
                let answer = JSON.parse(err);
                if (answer) {
                    errMsg = "Error(" + answer.error.code + "): " + answer.error.message;
                }
                alert(errMsg);
            });
    }

    function removeLicense(license_id: number) {
        let uri = '/manager/customers/' + props.customerId + '/clusters/' + props.clusterId + '/licenses/' + license_id;
        apiDelete<String>(uri)
            .then((_resp) => queryClient.invalidateQueries(QUERY_KEY_LICENSES))
            .catch((reason) => alert(reason));
    }

    function renderLicenseRow(licTypes: LicenseTypeResponse, licenses: License[]) {
        return licenses.map((l) => {
            let isExpired = l.expiration_date ? Date.parse(l.expiration_date) < Date.now() : false;
            let licType = licTypes.licenses[l.license_type_id];
            let options = Object.keys(licType.options).map((key) => {
                let licOpt = licType.options[key];
                let licOptName = licOpt.name;
                var licOptValue = "not set";
                switch (licOpt.data_type.toLowerCase()) {
                    case "boolean": licOptValue = licOpt.id in l.bool_values ? l.bool_values[licOpt.id].value + "" : "not set"; break;
                    case "integer": licOptValue = licOpt.id in l.integer_values ? l.integer_values[licOpt.id].value + "" : "not set"; break;
                    case "string": licOptValue = licOpt.id in l.string_values ? l.string_values[licOpt.id].value : "not set"; break;
                }
                return { "name": licOptName, "value": licOptValue }
            })
            return (
                <Tr key={l.id} {...(isExpired ? { style: { backgroundColor: "#ff8383" } } : {})}>
                    <Td>{licTypes.licenses[l.license_type_id].name}</Td>
                    <Td>{options.map((opt) => { return <p key={opt.name}>{opt.name}: {opt.value}</p> })}</Td>
                    <Td style={{ fontFamily: "RedHatMono" }}>{l.expiration_date ? l.expiration_date : "Unlimited"}</Td>
                    <Td style={{ fontFamily: "RedHatMono" }}>{l.invoice_issued ? l.invoice_issued : "none yet"}</Td>
                    <Td textCenter style={{ whiteSpace: "nowrap" }}>
                        <Button
                            variant='plain'
                            className={styles.smallpadding}
                            isSmall
                            onClick={() => downloadLicense(
                                l.id, licType.name.startsWith("RDMA") ? "drbd-transport-rdma.license" : "drbd-proxy.license")}
                            isDisabled={isReadOnlyCustomer(claims, props.contracts)}>
                            <Tooltip content="Download license"><DownloadIcon /></Tooltip></Button>
                        <Button variant='plain' className={styles.smallpadding} isSmall
                            onClick={() => alert("not implemented yet")}>
                            <Tooltip content="Send license"><MailIcon /></Tooltip></Button>
                        <Button variant='plain' className={styles.smallpadding} isSmall
                            onClick={() => { if (confirm("Really delete license?")) removeLicense(l.id) }}
                            isDisabled={isReadOnlyCustomer(claims, props.contracts)}>
                            <Tooltip content="Remove license"><TrashIcon /></Tooltip></Button>
                    </Td>
                </Tr>
            )
        })
    }

    const { isLoading, isError, data, error } = useQuery<License[], string>(QUERY_KEY_LICENSES, fetchClusterLicenses);
    const licTypesQuery = queryLicTypes();

    if (isError || licTypesQuery.isError) {
        return (<span>Error: {licTypesQuery.error}</span>)
    }

    if (isLoading || licTypesQuery.isLoading) {
        return (<Spinner />)
    }

    const licenses = data ? data : [];
    const licTypes = licTypesQuery.data ? licTypesQuery.data : { licenses: {} };

    if (licenses.length == 0) {
        return (<span>No Licenses</span>)
    }

    return (
        <TableComposable
            variant='compact'
            className={styles.tinytable}
        >
            <Thead>
                <Tr>
                    <Th width={25}>License Type</Th>
                    <Th>Options</Th>
                    <Th>Expiration</Th>
                    <Th>Invoice</Th>
                    <Th width={10}>Action</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderLicenseRow(licTypes, licenses)}
            </Tbody>
        </TableComposable>
    )
}

interface ClusterCardProps {
    clusterData: Cluster,
    contracts: ManagerContract[],
    onNewLicense: (cluster_id: number) => void,
}

const ClusterCard: React.FunctionComponent<ClusterCardProps> = (props: ClusterCardProps) => {
    const queryClient = useQueryClient();
    const claims = getCurrentClaims();

    const [isEditMode, setIsEditMode] = React.useState(false);
    const [documentation, setDocumentation] = React.useState("");

    const [errorMessage, setErrorMessage] = React.useState("");

    const deleteCluster = (clusterId: number) => {
        setErrorMessage("");
        let uri = '/manager/customers/' + props.clusterData.customer_id + '/clusters/' + clusterId;
        apiDelete<String>(uri)
            .then((_resp) => queryClient.invalidateQueries(['clusters', props.clusterData.customer_id]))
            .catch((reason) => setErrorMessage(reason.toString()));
    }

    const handleEditDocumenationCheckClick = () => {
        apiPut<Cluster>('/manager/customers/' + props.clusterData.customer_id + '/clusters/' + props.clusterData.id,
            { documentation: documentation })
            .then((_resp) => {
                queryClient.invalidateQueries(['clusters', props.clusterData.customer_id]);
            })
            .catch((reason) => setErrorMessage(reason.toString()));
        setIsEditMode(false);
    }

    const handleEditDocumenationInputChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
        setDocumentation(value);
    };

    return (
        <Card isCompact={true} id={"card-cluster-" + props.clusterData.id}>
            <CardTitle>
                <Title headingLevel='h2'>Cluster #{props.clusterData.id}
                    <Button
                        variant='plain'
                        onClick={() => { if (confirm("Really delete cluster " + props.clusterData.id)) deleteCluster(props.clusterData.id) }}>
                        <Tooltip content="Delete cluster with all nodes/licenses"><TrashIcon /></Tooltip></Button>
                </Title>
                {errorMessage && <Alert title={"Error deleting cluster: " + errorMessage} variant='danger' />}
            </CardTitle>
            <CardBody>
                <DescriptionList>
                    <DescriptionListGroup>
                        {isEditMode ?
                            <TextInputGroup>
                                <TextInput id="edit-customer-name" aria-label="edit name input"
                                    defaultValue={props.clusterData.documentation ? props.clusterData.documentation : ""}
                                    onChange={handleEditDocumenationInputChange} />
                                <Button variant="control" aria-label="edit name" onClick={handleEditDocumenationCheckClick} >
                                    <CheckIcon />
                                </Button>
                                <Button variant="control" aria-label="cancel edit name" onClick={() => setIsEditMode(false)} >
                                    <TimesIcon />
                                </Button>
                            </TextInputGroup>
                            :
                            <Title headingLevel="h1" size="xl" className='pf-c-inline-edit'>{props.clusterData.documentation}
                                <Button
                                    variant='plain'
                                    id="inline-edit-toggle-example-edit-button"
                                    aria-label="Edit"
                                    aria-labelledby="inline-edit-toggle-example-edit-button inline-edit-toggle-example-label"
                                    onClick={() => setIsEditMode(true)}
                                >
                                    <PencilAltIcon />
                                </Button>
                            </Title>
                        }
                    </DescriptionListGroup>
                    <DescriptionListGroup>
                        <DescriptionListTerm>Nodes</DescriptionListTerm>
                        <DescriptionListDescription>
                            <NodeTable
                                nodes={props.clusterData.nodes}
                                customerId={props.clusterData.customer_id}
                                clusterId={props.clusterData.id}
                                contracts={props.contracts} />
                        </DescriptionListDescription>
                    </DescriptionListGroup>
                </DescriptionList>
            </CardBody>
            <CardBody>
                <DescriptionList>
                    <DescriptionListGroup>
                        <DescriptionListTerm>Licenses</DescriptionListTerm>
                        <DescriptionListDescription>
                            <LicenseTable customerId={props.clusterData.customer_id} clusterId={props.clusterData.id} contracts={props.contracts} />
                        </DescriptionListDescription>
                    </DescriptionListGroup>
                </DescriptionList>
            </CardBody>
            <Divider />
            <CardFooter>
                <Button
                    isSmall
                    onClick={() => props.onNewLicense(props.clusterData.id)}
                    isDisabled={isReadOnlyCustomer(claims, props.contracts) || !claims.may_issue_licenses}>New Proxy License</Button>
            </CardFooter>
        </Card>
    )
}


interface ModalProxyLicenseDialogProps {
    customerId: number,
    clusterId: number,
    isOpen: boolean,
    onCreated: (lic: License) => void,
    onClose: () => void,
    onCancel: () => void,
}

const ModalProxyLicenseDialog: React.FunctionComponent<ModalProxyLicenseDialogProps> = (props: ModalProxyLicenseDialogProps) => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [licenseTypeId, setLicenseTypeId] = React.useState(2);
    const [expirationDate, setExpirationDate] = React.useState(
        new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0, 10));
    const [boolValues, setBoolValues] = React.useState<{ [key: number]: boolean }>({});

    const onChangeExpirationDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (str == "") {
            setExpirationDate("");
        } else if (date && isValidDate(date)) {
            setExpirationDate(date.toISOString().slice(0, 10));
        }
    }

    const onChangeLicenseType = (val: string) => {
        setLicenseTypeId(+val);
        setBoolValues({});
    }

    const setAdvancedYear = (years: number) => {
        let future = new Date(new Date().setFullYear(new Date().getFullYear() + years));
        setExpirationDate(future.toISOString().slice(0, 10));
    }

    const setAdvancedDays = (days: number) => {
        let future = new Date(new Date().setDate(new Date().getDate() + days));
        setExpirationDate(future.toISOString().slice(0, 10));
    }

    const onCreateLicenseClick = () => {
        let createreq: CreateClusterLicenseRequest = {
            license_type_id: licenseTypeId,
            bool_values: Object.keys(boolValues).map((key, index) => {
                return { id: index, license_option_description_id: +key, value: boolValues[+key] }
            }),
            integer_values: [],
            string_values: []
        };
        if (expirationDate) {
            createreq.expiration_date = expirationDate;
        }
        console.log(createreq);
        apiPost<License>('/manager/customers/' + props.customerId + '/clusters/' + props.clusterId + "/licenses", createreq)
            .then((resp) => {
                props.onCreated(resp);
            }).catch((reason) => {
                setErrorMessage(reason.toString());
            });
    }

    const { isLoading, isError, data, error } = queryLicTypes();

    if (isError) {
        return (<Modal><Alert title={"Error loading license types:" + error} /></Modal>)
    }

    const lics = data ? data.licenses : {};
    const fakeOpts: { [key: string]: LicenseOptionDescription } = {};
    const selectedLicense: LicenseType = lics[licenseTypeId] ?
        lics[licenseTypeId] : { options: fakeOpts, id: 0, name: "unknown", feature_format: true };

    return (<Modal
        variant={ModalVariant.small}
        title="New License"
        isOpen={props.isOpen}
        onClose={props.onClose}
        actions={[
            <Button key="confirm" variant="primary" onClick={onCreateLicenseClick}>
                Create
            </Button>,
            <Button key="cancel" variant="secondary" onClick={props.onCancel}>
                Abort
            </Button>
        ]}
    >
        <Form id="modal-new-license-form">
            {isLoading ? <Spinner /> :
                <FormGroup
                    label="License Type"
                    fieldId="form-license-type">
                    <FormSelect id="form-license-type" value={licenseTypeId} onChange={onChangeLicenseType}>
                        {Object.keys(lics).map((key) => {
                            let lic = lics[key];
                            return (<FormSelectOption key={key} label={lic?.name || "unkown"} value={key} />)
                        })}
                    </FormSelect>
                </FormGroup>
            }
            <FormGroup
                label="Expiration date"
                fieldId="form-license-expiration">
                <DatePicker
                    id='payment-date'
                    onChange={onChangeExpirationDate}
                    value={expirationDate} />&nbsp;
                <Button onClick={() => { setAdvancedDays(30) }} variant='secondary'>30d</Button>
                <Button onClick={() => { setAdvancedYear(1) }} variant='secondary'>1y</Button>
                <Button onClick={() => { setAdvancedYear(2) }} variant='secondary'>2y</Button>
                <Button onClick={() => { setAdvancedYear(3) }} variant='secondary'>3y</Button>
                <Button onClick={() => { setAdvancedYear(4) }} variant='secondary'>4y</Button>
                <Button onClick={() => { setAdvancedYear(5) }} variant='secondary'>5y</Button>
            </FormGroup>
            <FormGroup role="group" label="Options" fieldId="form-options">
                {Object.keys(selectedLicense.options).map((key) => {
                    const opt = selectedLicense.options[key];
                    if (opt.data_type === "Boolean") {
                        return <Checkbox
                            key={opt.id}
                            label={opt.name}
                            id={"opt-" + opt.id}
                            isChecked={boolValues[opt.id]}
                            onChange={(checked, _event) => { setBoolValues({ ...boolValues, [opt.id]: checked }); }} />
                        // } else if (opt.data_type === "Integer") {
                        //     return <TextInput key={opt.id} type="number" id={"opt-" + opt.id} />
                        // } else if (opt.data_type === "String") {
                        //     return <TextInput key={opt.id} type="text" id={"opt-" + opt.id} />
                    } else {
                        return <FormAlert key={opt.id}>Unknown data type</FormAlert>
                    }
                })}
            </FormGroup>
            <FormAlert>{errorMessage && errorMessage}</FormAlert>
        </Form>
    </Modal>)
}

interface ClusterViewProps {
    customerId: number,
}

const ClusterView: React.FunctionComponent<ClusterViewProps> = (props: ClusterViewProps) => {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [searchInput, setSearchInput] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(20);

    const [modalNewLicenseCluster, setModalNewLicenseCluster] = React.useState(0);

    const [searchParams, setSearchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const claims = getCurrentClaims();

    React.useEffect(() => {
        const filter = searchParams.get("filter");
        if (filter) {
            setSearchInput(filter);
        }
    }, [location]);

    const handleSearchInputChange = (_event: React.FormEvent<HTMLInputElement>, str: string) => {
        setCurrentPage(1);
        setSearchInput(str);
    }

    const helpContent = (
        <div>
            <p>Per default this input searches for node names, but can also search for clusterId, contractId or node-hash.</p>
            <p>For that you need to prefix you searches with either
                <List>
                    <ListItem><code>cluster=</code></ListItem>
                    <ListItem><code>contract=</code></ListItem>
                    <ListItem><code>hash=</code></ListItem>
                    <ListItem><code>mac=</code></ListItem>
                    <ListItem><code>ip=</code></ListItem>
                    <ListItem><code>ibguid=</code></ListItem>
                    <ListItem><code>hasexpiredcontracts=</code></ListItem>
                </List>
            </p>
        </div>
    )

    const SearchInput = (
        <TextInputGroup>
            <TextInputGroupMain icon={<SearchIcon />} type='search' value={searchInput} onChange={handleSearchInputChange} />
            <TextInputGroupUtilities>
                <Popover
                    headerContent="Search input help"
                    bodyContent={helpContent}
                >
                    <button
                        type="button"
                        aria-label="More info for name field"
                        onClick={e => e.preventDefault()}
                        aria-describedby="simple-form-name-01"
                        className="pf-c-form__group-label-help"
                    >
                        <HelpIcon noVerticalAlign />
                    </button>
                </Popover>
            </TextInputGroupUtilities>
        </TextInputGroup>
    );

    function renderClusterCards(
        clusters: Cluster[],
        contracts: ManagerContract[]) {
        return clusters.map((cluster, index) => {
            return (<ClusterCard clusterData={cluster} contracts={contracts} key={cluster.id} onNewLicense={(cluster_id: number) => setModalNewLicenseCluster(cluster_id)} />)
        })
    }

    const onSetPage = (_event: any, pageNumber: number) => {
        setCurrentPage(pageNumber);
    }

    const onPerPageSelect = (_event: any, itemsPerPage: number, newPage: number) => {
        setItemsPerPage(itemsPerPage);
        setCurrentPage(newPage);
    }

    const onNewClusterClick = () => {
        apiPost<Cluster>('/manager/customers/' + props.customerId + '/clusters', {})
            .then((_resp) => {
                setSearchInput("cluster=" + _resp.id.toString());
                queryClient.invalidateQueries(['clusters', props.customerId]);
            }).catch((reason) => {
                setErrorMessage(reason.toString());
            });
    }

    const onDeleteAllClustersClick = () => {
        if (confirm("Really delete all clusters(with nodes) for this customer?")) {
            setErrorMessage("");

            let deletes: Promise<string>[] = [];
            data?.list.forEach((c) => {
                let uri = '/manager/customers/' + props.customerId + '/clusters/' + c.id;
                deletes.push(apiDelete<string>(uri));
            })

            Promise.all(deletes).then((_resp) =>
                queryClient.invalidateQueries(['clusters', props.customerId])
            ).catch((reason) => {
                setErrorMessage(reason.toString());
            })
        }
    }

    const { isLoading, isError, data, error } = queryClusters(props.customerId);
    const contractsRaw = useQuery<ManagerContract[], string>(['contracts', props.customerId, true], () => fetchCustomerContracts(props.customerId, true))

    if (isError || contractsRaw.isError) {
        return (<PageSection><Alert variant="danger" title={"" + error + contractsRaw.error} /></PageSection>)
    } else {
        let filtered: Cluster[] = data ? data.list : [];
        let count = 0;
        if (data) {
            let start = (currentPage - 1) * itemsPerPage;
            filtered = filtered.filter((c) => {
                if (searchInput && searchInput.length > 2) {
                    if (searchInput.startsWith("cluster=")) {
                        const subSearch = searchInput.substring(8);
                        if (!subSearch) {
                            return false;
                        }
                        return c.id.toString() === subSearch;
                    } else if (searchInput.startsWith("contract=")) {
                        const subSearch = +searchInput.substring(9);
                        if (!subSearch) {
                            return false;
                        }
                        return c.nodes.findIndex((n) => n.current_contract === subSearch) !== -1;
                    } else if (searchInput.startsWith("hash=")) {
                        const subSearch = searchInput.substring(5);
                        if (!subSearch) {
                            return false;
                        }
                        return c.nodes.findIndex((n) => n.url_hash.includes(subSearch)) !== -1;
                    } else if (searchInput.startsWith("mac=")) {
                        const subSearch = searchInput.substring(4);
                        if (!subSearch) {
                            return false;
                        }
                        return c.nodes
                            .findIndex((n) => n.mac_addresses
                                .some((mac) => mac.search(
                                    isValidRegex(subSearch) ? new RegExp(subSearch, "i") : new RegExp("")) > -1)) > -1;
                    } else if (searchInput.startsWith("ip=")) {
                        const subSearch = searchInput.substring(3);
                        if (!subSearch) {
                            return false;
                        }
                        return c.nodes.findIndex((n) => n.ip_addresses.some((ip) => ip.search(subSearch) > -1)) > -1;
                    } else if (searchInput.startsWith("ibguid=")) {
                        const subSearch = searchInput.substring(7);
                        if (!subSearch) {
                            return false;
                        }
                        return c.nodes
                            .findIndex((n) => n.ib_addresses
                                .some((ib) => ib.search(
                                    isValidRegex(subSearch) ? new RegExp(subSearch, "i") : new RegExp("")) > -1)) > -1;
                    } else if (searchInput.startsWith("hasexpiredcontracts=")) {
                        return c.nodes
                            .some((n) => {
                                const contract = contractsRaw.data?.find((c) => c.id === n.current_contract);
                                return contract && isContractExpired(contract)
                            })
                    } else {
                        return c.nodes
                            .findIndex((n) => n.hostname?.includes(searchInput)) !== -1;
                    }
                }
                return true;
            });
            count = filtered.length;
            filtered = filtered.slice(start, start + itemsPerPage);
        }

        const contracts = contractsRaw.data ? contractsRaw.data : [];
        return (
            <PageSection>
                <Button isSmall onClick={() => onNewClusterClick()}
                    isDisabled={isReadOnlyCustomer(claims, contracts)}>New Cluster</Button>&nbsp;
                <Button isSmall variant='danger' onClick={() => onDeleteAllClustersClick()}
                    isDisabled={isReadOnlyCustomer(claims, contracts)}>Delete All Clusters</Button>
                {errorMessage && <Alert title={errorMessage} variant='danger' />}
                <br /><br />
                {SearchInput}
                <Pagination
                    itemCount={count}
                    widgetId="pagination-customers-menu-bottom"
                    perPage={itemsPerPage}
                    page={currentPage}
                    onSetPage={onSetPage}
                    onPerPageSelect={onPerPageSelect}
                    variant={PaginationVariant.top}
                />
                <Gallery hasGutter minWidths={{ md: '700px' }}>
                    {isLoading || contractsRaw.isLoading || !filtered ? <Spinner isSVG /> : renderClusterCards(filtered, contracts)}
                </Gallery>
                <ModalProxyLicenseDialog
                    customerId={props.customerId}
                    clusterId={modalNewLicenseCluster}
                    isOpen={modalNewLicenseCluster > 0}
                    onCreated={() => { queryClient.invalidateQueries(["licenses", modalNewLicenseCluster]); setModalNewLicenseCluster(0); }}
                    onClose={() => setModalNewLicenseCluster(0)}
                    onCancel={() => setModalNewLicenseCluster(0)} />
            </PageSection>
        )
    }
}

export { ClusterView }
