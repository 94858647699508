import * as React from 'react';
import {
    Alert, PageSection, Spinner, Title, BackToTop, Badge, ClipboardCopy
} from "@patternfly/react-core"
import { UseQueryResult, useQuery } from "@tanstack/react-query"

import { SessionsResponse, SessionInfo, apiGet } from 'linbit-api-fetcher'
import { useNavigate } from 'react-router-dom';
import { TableComposable, Thead, Tr, Th, Tbody, Td, ThProps } from '@patternfly/react-table';

interface SessionsTableProps {
    tickets: SessionInfo[]
}

const SessionsTable: React.FunctionComponent<SessionsTableProps> = (props: SessionsTableProps) => {
    const [activeSortIndex, setActiveSortIndex] = React.useState<number>(2);

    // Sort direction of the currently sorted column
    const [activeSortDirection, setActiveSortDirection] = React.useState<'asc' | 'desc'>('asc');

    const getSortParams = (columnIndex: number): ThProps['sort'] => ({
        sortBy: {
            index: activeSortIndex,
            direction: activeSortDirection
        },
        onSort: (_event, index, direction) => {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
        },
        columnIndex
    });

    const boolBadge = (val: boolean) => {
        return val ?
            <Badge style={{ backgroundColor: 'green' }}>yes</Badge> : <Badge style={{ backgroundColor: 'red' }}>no</Badge>;
    }

    function renderIssuerRows(session: SessionInfo[]) {
        return session.sort((a, b) => {
            switch (activeSortIndex) {
                case 0: {
                    return activeSortDirection === 'asc'
                        ? a.refresh_token.localeCompare(b.refresh_token) : b.refresh_token.localeCompare(a.refresh_token);
                }
                case 1: {
                    return activeSortDirection === 'asc' ?
                        a.claim.sub.localeCompare(b.claim.sub) : b.claim.sub.localeCompare(a.claim.sub);
                }
                case 3: {
                    return activeSortDirection === 'asc' ?
                        a.claim.role.localeCompare(b.claim.role) : b.claim.role.localeCompare(a.claim.role);
                }
                case 4: {
                    return activeSortDirection === 'asc' ?
                        a.claim.issuer_id - b.claim.issuer_id : b.claim.issuer_id - a.claim.issuer_id;
                }
                default: {
                    return activeSortDirection === 'asc' ?
                        a.claim.exp - b.claim.exp : b.claim.exp - a.claim.exp;
                }
            }
        }).map((s) => {
            return (
                <Tr key={s.refresh_token}>
                    <Td><ClipboardCopy>{s.refresh_token}</ClipboardCopy></Td>
                    <Td>{s.claim.sub}</Td>
                    <Td style={{ fontFamily: "RedHatMono", fontSize: "0.9em", whiteSpace: "nowrap" }}>{new Date(s.claim.exp * 1000).toISOString()}</Td>
                    <Td>{s.claim.role}</Td>
                    <Td>{s.claim.issuer_id}</Td>
                    <Td>{s.claim.credential_id}</Td>
                    <Td>{s.claim.contact_id}</Td>
                    <Td textCenter>{boolBadge(s.claim.may_issue_licenses || false)}</Td>
                </Tr>)
        })
    }

    return (
        <TableComposable
            variant='compact'
        // className={styles.smalltable}
        >
            <Thead>
                <Tr>
                    <Th sort={getSortParams(0)}>refresh-token</Th>
                    <Th sort={getSortParams(1)}>Subject</Th>
                    <Th sort={getSortParams(2)}>Expires</Th>
                    <Th sort={getSortParams(3)}>Role</Th>
                    <Th sort={getSortParams(4)}>IssuerId</Th>
                    <Th>CredentialId</Th>
                    <Th>ContactId</Th>
                    <Th>MayIssueLicenses</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderIssuerRows(props.tickets)}
            </Tbody>
        </TableComposable>
    )
}

const AdminSessionsPage: React.FunctionComponent = () => {
    const navigate = useNavigate();

    async function fetchAdminSessions(): Promise<SessionsResponse> {
        let uri = '/manager/admin/sessions';
        return apiGet<SessionsResponse>(uri);
    }

    function queryAdminSessions(): UseQueryResult<SessionsResponse, string> {
        return useQuery<SessionsResponse, string>(['admin', 'sessions'], fetchAdminSessions);
    }

    const { isLoading, isError, data, error } = queryAdminSessions();

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>);
    }

    const sessionsCount = data ? data.count : 0;
    const sessions = data ? data.list : [];
    return (
        <React.Fragment>
            <PageSection hasOverflowScroll name="scrolling-section">
                <Title
                    id="title-ticket-head"
                    headingLevel="h1"
                    size="xl">{sessionsCount} Sessions</Title>
                <br />
                {isLoading ? <Spinner isSVG /> : <SessionsTable tickets={sessions} />}
            </PageSection>
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>)
}

export {
    AdminSessionsPage
}
