import * as React from 'react';
import {
    Alert, BackToTop, Badge, PageSection, SearchInput, Spinner, Tab, TabContent, TabContentBody, Tabs, TabTitleText,
    Title, Toolbar, ToolbarContent, ToolbarItem
} from "@patternfly/react-core"
import { useQuery } from "@tanstack/react-query"

import { ManagerContractResponse } from 'linbit-api-fetcher'
import { ContractViewTable } from "./contracts-view"
import { fetchContractsByEmail } from './fetcher';
import { useSearchParams } from 'react-router-dom';
import { isContractExpired } from './contract-components';

const ContractByContactPage: React.FunctionComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [searchEmail, setSearchEmail] = React.useState('');

    const tabKeyRaw = searchParams.get("tab");
    const tabKey = tabKeyRaw !== null ? +tabKeyRaw : 0;

    const emailRaw = searchParams.get("email");
    const searchString = emailRaw !== null ? emailRaw : "";

    const { isLoading, isError, data, error } =
        useQuery<ManagerContractResponse, string>(["contracts-by-email", searchString], () => fetchContractsByEmail(searchString));

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>);
    }

    const contractsAll = data ? data.list : [];
    const contracts = contractsAll.filter((c) => !isContractExpired(c));
    const contractsExpired = contractsAll.filter((c) => isContractExpired(c));
    return (
        <React.Fragment>
            <PageSection>
                <Title headingLevel="h1" size="xl">Contracts containing contact email '{searchString}' <Badge>{contractsAll.length}</Badge></Title>
                <Toolbar>
                    <ToolbarContent>
                        <ToolbarItem>
                            <SearchInput
                                placeholder="Find by contact email"
                                value={searchEmail}
                                onChange={(_e, val: React.SetStateAction<string>) => setSearchEmail(val)}
                                onSearch={() => setSearchParams({ email: searchEmail })}
                                onClear={() => setSearchEmail("")}
                            />
                        </ToolbarItem>
                    </ToolbarContent>
                </Toolbar>
            </PageSection>
            <PageSection type="tabs">
                <Tabs activeKey={tabKey} onSelect={(_event, tabIndex) => setSearchParams({ tab: tabIndex + "", email: searchString })} usePageInsets={true}>
                    <Tab eventKey={0} title={<TabTitleText>Active <Badge>{contracts.length}</Badge></TabTitleText>} />
                    <Tab eventKey={1} title={<TabTitleText>Expired <Badge>{contractsExpired.length}</Badge></TabTitleText>} />
                </Tabs>
            </PageSection>
            {isLoading ? <Spinner isSVG /> :
                <PageSection hasOverflowScroll name="scrolling-section">
                    <TabContent key={0} eventKey={0} id={`tabContent${0}`} activeKey={tabKey} hidden={0 !== tabKey}>
                        <TabContentBody>{0 === tabKey ? <ContractViewTable contracts={contracts} showContacts /> : <div />}</TabContentBody>
                    </TabContent>
                    <TabContent key={1} eventKey={1} id={`tabContent${1}`} activeKey={tabKey} hidden={1 !== tabKey}>
                        <TabContentBody>{1 === tabKey ? <ContractViewTable contracts={contractsExpired} showContacts /> : <div />}</TabContentBody>
                    </TabContent>
                </PageSection>
            }
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>)
}

export {
    ContractByContactPage
}
