import * as React from 'react';
import {
    Alert, PageSection, Spinner, Title, BackToTop, Button,
    ExpandableSection, AlertGroup, AlertProps, AlertVariant, AlertActionCloseButton
} from "@patternfly/react-core"
import { useQuery, useQueryClient } from "@tanstack/react-query"

import { useNavigate, useParams } from 'react-router-dom';
import { TableComposable, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table';
import { ContractContactResponse, ManagerContract } from 'linbit-api-fetcher';
import { LdapNameResolver } from './components';
import { ContractContacts, ContractKindResolver } from './contract-components';
import { apiPost, fetchCustomerContracts } from './fetcher';

interface SelectableContractTableProps {
    contracts: ManagerContract[]
    selected: (selected: number[]) => void;
}

const SelectableContractTable: React.FunctionComponent<SelectableContractTableProps> = (props: SelectableContractTableProps) => {
    const [selContracts, setSelContracts] = React.useState<number[]>([]);

    const onSelectContract = (c: ManagerContract, isSelecting: boolean) => {
        let cur = isSelecting ? selContracts.concat([c.id]) : selContracts.filter((n) => n !== c.id);
        setSelContracts(cur);
        props.selected(cur);
    }

    const isContractSelected = (c: ManagerContract) => {
        return selContracts.find((n) => n === c.id) !== undefined;
    }

    const selectAll = (isSelecting = true) => {
        let cur = isSelecting ? props.contracts.map((c) => c.id) : [];
        setSelContracts(cur);
        props.selected(cur);
    }

    const areAllSelected = selContracts.length === props.contracts.length;

    function renderTicketRows(contracts: ManagerContract[]) {
        return contracts.map((c, idx) => {
            return (
                <Tr key={c.id}>
                    <Td select={{
                        rowIndex: idx,
                        onSelect: (_event, isSelecting) => onSelectContract(c, isSelecting),
                        isSelected: isContractSelected(c),
                    }} />
                    <Td style={{ fontFamily: "RedHatMono" }}>{c.id}</Td>
                    <Td><LdapNameResolver ldap_uid={c.account_manager_ldap} /></Td>
                    <Td><ContractKindResolver kind={c.kind_id} /></Td>
                    <Td style={{ fontFamily: "RedHatMono", fontSize: "0.85em", whiteSpace: "nowrap" }}>{c.support_until}</Td>
                    <Td style={{ whiteSpace: "nowrap" }}><ContractContacts contractId={c.id} /></Td>
                    <Td>{c.comment}</Td>
                </Tr>)
        })
    }

    return (
        <TableComposable
            variant='compact'
        // className={styles.smalltable}
        >
            <Thead>
                <Tr>
                    <Th select={{
                        onSelect: (_event, isSelecting) => selectAll(isSelecting),
                        isSelected: areAllSelected
                    }} />
                    <Th>Id</Th>
                    <Th>Account Manager</Th>
                    <Th>Level</Th>
                    <Th>Expires</Th>
                    <Th>Contacts</Th>
                    <Th>Comment</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderTicketRows(props.contracts)}
            </Tbody>
        </TableComposable>
    )
}


const ContractContactCopyPage: React.FunctionComponent = () => {
    const [alerts, setAlerts] = React.useState<Partial<AlertProps>[]>([]);
    const [selection, setSelection] = React.useState<number[]>([]);

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const params = useParams();

    const customerId = params.customerId ? parseInt(params.customerId) : 0;
    const contractIdFrom = params.contractId ? parseInt(params.contractId) : 0;

    const QUERY_KEY = ['contracts', customerId, false];

    const onSelectContracts = (selected: number[]) => {
        setSelection(selected);
    }

    const copyContacts = async () => {
        let copyTo = [...selection];
        setSelection([]);
        let al = [...alerts];
        for (const cid of copyTo) {
            await apiPost<ContractContactResponse>('/manager/contracts/' + cid + '/contacts-copy/' + contractIdFrom, {})
                .then((_resp) => {
                    al.push({ title: "Copied contacts to " + cid, variant: 'success', key: cid });
                    queryClient.invalidateQueries(['contract-contacts', cid]);
                })
                .catch((reason) => al.push({ title: "Error copy contacts: " + reason, variant: 'danger', key: cid }));
        }
        setAlerts(al);
    }

    const removeAlert = (key: React.Key | null | undefined) => {
        const newAlerts = alerts.filter(alert => alert.key !== key);
        setAlerts(newAlerts);
    };

    const { isLoading, isError, data, error } = useQuery<ManagerContract[], string>(
        QUERY_KEY, () => fetchCustomerContracts(customerId, false));

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>);
    }

    const contracts = data ? data.filter((c) => c.id != contractIdFrom) : [];
    console.log("alerts main", alerts);
    return (
        <React.Fragment>
            <PageSection hasOverflowScroll name="scrolling-section">
                <Title
                    id="title-ticket-head"
                    headingLevel="h1"
                    size="xl">Copy contacts of contract #{contractIdFrom}
                </Title>
                <AlertGroup isLiveRegion isToast>
                    {alerts.map(({ key, variant, title }) => (
                        <Alert
                            isInline
                            variant={AlertVariant[variant ? variant : 'default']}
                            title={title}
                            actionClose={
                                <AlertActionCloseButton
                                    title={title as string}
                                    variantLabel={`${variant} alert`}
                                    onClose={() => removeAlert(key)}
                                />
                            }
                            key={key}
                        />
                    ))}
                </AlertGroup>
                <br />
                {isLoading ? <Spinner isSVG /> : <React.Fragment>
                    <ExpandableSection toggleText='Contacts to copy' displaySize='large' isIndented>
                        <ContractContacts contractId={contractIdFrom} />
                    </ExpandableSection>
                    <br />
                    <Button
                        onClick={copyContacts}
                        isDisabled={selection.length === 0}>Copy</Button>&nbsp;&nbsp;
                    <Button variant='secondary' onClick={() => navigate(-1)}>Back</Button>
                    <br /><br />
                    <SelectableContractTable contracts={contracts} selected={onSelectContracts} />
                </React.Fragment>}
            </PageSection>
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>)
}

export {
    ContractContactCopyPage
}
