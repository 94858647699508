import format from "date-fns/format";

function formatDateTime(dstr: string | null | undefined): string {
    if (dstr) {
        let d = Date.parse(dstr);
        return format(d, "yyyy-MM-dd'T'HH:mm:ss");
    }
    return "";
}

function formatDate(d: Date): string {
    return format(d, "yyyy-MM-dd");
}

function formatTimeDuration(minutes: number): string {
    let hours = Math.floor(minutes / 60);
    let mins = minutes - hours * 60;

    var s = ""
    if (hours) {
        s += hours + "h"
    }
    s += mins > 0 ? `${mins}`.padStart(2, "0") : "0";
    return s;
}

function setUTC(rd: Date, year: number, month: number, day: number) {
    rd.setUTCFullYear(year);
    rd.setUTCMonth(month);
    rd.setUTCDate(day);
    rd.setUTCMinutes(0);
    rd.setUTCHours(0);
    rd.setUTCSeconds(0);
    rd.setUTCMilliseconds(0);
    return rd;
}

export {
    formatDateTime, formatDate, formatTimeDuration,
    setUTC
}
