import * as React from 'react';
import {
    Alert, BackToTop, Badge, Button, ContextSelectorFooter, DatePicker, isValidDate, NumberInput, PageSection, Spinner,
    Switch,
    Title, Toolbar, ToolbarContent, ToolbarItem, Tooltip
} from "@patternfly/react-core"
import { useQuery } from "@tanstack/react-query"

import { LicenseExpiring, LicenseExpiringResponse, ManagerCustomer } from 'linbit-api-fetcher'
import { fetchCustomers, fetchLicensesExpiring } from './fetcher';
import { formatDate, setUTC } from './formatter';
import { ThProps, Tr, Td, TableComposable, Thead, Th, Tbody } from '@patternfly/react-table';
import { LicenseTypeResolver } from './components';
import { Link } from 'react-router-dom';

interface LicensesExpiringTableProps {
    licenses: LicenseExpiring[]
}

const LicensesExpiringTable: React.FunctionComponent<LicensesExpiringTableProps> = (props: LicensesExpiringTableProps) => {
    const [activeSortIndex, setActiveSortIndex] = React.useState<number>(3);

    // Sort direction of the currently sorted column
    const [activeSortDirection, setActiveSortDirection] = React.useState<'asc' | 'desc'>('asc');

    const getSortParams = (columnIndex: number): ThProps['sort'] => ({
        sortBy: {
            index: activeSortIndex,
            direction: activeSortDirection
        },
        onSort: (_event, index, direction) => {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
        },
        columnIndex
    });

    function renderLicenseRows(licenses: LicenseExpiring[]) {
        return licenses.sort((a, b) => {
            if (activeSortIndex !== null) {
                if (activeSortIndex === 2) {
                    const aDateIssued = Date.parse(a.license.date_issued);
                    const bDateIssued = Date.parse(b.license.date_issued);
                    return activeSortDirection === 'asc' ? aDateIssued - bDateIssued : bDateIssued - aDateIssued;
                } else if (activeSortIndex === 3) {
                    const aExpireDate = Date.parse(a.license.expiration_date ? a.license.expiration_date : "2999-01-01");
                    const bExpireDate = Date.parse(b.license.expiration_date ? b.license.expiration_date : "2999-01-01");
                    return activeSortDirection === 'asc' ?
                        aExpireDate - bExpireDate : bExpireDate - aExpireDate;
                }
            }
            const customerA = customerMap.get(a.cluster.customer_id);
            const customerAName: string = customerA ? customerA.name : "" + a.cluster.customer_id;
            const customerB = customerMap.get(b.cluster.customer_id);
            const customerBName = customerB ? customerB.name : "" + b.cluster.customer_id;
            return activeSortDirection === 'asc' ?
                customerAName.localeCompare(customerBName) : customerBName.localeCompare(customerAName);
        }).map((l) => {
            const customer = customerMap.get(l.cluster.customer_id);
            return (
                <Tr key={l.license.id}>
                    <Td><Link to={"/customers/" + l.cluster.customer_id}>{customer?.name}</Link></Td>
                    <Td><Link to={"/customers/" + l.cluster.customer_id + "?tab=1&filter=" + encodeURIComponent("cluster=" + l.cluster.id)}>{l.cluster.id}</Link></Td>
                    <Td><LicenseTypeResolver licenseTypeId={l.license.license_type_id} /></Td>
                    <Td style={{ fontFamily: "RedHatMono" }}>{formatDate(new Date(Date.parse(l.license.date_issued)))}</Td>
                    <Td style={{ fontFamily: "RedHatMono" }}>{l.license.expiration_date ? formatDate(new Date(Date.parse(l.license.expiration_date))) : "<no expiration>"}</Td>
                </Tr>)
        })
    }

    const { isLoading, isError, data, error } =
        useQuery<ManagerCustomer[], string>(['customers', true], () => fetchCustomers(true))

    if (isError || isLoading) {
        return (<Spinner isSVG />)
    }

    const customers = data ? data : [];
    const customerMap = new Map(customers.map((c) => [c.id, c]));

    return (
        <TableComposable
            variant='compact'
        >
            <Thead>
                <Tr>
                    <Th sort={getSortParams(0)}>Customer</Th>
                    <Th>Cluster #</Th>
                    <Th>Licenses type</Th>
                    <Th sort={getSortParams(2)}>Date issued</Th>
                    <Th sort={getSortParams(3)}>Expiration date</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderLicenseRows(props.licenses)}
            </Tbody>
        </TableComposable>
    )
}

const TODAY = new Date();

const startOfMonth = (d: Date) => {
    const rd = new Date();
    setUTC(rd, d.getFullYear(), d.getMonth(), 1);
    return rd;
}

const endOfMonth = (d: Date) => {
    const rd = new Date();
    setUTC(rd, d.getFullYear(), d.getMonth() + 1, 0);
    return rd;
}

const LicenseExpirationPage: React.FunctionComponent = () => {
    const [neverExpiring, setNeverExpiring] = React.useState(false);
    const [supportFromDate, setSupportFromDate] = React.useState(startOfMonth(TODAY));
    const [supportUntilDate, setSupportUntilDate] = React.useState(endOfMonth(TODAY));

    const QUERY_KEY = ["licenses-expiring", supportFromDate.toISOString(), supportUntilDate.toISOString(), neverExpiring];

    const handleNeverExpiringSwitch = (checked: boolean, _event: React.FormEvent<HTMLInputElement>) => {
        setNeverExpiring(checked);
    }

    const onChangeIssuedFromDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (date && isValidDate(date)) {
            const newD = new Date();
            setUTC(newD, date.getFullYear(), date.getMonth(), date.getDate());
            setSupportFromDate(newD);
        }
    }

    const onChangeIssuedUntilDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (date && isValidDate(date)) {
            const newD = new Date();
            setUTC(newD, date.getFullYear(), date.getMonth(), date.getDate());
            setSupportUntilDate(newD);
        }
    }

    const changeMonth = (d: Date, change: number) => {
        const rd = new Date();
        setUTC(rd, d.getFullYear(), d.getUTCMonth() + change, 15);
        setSupportFromDate(startOfMonth(rd));
        setSupportUntilDate(endOfMonth(rd));
    }

    const { isLoading, isError, data, error } =
        useQuery<LicenseExpiringResponse, string>(QUERY_KEY, () => fetchLicensesExpiring(supportFromDate, supportUntilDate, neverExpiring));

    if (isError) {
        return (<PageSection><Alert variant="danger" title={error} /></PageSection>);
    }

    const count = data ? data.count : 0;
    const licenses = data ? data.list : [];

    return (
        <React.Fragment>
            <PageSection hasOverflowScroll name="scrolling-section">
                <Title headingLevel="h1" size="xl">Expiring/Expired licenses</Title>
                <Toolbar>
                    <ToolbarContent>
                        <ToolbarItem>
                            Show licenses expiring between
                            <DatePicker
                                id='issued-from-date'
                                appendTo={document.body}
                                onChange={onChangeIssuedFromDate}
                                value={supportFromDate.toISOString().slice(0, 10)} />&nbsp;
                            and
                        </ToolbarItem>
                        <ToolbarItem>
                            <DatePicker
                                id='issued-from-date'
                                appendTo={document.body}
                                onChange={onChangeIssuedUntilDate}
                                value={supportUntilDate.toISOString().slice(0, 10)} />
                        </ToolbarItem>
                        <ToolbarItem>
                            <Switch
                                label="never expiring"
                                isChecked={neverExpiring}
                                onChange={handleNeverExpiringSwitch}
                                aria-label="infinite licenses switch"
                                id="infinite-switch" />
                        </ToolbarItem>
                        <ToolbarItem>
                            <Tooltip content="Go 1 month past">
                                <Button onClick={() => { changeMonth(supportFromDate, -1) }} variant='secondary'>-1m</Button>
                            </Tooltip>
                            <Tooltip content="Go 1 month forward">
                                <Button onClick={() => { changeMonth(supportFromDate, 1) }} variant='secondary'>+1m</Button>
                            </Tooltip>
                        </ToolbarItem>
                    </ToolbarContent>
                </Toolbar>
                <p>
                    Found <Badge>{count}</Badge> licenses expiring between {formatDate(supportFromDate)} and {formatDate(supportUntilDate)}.
                </p>
                {isLoading ? <Spinner isSVG /> : <LicensesExpiringTable licenses={licenses} />}
            </PageSection>
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>
    )
}

export {
    LicenseExpirationPage
}
