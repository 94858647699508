import * as React from 'react';
import {
    LabelGroup, Spinner, Label, Alert, AlertActionCloseButton, List, ListItem,
    Tooltip, SelectOptionObject, Select, SelectOption
} from "@patternfly/react-core";
import ClusterIcon from '@patternfly/react-icons/dist/esm/icons/cluster-icon';
import UsersIcon from '@patternfly/react-icons/dist/esm/icons/users-icon';
import { useQuery } from "@tanstack/react-query";

import { ContractContactResponse, ContractOption, ManagerContract, ManagerCustomer } from 'linbit-api-fetcher';
import { fetchContractContacts, fetchContractOptions, fetchCustomers, queryContractKinds } from "./fetcher";
import * as styles from './lab.module.css';
import { Link } from 'react-router-dom';

interface ContractOptionsViewProps {
    options: number[]
}

const ContractOptionsView: React.FunctionComponent<ContractOptionsViewProps> = (props: ContractOptionsViewProps) => {
    const nameLimit = 25;
    const { isLoading, isError, data, error } =
        useQuery<Map<number, ContractOption>, string>(['contract-options'], () => fetchContractOptions(false));

    if (isError) {
        return (<span>{error}</span>)
    } else {
        return (
            <LabelGroup isVertical numLabels={10} isCompact>
                {isLoading ?
                    <Spinner isSVG /> :
                    data && props.options.map((optIdx, index) => {
                        let option = data.get(optIdx);
                        if (option) {
                            const limitedName = option.name.length > nameLimit ?
                                option.name.substring(0, nameLimit) + "..." : option.name;
                            return (<Label id={option.id + ""} key={option.id} isCompact>{limitedName}</Label>)
                        } else {
                            return (<Label id="0" key="0" isCompact>Unknown option {optIdx}</Label>)
                        }
                    })
                }
            </LabelGroup>
        )
    }
}

interface ContractContactsProps {
    contractId: number,
}

const ContractContacts: React.FunctionComponent<ContractContactsProps> = (props: ContractContactsProps) => {
    const QUERY_KEY = ['contract-contacts', props.contractId];
    const [responseError, setResponseError] = React.useState('');

    const { isLoading, isError, data, error } =
        useQuery<ContractContactResponse, string>(QUERY_KEY, () => fetchContractContacts(props.contractId))

    if (isError) {
        return (<span>{error}</span>)
    } else {
        return (
            <React.Fragment >
                {responseError && (<Alert
                    variant='danger'
                    title={responseError}
                    actionClose={<AlertActionCloseButton onClose={() => setResponseError('')} />}
                />)}
                <List isPlain className={styles.narrowlist}>
                    {isLoading ?
                        <Spinner isSVG /> :
                        data && data.list?.map((c) => {
                            return <ListItem key={c.email}>
                                {c.technical ? <Tooltip content="Technical"><ClusterIcon /></Tooltip> : <ClusterIcon color='gainsboro' />}&nbsp;
                                {c.sales ? <Tooltip content="Sales"><UsersIcon /></Tooltip> : <UsersIcon color='gainsboro' />} {c.email}
                            </ListItem>
                        })
                    }
                </List>
            </React.Fragment>
        )
    }
}

interface ContractKindResolverProps {
    kind: number
}

const ContractKindResolver: React.FunctionComponent<ContractKindResolverProps> = (props: ContractKindResolverProps) => {
    const { isLoading, isError, data, error } = queryContractKinds(false);

    if (isError) {
        return (<span>{error}</span>)
    } else {
        if (isLoading || !data) {
            return (<Spinner isSVG />)
        } else {
            let kindData = data.get(props.kind);
            if (kindData) {
                return (<span>{kindData.name}</span>)
            } else {
                return (<span>{props.kind}</span>)
            }
        }
    }
}

interface CustomerNameResolverProps {
    customerId: number
}

const CustomerNameResolver: React.FunctionComponent<CustomerNameResolverProps> = (props: CustomerNameResolverProps) => {
    // we could improve this by simple fetching the customers table (without extra manager customer data)
    const { isLoading, isError, data, error } =
        useQuery<ManagerCustomer[], string>(['customers', true], () => fetchCustomers(true))

    if (isError) {
        return <span>{props.customerId}</span>;
    }

    if (isLoading || !data) {
        return (<Spinner isSVG size='sm' />)
    } else {
        const customerData = data.find((mc) => mc.id === props.customerId);
        if (customerData) {
            return (<span>{customerData.name}</span>)
        } else {
            return (<span>{props.customerId}</span>)
        }
    }
}

interface ContractOptionSelectProps {
    id: string;
    variant?: 'checkbox' | 'typeahead' | 'typeaheadmulti';
    placeholderText?: string;
    width?: string | number;
    selected: number[];
    onContractOptionSelected: (contractOptions: ContractOption[]) => void;
}

const ContractOptionSelect: React.FunctionComponent<ContractOptionSelectProps> = (props: ContractOptionSelectProps) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const onToggle = (isExpanded: boolean) => {
        setIsOpen(isExpanded);
    }

    const onSelect = (
        event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
        value: string | SelectOptionObject,
        isPlaceholder: boolean | undefined) => {
        let curSel = props.selected;
        let opt = Array.from(contractOptions).find(co => co[1].name === value);
        let optId = opt ? opt[0] : 0;
        if (props.selected && props.selected.includes(optId)) {
            curSel = props.selected.filter(item => item !== optId);
        } else {
            curSel = [...props.selected, optId];
        }
        let items = Array.from(contractOptions).filter(([key, co]) => curSel.includes(key)).map(([key, co]) => co);
        props.onContractOptionSelected(items);
    }

    const { isLoading, isError, data, error } =
        useQuery<Map<number, ContractOption>, string>(['contract-options'], () => fetchContractOptions(true));

    if (isError) {
        return <Alert variant='danger' title={error} />
    }

    if (isLoading || !data) {
        return <Spinner isSVG />
    }

    const contractOptions: Map<number, ContractOption> = data || new Map<number, ContractOption>();

    return (
        <Select
            chipGroupProps={{ numChips: 5 }}
            variant={props.variant ? props.variant : 'typeaheadmulti'}
            placeholderText={props.placeholderText}
            className={styles.smallselect}
            aria-label='Contract kinds'
            onToggle={onToggle}
            onSelect={onSelect}
            selections={Array.from(contractOptions).filter(co => props.selected.includes(co[0])).map(co => co[1].name)}
            isOpen={isOpen}
            width={props.width}
            id={props.id}>
            {
                Array.from(contractOptions)
                    .sort((a, b) => a[1].name.localeCompare(b[1].name))
                    .map(([key, co]) => {
                        return (
                            <SelectOption key={key} id={key + ""} value={co.name} />
                        )
                    })
            }
        </Select>
    )
}

interface ToolTipLinkProps {
    tooltipContent: string;
    prefix: string;
    contractId: number | null | undefined;
}

const ToolTipLink: React.FunctionComponent<ToolTipLinkProps> = (props: ToolTipLinkProps) => {
    if (props.contractId) {
        return (<Tooltip content={props.tooltipContent}>
            <Link to={"/contracts/" + props.contractId}>{props.prefix + props.contractId}</Link></Tooltip>)
    }
    return (<React.Fragment />);
}

function isContractExpired(c: ManagerContract) {
    const TODAY = new Date().setUTCHours(0, 0, 0, 0);
    return Date.parse(c.support_until) < TODAY;
}

export {
    isContractExpired,
    ContractOptionsView, ContractKindResolver, ContractContacts, CustomerNameResolver, ToolTipLink,
    ContractOptionSelect
}
